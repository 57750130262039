import React, { useEffect } from "react";
import "./HomePage.scss";
import Image1 from "./../assets/home/HomePage2024-1.png";
import Image2 from "./../assets/home/HomePage2024-2.png";
import Image3 from "./../assets/home/design-system-champions-1.png";
import Image4 from "./../assets/home/HomePage2024-3.png";
import Cookies from "./../Cookies";
import Nav from "./Nav";
import "./WebPage.scss";
import { Flex, Heading, Paragraph, Youtube } from "blocksin-system";

const HomePage = () => {
  const loadHotjar = () => {
    if (window.hj && window._hjSettings) return; // Hotjar already loaded

    // Hotjar loading script
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 3760487, hjsv: 6 };
      a = o.getElementsByTagName("head")[0];
      r = o.createElement("script");
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
  };

  const removeHotjar = () => {
    // Remove Hotjar script if it's already loaded
    const hotjarScript = document.querySelector('script[src*="static.hotjar"]');
    if (hotjarScript) {
      hotjarScript.remove();
    }

    // Reset any Hotjar-related settings or flags
    if (window.hj) window.hj = undefined;
    if (window._hjSettings) window._hjSettings = undefined;
  };

  useEffect(() => {
    // Check if Hotjar is allowed
    if (localStorage.getItem("allowHotjar") === "true") {
      loadHotjar();
    }
  }, []);

  useEffect(() => {
    const handleHotjarConsentChange = (event) => {
      const isAllowed = event.detail;
      if (isAllowed) {
        loadHotjar();
      } else {
        removeHotjar();
      }
    };

    window.addEventListener("hotjarConsentChange", handleHotjarConsentChange);

    // Cleanup
    return () => {
      window.removeEventListener(
        "hotjarConsentChange",
        handleHotjarConsentChange
      );
    };
  }, []);

  return (
    <Flex
      customClass={"HomePage WebPage"}
      direction={"column"}
      align={"center"}
      justify={"center"}
    >
      <Cookies />
      <Nav />

      <Flex customClass={"section Hero"} direction={"column"}>
        <Flex customClass={"row"} direction={"row"} align={"center"}>
          <Flex customClass={"column order2"} direction={"column"}>
            <Heading level={2} weight="bold">
              Empower Your Design & Development Teams
              with&nbsp;BlocksIn&nbsp;App
            </Heading>

            <Paragraph size="large">
              Welcome to a new era of collaboration! BlocksIn App is your
              ultimate tool for bringing designers, developers, and brand teams
              together. We streamline your workflow by integrating essential
              resources like Figma, GitHub, and Slack into one intuitive
              platform.
              <br />
              <br />
              Ready to transform the way you work?
            </Paragraph>
            <a
              className="button button-solid button-large"
              href="https://www.linkedin.com/in/sebastiangrochocki/"
            >
              Request a demo
            </a>
          </Flex>
          <Flex
            customClass={"column order1"}
            direction={"column"}
            align={"center"}
          >
            <Youtube videoId="x0eTAz4Pnys" />
          </Flex>
        </Flex>
      </Flex>

      <Flex customClass={"section"} direction={"column"}>
        <Flex customClass={"row"} direction={"row"} align={"center"}>
          <Flex customClass={"column flex-1 order2"} direction={"column"}>
            <Heading level={3} weight="bold">
              Breaking Down Barriers Between Design&nbsp;&&nbsp;Development
            </Heading>
            <Paragraph size="large">
              Ever felt like designers and developers are speaking different
              languages? We get it. Tools like Storybook are great for devs, but
              leave designers out. And while design platforms offer neat
              visuals, they often miss the coding beat.
              <br />
              <br />
              BlocksIn App is here to change the game, merging these worlds in
              one easy-to-use space. Because great products are built together.
            </Paragraph>
            <a
              className="button button-solid button-large"
              href="https://www.linkedin.com/in/sebastiangrochocki/"
            >
              Request a demo
            </a>
          </Flex>
          <Flex
            customClass={"column flex-1 imageLarge order1"}
            direction={"column"}
            align={"center"}
            justify={"center"}
          >
            <img
              src={Image1}
              alt="Breaking Down Barriers Between Design & Development"
            />
          </Flex>
        </Flex>
      </Flex>

      <Flex customClass={"section minHeight"} direction={"column"}>
        <Flex customClass={"row"} direction={"row"} align={"center"}>
          <Flex
            customClass={"column flex-1 imageLarge flareGreen"}
            direction={"column"}
            align={"center"}
            justify={"center"}
          >
            <img src={Image2} alt="Shared Ownership: Your Team’s Superpower" />
          </Flex>
          <Flex
            customClass={"column flex-1"}
            direction={"column"}
            align={"start"}
          >
            <Heading level={3} weight="bold">
              Shared Ownership: Your Team’s Superpower
            </Heading>
            <Paragraph size="large">
              BlocksIn App champions teamwork through shared project ownership.
              Designers, developers, brand experts – everyone gets a seat at the
              table. By working together on the same page, literally, your team
              can tackle projects with complete transparency, fostering a
              culture of shared success and collective innovation.
              <br />
              <br />
              Let's make everyone a part of the story.
            </Paragraph>
            <a
              className="button button-solid button-large"
              href="https://www.linkedin.com/in/sebastiangrochocki/"
            >
              Request a demo
            </a>
          </Flex>
        </Flex>
      </Flex>

      <Flex customClass={"section minHeight"} direction={"column"}>
        <Flex customClass={"row"} direction={"row"} align={"center"}>
          <Flex
            customClass={"column order2"}
            direction={"column"}
            align={"start"}
          >
            <Heading level={3} weight="bold">
              Recognize, Connect, and Grow with&nbsp;Contributions
            </Heading>
            <Paragraph size="large">
              Our Contribution feature is all about giving credit where it's
              due. It's a shout-out to those who make a difference in your
              design system. From the most active GitHub commiters to the Figma
              design dynamos, BlocksIn App highlights your top talent on the
              Score Board.
              <br />
              <br />
              This isn't just about recognition; it's about building a community
              of mentors and learners, boosting your project's momentum
            </Paragraph>
            <a
              className="button button-solid button-large"
              href="https://www.linkedin.com/in/sebastiangrochocki/"
            >
              Request a demo
            </a>
          </Flex>
          <Flex
            customClass={"column tcenter order1 flareMagenta"}
            direction={"column"}
            align={"center"}
            justify={"center"}
          >
            <img src={Image3} alt="Design System Champions" />
          </Flex>
        </Flex>
      </Flex>

      <Flex customClass={"section"} direction={"column"} gap={600}>
        <Flex customClass={"row"} direction={"row"}>
          <Flex
            customClass={"column flareGreen imageMobileLarge"}
            direction={"column"}
            align={"center"}
            justify={"center"}
          >
            <img src={Image4} alt="GrapesJS editor" />
          </Flex>
        </Flex>
        <Flex customClass={"row tcenter"} direction={"row"}>
          <Flex customClass={"column "} direction={"column"} align={"center"}>
            <Heading level={3} weight="bold">
              Documentation: From Hassle to&nbsp;Breeze
            </Heading>
            <Paragraph size="large">
              Forget the documentation dread. With BlocksInNotes, creating rich,
              detailed project documentation is a breeze. Our intuitive
              drag-and-drop editor lets you weave in design, code, and
              discussion into vibrant, shareable web pages.
              <br />
              <br />
              Say goodbye to boring docs and hello to engaging, informative
              content that everyone can enjoy.
            </Paragraph>
            <a
              className="button button-solid button-large"
              href="https://www.linkedin.com/in/sebastiangrochocki/"
            >
              Request a demo
            </a>
          </Flex>
        </Flex>
      </Flex>

      <Flex customClass={"section"} direction={"column"}>
        <Flex customClass={"row"} direction={"row"}>
          <Flex
            customClass={"column tcenter"}
            direction={"column"}
            align={"center"}
          >
            <Heading level={2} weight="bold">
              What's next!
            </Heading>

            <Heading level={3} weight="bold">
              Meet BlocksIn AI
            </Heading>
            <Paragraph size="large">
              Ready for the future? BlocksIn AI is coming to make your design
              and development smarter. By analyzing your project's heartbeat –
              from GitHub codes to Figma designs – it'll offer insights and
              suggestions to elevate your work.
              <br />
              <br />
              It's like having a personal advisor in your team, constantly
              pushing your project to its best version.
            </Paragraph>
          </Flex>
        </Flex>
      </Flex>
      <footer />
    </Flex>
  );
};

export default HomePage;
