import React, { useState } from "react";
import "./../radix-styles/Dialog.scss";
import * as Dialog from "@radix-ui/react-dialog";
// import AlertDialogCustom from "../AlertDialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import {
  Button,
  Flex,
  Paragraph,
  Heading,
  Input,
  IconButton,
} from "blocksin-system";

const DialogProjectCardUpdate = ({
  onSave,
  // onDelete,
  open,
  onOpenChange,
  cardTitle,
  cardId,
  setNewProjectTitle,
  onRenameProject,
}) => {
  const [title, setTitle] = useState(cardTitle);

  const handleSave = () => {
    onRenameProject(cardId, title);
    onOpenChange(false);
  };

  return (
    <>
      <Dialog.Root
        className="DialogRoot"
        open={open}
        onOpenChange={onOpenChange}
      >
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent">
            <Heading level={3} weight="bold">
              Rename Project
            </Heading>
            <Flex customClass="DialogDescription" direction={"column"}>
              <Paragraph size="large">
                Here you can edit Project Card details.
              </Paragraph>
            </Flex>
            <Flex direction="column" gap={200}>
              <Input
                label="Title"
                placeholder="Enter title"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Flex>

            <Flex
              direction="row"
              align="end"
              justify={"end"}
              gap={200}
              style={{
                marginTop: "var(--size-400)",
                paddingTop: "var(--size-150)",
                borderTop: "1px solid var(--border-neutral-subtle)",
              }}
            >
              <Button size="medium" variant="solid" onClick={handleSave}>
                Update Project
              </Button>
              {/* <Dialog.Close asChild>

              </Dialog.Close> */}
              {/* <Button
                size="medium"
                variant="danger"
                onClick={() => setShowAlertDeleteCardDialog(true)}
              >
                Delete
              </Button> */}
            </Flex>
            <Flex customClass="closeButton">
              <Dialog.Close asChild>
                <IconButton aria-label="Close" size="small" variant="ghost">
                  <Cross2Icon />
                </IconButton>
              </Dialog.Close>
            </Flex>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

export default DialogProjectCardUpdate;
