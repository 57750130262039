import React, { useState } from "react";
import "./../radix-styles/Dialog.scss";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import {
  Button,
  Flex,
  Paragraph,
  Heading,
  IconButton,
  Input,
} from "blocksin-system";

const DialogCards = ({ onSave, open, onOpenChange, username }) => {
  const [title, setTitle] = useState("");
  // const [author, setAuthor] = useState(username);
  const [image, setImage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [imageName, setImageName] = useState(""); // New state for storing image name

  const handleSave = () => {
    if (!title.trim()) {
      setErrorMessage("Title cannot be empty.");
      return;
    }

    onSave({ title, image }); // Pass the project object directly
    // setAuthor("");
    setTitle("");
    setImage("");
    setErrorMessage("");
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 202400) {
      setErrorMessage("File size should not exceed 200 KB.");
      return;
    }

    setImageName(file.name); // Update the image name

    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <Dialog.Root
        className="DialogRoot"
        open={open}
        onOpenChange={onOpenChange}
      >
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent">
            <Heading level={3} weight="bold">
              Create Design Library
            </Heading>
            <Flex customClass="DialogDescription" direction={"column"}>
              <Paragraph size="large">
                Here you can create your Design Library. Add title and upload
                Thumbnail image for better visibility.
              </Paragraph>
            </Flex>
            <Flex direction="column" gap={200}>
              <Input
                label="Title"
                placeholder="Enter title"
                errormsg={false}
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <input
                type="file"
                onChange={handleImageChange}
                style={{ display: "none" }}
                id="image-upload"
              />
              <Button
                size="medium"
                variant="outline"
                fluid
                onClick={() => document.getElementById("image-upload").click()}
              >
                {imageName || "Upload Image"}
              </Button>
            </Flex>

            {errorMessage && <p className="Error">{errorMessage}</p>}
            <Flex
              direction="column"
              align="end"
              style={{
                marginTop: "var(--size-400)",
                paddingTop: "var(--size-150)",
                borderTop: "1px solid var(--border-neutral-subtle)",
              }}
            >
              <Button size="medium" variant="solid" onClick={handleSave}>
                Save
              </Button>
            </Flex>
            <Flex customClass="closeButton">
              <Dialog.Close asChild>
                <IconButton aria-label="Close" size="small" variant="ghost">
                  <Cross2Icon />
                </IconButton>
              </Dialog.Close>
            </Flex>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

export default DialogCards;
