import React from "react";
import Nav from "./Nav";
import { Flex, Heading, Paragraph } from "blocksin-system";
import "./WebPage.scss";

const ProjectManagement = () => {
  return (
    <Flex direction={"column"} customClass={"WebPage"} align={"center"}>
      <Nav />

      <Flex direction={"column"} customClass={"WebPageBody"}>
        <Heading level={2} weight="bold">
          Project Management
        </Heading>
        <Paragraph size="medium">Coming soon...</Paragraph>
      </Flex>
    </Flex>
  );
};

export default ProjectManagement;
