import React, { useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import "./GrapesCardSelectionDialog.scss";
import PlaceholderImage from "./../assets/PlaceholderImage.svg";
import { Cross2Icon } from "@radix-ui/react-icons";
import { parse } from "papaparse";
// import axios from "axios";
import {
  Heading,
  Input,
  IconButton,
  Button,
  Tag,
  Paragraph,
  Flex,
  Tabs,
  ScrollArea,
} from "blocksin-system";

function GrapesCardSelectionDialog({
  isOpen,
  onClose,
  onSaveCardSelection,
  editorInstance,
  selectedOrgProjects,
  userOrg,
  publicTemplates,
  orgTemplates,
}) {
  const [userCards, setUserCards] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // State to track search query
  // const [iconCards, setIconCards] = useState([]);

  const serverUrl = process.env.REACT_APP_SERVER_URL;
  //
  //
  useEffect(() => {
    // console.log("selectedOrgProjects", selectedOrgProjects);

    const projectFileCards = selectedOrgProjects.flatMap((project) =>
      project.projectFiles.map((file) => ({
        title: file.title,
        image: file.projectFileImage || PlaceholderImage, // Use placeholder if no image is provided
        colorsURL: file.colorsURL,
        figmaIcons: file.figmaIcons,
      }))
    );
    setUserCards(projectFileCards);

    //
  }, [selectedOrgProjects, serverUrl]);

  const filteredIconCards = userCards.filter(
    (card) =>
      card.figmaIcons &&
      Array.isArray(card.figmaIcons) &&
      card.figmaIcons.length > 0 &&
      card.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredColorCards = userCards.filter(
    (card) =>
      card.colorsURL &&
      (!card.figmaIcons || !card.figmaIcons.length) && // Exclude icon cards
      card.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredCards = userCards.filter(
    (card) =>
      !card.colorsURL &&
      (!card.figmaIcons || !card.figmaIcons.length) && // Exclude icon cards
      card.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredTemplates = orgTemplates.filter((template) =>
    template.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleTemplateSelect = (template) => {
    onSaveCardSelection(template); // Pass the selected template to the onSaveCardSelection prop
    onClose();
  };

  const handleCardSelect = (cardTitle, cardType) => {
    const card = userCards.find((c) => c.title === cardTitle);

    if (!card) {
      console.error("Selected card not found in userCards array.");
      return;
    }

    if (cardType === "Color" && card.colorsURL) {
      // Handle color cards differently
      fetchColorCardData(card.colorsURL, editorInstance);
    } else if (cardType === "Icons" && card.figmaIcons) {
      editorInstance.addComponents([
        {
          type: "default", // Adjust if you have a specific type for SVGs
          tagName: "div",
          classes: ["container"], // Adjust based on your styling needs
          components: card.figmaIcons.map((icon) => ({
            type: "svg", // Assuming 'svg' type is correctly handled in your GrapesJS setup
            content: icon.content, // Use the SVG content
            // If additional handling is needed (e.g., attributes), adjust accordingly
          })),
        },
      ]);
      onClose(); // Close the dialog after inserting the icons
    } else {
      // For regular cards or color cards clicked as regular cards
      onSaveCardSelection(card);
      onClose();
    }
  };

  // Fetch and parse color data, then add it to the editor
  const fetchColorCardData = (colorsURL, editorInstance) => {
    fetch(colorsURL)
      .then((response) => response.text())
      .then((csvData) => {
        parse(csvData, {
          complete: (results) => {
            const tableHtml = generateColorTableHtml(results.data, colorsURL);
            editorInstance.addComponents(tableHtml);
            onClose();
          },
        });
      })
      .catch((error) => console.error("Error fetching color data:", error));
  };

  // Generate HTML table from CSV color data
  const generateColorTableHtml = (csvData, colorsURL) => {
    let html = `<table class='Table colorTable' data-colors-url='${colorsURL}'><tbody>`;
    csvData.forEach((row, rowIndex) => {
      html += "<tr>";
      row.forEach((cell, cellIndex) => {
        if (rowIndex === 0) {
          // Header row
          html += `<th><div data-gjs-type="text" data-gjs-droppable="true"> ${cell}</div></th>`;
        } else if (cell.startsWith("#")) {
          // Color cell
          html += `<td style="background-color: ${cell};"><div data-gjs-type="text" data-gjs-droppable="true"> ${cell}</div></td>`;
        } else {
          // Regular cell
          html += `<td><div data-gjs-type="text" data-gjs-droppable="true"> ${cell}</div></td>`;
        }
      });
      html += "</tr>";
    });
    html += "</tbody></table>";
    return html;
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value); // Update the search query state
  };

  const filteredPublicTemplates = publicTemplates.filter((template) =>
    template.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const [iframeURL, setIframeURL] = useState(""); // State to track iframe URL

  const handleIframeAdd = () => {
    onSaveCardSelection({ type: "iframe", src: iframeURL });
    onClose(); // Close the dialog after adding the iframe
  };

  return (
    <Dialog.Root className="DialogRoot" open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />

        <Dialog.Content className="DialogContent cardSelectionDialog">
          <Flex
            direction={"column"}
            gap={200}
            customClass={"cardSelectionDialogHeader"}
          >
            <Heading level={3} weight="bold">
              Choose your block {userOrg}
            </Heading>
            <Input
              label="Search"
              placeholder="Search for block"
              errormsg={false}
              type="search"
              value={searchQuery}
              onChange={handleSearchChange} // Add onChange handler
            />
          </Flex>

          <Tabs defaultValue="tab1">
            <Tabs.List aria-label="Manage your account">
              <Tabs.Trigger value="tab1">My blocks</Tabs.Trigger>
              <Tabs.Trigger value="tab2">Community</Tabs.Trigger>
            </Tabs.List>
            <Tabs.Content value="tab1">
              <ScrollArea>
                <Flex customClass={"cardSelector"} gap={200}>
                  <div className="cardOption column flex flex-column iframeCard">
                    <Heading level={4} weight="bold">
                      iframe Block
                    </Heading>
                    <Input
                      placeholder="Enter iframe URL"
                      value={iframeURL}
                      onChange={(e) => setIframeURL(e.target.value)}
                    />
                    <Button onClick={handleIframeAdd}>Add iframe</Button>
                  </div>

                  {filteredColorCards.map((colorCard, index) => (
                    <div
                      key={`colorCard-${index}`}
                      onClick={() => handleCardSelect(colorCard.title, "Color")}
                      className="cardOption column flex flex-column"
                    >
                      <Flex customClass="cardOptionThumbnail">
                        {colorCard.image && (
                          <img src={colorCard.image} alt={colorCard.title} />
                        )}
                        {!colorCard.image && (
                          <img src={PlaceholderImage} alt={colorCard.title} />
                        )}
                      </Flex>

                      <Paragraph size="medium">{colorCard.title}</Paragraph>

                      <Tag variant="Primary" text="Colors" />
                    </div>
                  ))}

                  {filteredIconCards.map((iconCard, index) => (
                    <div
                      key={`iconCard-${index}`}
                      onClick={() => handleCardSelect(iconCard.title, "Icons")}
                      className="cardOption column flex flex-column"
                    >
                      <Flex customClass="cardOptionThumbnail">
                        {iconCard.image && (
                          <img src={iconCard.image} alt={iconCard.title} />
                        )}
                        {!iconCard.image && (
                          <img src={PlaceholderImage} alt={iconCard.title} />
                        )}
                      </Flex>

                      <Paragraph size="medium">{iconCard.title}</Paragraph>

                      <Tag variant="Demo" text="Icons" />
                    </div>
                  ))}

                  {filteredCards.map((card, index) => (
                    // {userCards.map((card) => (
                    <div
                      key={`filteredCard-${index}`}
                      onClick={() => handleCardSelect(card.title, "Card")}
                      className="cardOption column flex flex-column"
                    >
                      <Flex customClass="cardOptionThumbnail">
                        {card.image && (
                          <img src={card.image} alt={card.title} />
                        )}
                        {!card.image && (
                          <img src={PlaceholderImage} alt={card.title} />
                        )}
                      </Flex>
                      <Paragraph size="medium">{card.title}</Paragraph>

                      <Tag variant="Normal" text="Card" />
                    </div>
                  ))}
                  {filteredTemplates.map((template, index) => (
                    <div
                      key={`template-${index}`}
                      onClick={() => handleTemplateSelect(template)}
                      className="cardOption column flex flex-column"
                    >
                      <Flex customClass="cardOptionThumbnail">
                        {template.image && (
                          <img src={template.image} alt={template.title} />
                        )}
                        {!template.image && (
                          <img src={PlaceholderImage} alt={template.title} />
                        )}
                      </Flex>
                      <Paragraph size="medium">{template.title}</Paragraph>
                      <Tag variant="Demo" text="Template" />
                    </div>
                  ))}
                </Flex>
              </ScrollArea>
            </Tabs.Content>
            <Tabs.Content value="tab2">
              <ScrollArea>
                <Flex customClass={"cardSelector"} gap={200}>
                  {filteredPublicTemplates.map((template, index) => (
                    <div
                      key={index}
                      onClick={() => handleTemplateSelect(template)}
                      className="cardOption column flex flex-column"
                    >
                      <Flex customClass="cardOptionThumbnail">
                        {template.image ? (
                          <img src={template.image} alt={template.title} />
                        ) : (
                          <img src={PlaceholderImage} alt={template.title} />
                        )}
                      </Flex>
                      <Paragraph size="medium">{template.title}</Paragraph>
                      <Tag variant="Demo" text="Template" />
                    </div>
                  ))}
                </Flex>
              </ScrollArea>
            </Tabs.Content>
          </Tabs>

          <Flex customClass="closeButton">
            <Dialog.Close asChild>
              <IconButton aria-label="Close" size="small" variant="ghost">
                <Cross2Icon />
              </IconButton>
            </Dialog.Close>
          </Flex>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default GrapesCardSelectionDialog;
