import React from "react";

export const TwoHorizontalSlidersIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    icon-name="TwoHorizontalSlidersIcon"
  >
    <path
      d="M2 6.5H5.5M5.5 6.5C5.5 7.60457 6.39543 8.5 7.5 8.5C8.60457 8.5 9.5 7.60457 9.5 6.5M5.5 6.5C5.5 5.39543 6.39543 4.5 7.5 4.5C8.60457 4.5 9.5 5.39543 9.5 6.5M18 13.5H14.5M14.5 13.5C14.5 14.6046 13.6046 15.5 12.5 15.5C11.3954 15.5 10.5 14.6046 10.5 13.5M14.5 13.5C14.5 12.3954 13.6046 11.5 12.5 11.5C11.3954 11.5 10.5 12.3954 10.5 13.5M9.5 6.5H18M10.5 13.5H2"
      stroke="black"
      stroke-linecap="round"
    />
  </svg>
);

export const TableIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    icon-name="TableIcon"
  >
    <path
      d="M3.5 7C3.22386 7 3 7.22386 3 7.5C3 7.77614 3.22386 8 3.5 8V7ZM16.5 8C16.7761 8 17 7.77614 17 7.5C17 7.22386 16.7761 7 16.5 7V8ZM3.5 12C3.22386 12 3 12.2239 3 12.5C3 12.7761 3.22386 13 3.5 13V12ZM16.5 13C16.7761 13 17 12.7761 17 12.5C17 12.2239 16.7761 12 16.5 12V13ZM3.5 8H16.5V7H3.5V8ZM3.5 13H16.5V12H3.5V13ZM4.9 3H15.1V2H4.9V3ZM16 3.9V16.1H17V3.9H16ZM15.1 17H4.9V18H15.1V17ZM4 16.1V3.9H3V16.1H4ZM4.9 17C4.40294 17 4 16.5971 4 16.1H3C3 17.1493 3.85066 18 4.9 18V17ZM16 16.1C16 16.5971 15.5971 17 15.1 17V18C16.1493 18 17 17.1493 17 16.1H16ZM15.1 3C15.5971 3 16 3.40294 16 3.9H17C17 2.85066 16.1493 2 15.1 2V3ZM4.9 2C3.85066 2 3 2.85066 3 3.9H4C4 3.40294 4.40294 3 4.9 3V2ZM9.5 3V17H10.5V3H9.5Z"
      fill="black"
    />
  </svg>
);

export const ThreeDotsIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    icon-name="ThreeDotsIcon"
  >
    <path
      d="M7 10C7 10.5523 6.55228 11 6 11C5.44772 11 5 10.5523 5 10C5 9.44772 5.44772 9 6 9C6.55228 9 7 9.44772 7 10Z"
      fill="black"
    />
    <path
      d="M11 10C11 10.5523 10.5523 11 10 11C9.44772 11 9 10.5523 9 10C9 9.44772 9.44772 9 10 9C10.5523 9 11 9.44772 11 10Z"
      fill="black"
    />
    <path
      d="M15 10C15 10.5523 14.5523 11 14 11C13.4477 11 13 10.5523 13 10C13 9.44772 13.4477 9 14 9C14.5523 9 15 9.44772 15 10Z"
      fill="black"
    />
  </svg>
);

export const ThreeHorizontalLinesIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    icon-name="ThreeHorizontalLinesIcon"
  >
    <path
      d="M3 5.5H16M3 10.5H16M3 15.5H16"
      stroke="black"
      stroke-linecap="round"
    />
  </svg>
);

export const FigmaToReactIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    icon-name="FigmaToReactIcon"
  >
    <rect width="24" height="24" rx="2" fill="#F232C8" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 15C18.3137 15 21 12.3137 21 9C21 5.68629 18.3137 3 15 3C11.6863 3 9 5.68629 9 9H15V15Z"
      fill="white"
    />
    <rect x="4" y="11" width="9" height="9" fill="white" />
  </svg>
);

export const FigmaToReact2Icon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    icon-name="FigmaToReact2Icon"
  >
    <rect width="24" height="24" rx="2" fill="#121118" />
    <circle cx="14" cy="9" r="6" fill="white" stroke="white" />
    <rect
      x="5"
      y="10"
      width="10"
      height="10"
      fill="#121118"
      stroke="white"
      stroke-width="2"
    />
  </svg>
);
