import React, { useState } from "react";
import "./LibraryCard.scss";
import PlaceholderImage from "./assets/PlaceholderImage.svg";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";

import AlertDialogCustom from "./AlertDialog";
import {
  Button,
  IconButton,
  Paragraph,
  Avatar,
  Flex,
  DropdownMenu,
  Tooltip,
} from "blocksin-system";

const Card = ({
  title,
  image,
  onViewProject,
  id,
  handleDeleteProjectCard,
  handleSaveCardTitle,
  owners,
  onEditProjectCard,
}) => {
  // const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleEditClick = () => {
    onEditProjectCard(id); // Pass the project ID to the onEditProjectCard function
  };

  const [showAlertDeleteCardDialog, setShowAlertDeleteCardDialog] =
    useState(false);

  return (
    <div className="LibraryCard">
      <AlertDialogCustom
        title="Delete Card"
        description={"Are you sure you want to delete card?"}
        cta="Yes, delete"
        onClick={() => handleDeleteProjectCard(id)} // Updated prop
        isOpen={showAlertDeleteCardDialog}
        onOpenChange={setShowAlertDeleteCardDialog}
      />
      <Flex customClass={"actionButtons"} direction={"row"} gap={100}>
        <DropdownMenu modal={false}>
          <DropdownMenu.Trigger asChild>
            <IconButton size="medium">
              <DotsHorizontalIcon />
            </IconButton>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content sideOffset={4} align="end">
            <DropdownMenu.Item onClick={handleEditClick}>
              Rename
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onClick={() => setShowAlertDeleteCardDialog(true)}
            >
              Delete
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu>
      </Flex>
      <Flex direction={"column"} customClass={"Thumbnail"}>
        {image && <img src={image} alt={title} />}
        {!image && <img src={PlaceholderImage} alt={title} />}
      </Flex>
      <Flex
        direction={"column"}
        align="start"
        justify="start"
        style={{ padding: "var(--size-200)", width: "100%" }}
        customClass={"ProjectTitle"}
      >
        <Tooltip>
          <Tooltip.Trigger>
            <Paragraph size="large" weight="bold">
              {title}
            </Paragraph>
          </Tooltip.Trigger>
          <Tooltip.Content sideOffset={8}>{title}</Tooltip.Content>
        </Tooltip>
      </Flex>
      <Flex
        direction={"row"}
        align="center"
        justify={"between"}
        style={{
          padding: "0 var(--size-200)",
          width: "100%",
          marginTop: "var(--size-200)",
        }}
      >
        {Array.isArray(owners) &&
          owners.map((owner, index) => {
            // console.log(owner);
            return (
              <Avatar
                key={index}
                avatar={owner.avatar}
                altText={owner.username}
                role={owner.role.join(", ")}
              />
            );
          })}

        <Button
          size="medium"
          variant="outline"
          onClick={() => onViewProject({ title, image })}
        >
          View Library
        </Button>
      </Flex>
    </div>
  );
};

export default Card;
