import React from "react";
import "./../radix-styles/Dialog.scss";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import { Flex, IconButton, Iframe, Paragraph, Heading } from "blocksin-system";

const DialogFeedback = ({ open, onOpenChange }) => {
  const googleFormUrl =
    "https://docs.google.com/forms/d/e/1FAIpQLSeIpXR0A9MQK89HPR-FFeEfJrh5BLVHPQvzRRYswtIa0SMuDQ/viewform";
  return (
    <>
      <Dialog.Root
        className="DialogRoot"
        open={open}
        onOpenChange={onOpenChange}
        modal
      >
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent DialogFeedback">
            <Heading level={3} weight="bold">
              Give feedback!
            </Heading>

            <Flex customClass="DialogDescription" direction={"column"}>
              <Paragraph size="large">It takes only fives seconds!</Paragraph>
            </Flex>
            <Iframe url={googleFormUrl} title={"Feedback form"} />
            <Flex customClass="closeButton">
              <Dialog.Close asChild>
                <IconButton aria-label="Close" size="small" variant="ghost">
                  <Cross2Icon />
                </IconButton>
              </Dialog.Close>
            </Flex>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

export default DialogFeedback;
