import React, { useState, useCallback } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  CopyIcon,
  ButtonIcon,
  SwitchIcon,
  CheckboxIcon,
  AvatarIcon,
} from "@radix-ui/react-icons";
import "./DragAndDrop.scss";
import { Button, Flex, Toast, IconButton, Input } from "blocksin-system";

const generateUniqueId = () =>
  `button-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

const DraggableComponent = () => {
  const [, drag] = useDrag(() => ({
    type: "COMPONENT",
    item: { type: "Button", id: generateUniqueId() },
  }));

  return (
    <div ref={drag} className="draggableComponent">
      <ButtonIcon />
    </div>
  );
};

const DropZone = ({ onDrop, className, layout, moveItem }) => {
  const [, drop] = useDrop(() => ({
    accept: "COMPONENT",
    drop: (item, monitor) => {
      onDrop({ ...item, id: generateUniqueId() }); // Generate a unique ID for the dropped item
    },
  }));

  return (
    <div ref={drop} className={className}>
      {layout.map((item, index) => (
        <DraggableButton
          key={item.id}
          id={item.id}
          text={item.label}
          index={index}
          moveItem={moveItem}
          variant={item.variant}
        />
      ))}
    </div>
  );
};

const DraggableButton = ({ id, text, variant, index, moveItem }) => {
  const [, drag] = useDrag(() => ({
    type: "BUTTON",
    item: { type: "BUTTON", id, index },
  }));

  const [, drop] = useDrop({
    accept: "BUTTON",
    hover: (item, monitor) => {
      if (!monitor.isOver({ shallow: true })) return;
      if (item.id !== id) {
        moveItem(item.index, index, item.id); // Pass the id of the dragged item
        item.index = index; // update the index for dragged item
      }
    },
  });

  return (
    <div ref={(node) => drag(drop(node))}>
      <Button variant={variant}>{text}</Button>
    </div>
  );
};

const DragAndDrop = () => {
  const [layout, setLayout] = useState([]);

  const handleDrop = useCallback(
    (newItem) => {
      const newLabel = `Button ${layout.length + 1}`;
      setLayout((prevLayout) => [
        ...prevLayout,
        { ...newItem, label: newLabel },
      ]);
    },
    [layout]
  );

  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = layout[dragIndex];
      const updatedLayout = [...layout];
      updatedLayout.splice(dragIndex, 1);
      updatedLayout.splice(hoverIndex, 0, dragItem);
      setLayout(updatedLayout);
    },
    [layout]
  );

  const updateLabel = useCallback((id, newLabel) => {
    setLayout((prevLayout) =>
      prevLayout.map((item) =>
        item.id === id ? { ...item, label: newLabel } : item
      )
    );
  }, []);

  const updateVariant = useCallback((id, newVariant) => {
    setLayout((prevLayout) =>
      prevLayout.map((item) =>
        item.id === id ? { ...item, variant: newVariant } : item
      )
    );
  }, []);

  const copyToClipboard = () => {
    const codeString = layout
      .map(
        (item) =>
          `<Button className='button button-medium button-${item.variant}'>${item.label}</Button>`
      )
      .join("\n");
    navigator.clipboard.writeText(codeString);
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  };

  const [showToast, setShowToast] = useState(false);

  return (
    <DndProvider backend={HTML5Backend}>
      <Flex direction="column" customClass="dnd">
        <Flex customClass="Components" direction="row" gap={100}>
          <DraggableComponent />
          <div disabled className="draggableComponent">
            <ButtonIcon />
          </div>
          <div disabled className="draggableComponent">
            <AvatarIcon />
          </div>
          <div disabled className="draggableComponent">
            <SwitchIcon />
          </div>
          <div disabled className="draggableComponent">
            <CheckboxIcon />
          </div>
        </Flex>
        <Flex customClass="dropZoneContainer">
          <DropZone
            onDrop={handleDrop}
            className="dropZone"
            layout={layout}
            moveItem={moveItem}
          />
          <Flex customClass="dndActions">
            <IconButton onClick={copyToClipboard} variant="ghost" size="medium">
              <CopyIcon />
            </IconButton>
          </Flex>
        </Flex>

        <div className="labelEditSection">
          {layout.map((item) => (
            <div key={item.id} className="itemEdit">
              <Input
                value={item.label}
                onChange={(e) => updateLabel(item.id, e.target.value)}
                label={"Button label"}
              />
              <Input
                value={item.variant || "solid"}
                onChange={(e) => updateVariant(item.id, e.target.value)}
                label={"Variant"}
              />
            </div>
          ))}
        </div>
      </Flex>
      {showToast && (
        <Toast
          showToast={showToast}
          setShowToast={setShowToast} // Add this line
          text="Copied to clipboard 🚀"
          time={3000}
          simple
        />
      )}
    </DndProvider>
  );
};

export default DragAndDrop;
