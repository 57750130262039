import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Flex,
  Paragraph,
  Heading,
  Separator,
  IconButton,
  BadgeLevel,
  Input,
  Checkbox,
  Loader,
  Select,
  DropdownMenu,
  Tooltip,
  ScrollArea,
} from "blocksin-system";
import "./FigmaFileDetails.scss";
import { ReloadIcon } from "@radix-ui/react-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import classnames from "classnames";
import {
  FigmaLogoIcon,
  Component1Icon,
  ChatBubbleIcon,
  DotsHorizontalIcon,
  MixerHorizontalIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@radix-ui/react-icons";
import FigmaLogo from "../assets/figmaLogo.svg";
import DialogAnswer from "../dialogs/DialogAnswer";

import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

const DateRangePickerInput = React.forwardRef(
  ({ value, onClick, label }, ref) => (
    <Input
      type="text"
      value={value}
      onClick={onClick}
      ref={ref}
      placeholder="Select date range"
      readOnly // to prevent manual editing
      label={label}
      validation={false}
    />
  )
);

// Function to get week number of the year
const getWeekNumber = (date) => {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

// Function to get weeks in each month
const getWeeksInMonth = (year) => {
  const months = Array.from({ length: 12 }, (_, i) => new Date(year, i, 1));
  return months.map((month) => ({
    month: month.toLocaleString("default", { month: "short" }),
    startWeek: getWeekNumber(
      new Date(month.getFullYear(), month.getMonth(), 1)
    ),
    endWeek: getWeekNumber(
      new Date(month.getFullYear(), month.getMonth() + 1, 0)
    ),
  }));
};

const FigmaFileDetails = ({
  figmaFileId,
  figmaAccessToken,
  username,
  currentUser,
}) => {
  const [hasError, setHasError] = useState(false);
  const [personalAccessToken, setPersonalAccessToken] = useState(null);
  const [instanceCount, setInstanceCount] = useState(0);
  const [detachedInstanceCount, setDetachedInstanceCount] = useState(0);

  // eslint-disable-next-line
  const [editDates, setEditDates] = useState([]);
  const [fileName, setFileName] = useState("");

  const [comments, setComments] = useState([]);
  const [editCount, setEditCount] = useState(0);
  const [componentNames, setComponentNames] = useState([]);

  const [filterAuthor, setFilterAuthor] = useState("all");
  const [filterAuthorDisplay, setFilterAuthorDisplay] = useState("All authors"); // New state for display
  const [authors, setAuthors] = useState([]);

  const handleAuthorChange = (newAuthor) => {
    setFilterAuthor(newAuthor);
    const displayValue =
      newAuthor === "all" || !newAuthor ? "Filter by author" : newAuthor;
    setFilterAuthorDisplay(displayValue);
  };

  // eslint-disable-next-line
  const [allYearsData, setAllYearsData] = useState({}); // New state to store data for all years

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedCommentId, setSelectedCommentId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const storageKeyPrefix = `figmaFile_${figmaFileId}_`;

  // Initial state setup using local storage values or default values
  const [statsShow, setStatsShow] = useState(
    JSON.parse(localStorage.getItem(storageKeyPrefix + "statsShow")) ?? true
  );
  const [componentsShow, setComponentsShow] = useState(
    JSON.parse(localStorage.getItem(storageKeyPrefix + "componentsShow")) ??
      true
  );
  const [commentsShow, setCommentsShow] = useState(
    JSON.parse(localStorage.getItem(storageKeyPrefix + "commentsShow")) ?? true
  );
  const [activityShow, setActivityShow] = useState(
    JSON.parse(localStorage.getItem(storageKeyPrefix + "activityShow")) ?? true
  );

  useEffect(() => {
    localStorage.setItem(
      storageKeyPrefix + "statsShow",
      JSON.stringify(statsShow)
    );
    localStorage.setItem(
      storageKeyPrefix + "componentsShow",
      JSON.stringify(componentsShow)
    );
    localStorage.setItem(
      storageKeyPrefix + "commentsShow",
      JSON.stringify(commentsShow)
    );
    localStorage.setItem(
      storageKeyPrefix + "activityShow",
      JSON.stringify(activityShow)
    );
  }, [
    figmaFileId,
    statsShow,
    componentsShow,
    commentsShow,
    activityShow,
    storageKeyPrefix,
  ]);

  const openDialog = (commentId) => {
    setSelectedCommentId(commentId);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setSelectedCommentId(null);
  };

  const [swiperInstance, setSwiperInstance] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0); // Add state to track the current index

  const nextSlide = () => {
    if (swiperInstance) {
      swiperInstance.slideNext();
    }
  };

  const prevSlide = () => {
    if (swiperInstance) {
      swiperInstance.slidePrev();
    }
  };

  const updateCurrentIndex = () => {
    if (swiperInstance) {
      setCurrentIndex(swiperInstance.realIndex);
    }
  };

  // eslint-disable-next-line
  const [componentIds, setComponentIds] = useState(new Set());

  // const [replyToCommentId, setReplyToCommentId] = useState(null);
  const [replyText, setReplyText] = useState("");

  // Function to check if a week has an edit or comment
  const checkWeekActivity = (week, activityWeeks) => {
    return activityWeeks.includes(week);
  };

  const handleReplyChange = (event) => {
    setReplyText(event.target.value);
  };

  const currentYear = new Date().getFullYear();
  // const currentYear = 2023;
  const monthsWithWeeks = getWeeksInMonth(currentYear);
  const currentMonth = new Date().getMonth();
  const { startWeek: currentMonthStartWeek, endWeek: currentMonthEndWeek } =
    getWeeksInMonth(currentYear)[currentMonth];

  const gatherComponentIds = useCallback((node, componentIds) => {
    if (node.type === "COMPONENT") {
      // Add only COMPONENT nodes
      componentIds.add(node.id);
    }
    if (node.children) {
      node.children.forEach((child) => {
        gatherComponentIds(child, componentIds);
      });
    }
  }, []);

  const countDetachedInstances = useCallback((node, componentIds) => {
    let count = 0;
    if (node.type === "INSTANCE" && !componentIds.has(node.componentId)) {
      count += 1;
    }
    if (node.children) {
      node.children.forEach((child) => {
        count += countDetachedInstances(child, componentIds);
      });
    }
    return count;
  }, []);

  const extractComponents = useCallback((node, components = []) => {
    if (node.type === "COMPONENT") {
      components.push({ name: node.name, id: node.id }); // Store both name and id
    } else if (node.children) {
      node.children.forEach((child) => extractComponents(child, components));
    }
    return components;
  }, []);

  const fetchFigmaData = useCallback(async () => {
    console.log("i am fetching");
    setIsLoading(true);

    // const figmaToken = currentUser?.widgetFigmaAccessToken;
    const figmaToken = figmaAccessToken;

    if (!figmaToken) {
      console.error("No Figma token found");
      return;
    }

    try {
      // Fetch versions and filter by current year
      const versionsResponse = await fetch(
        `https://api.figma.com/v1/files/${figmaFileId}/versions`,
        {
          headers: { "X-Figma-Token": figmaToken },
        }
      );
      const versionsData = await versionsResponse.json();

      // const editsWithUserIds = versionsData.versions
      //   .filter(
      //     (version) =>
      //       new Date(version.created_at).getFullYear() === currentYear
      //   )
      //   .map((version) => ({
      //     date: version.created_at,
      //     userId: version.user_id, // Replace 'user_id' with the actual property name
      //   }));

      const currentYearEditDates = versionsData.versions
        .filter(
          (version) =>
            new Date(version.created_at).getFullYear() === currentYear
        )
        .map((version) => version.created_at);

      // Fetch file data
      const fileResponse = await fetch(
        `https://api.figma.com/v1/files/${figmaFileId}`,
        {
          headers: { "X-Figma-Token": figmaToken },
        }
      );
      const fileData = await fileResponse.json();

      // Fetch comments and filter by current year
      const commentsResponse = await fetch(
        `https://api.figma.com/v1/files/${figmaFileId}/comments`,
        {
          headers: { "X-Figma-Token": figmaToken },
        }
      );
      const commentsData = await commentsResponse.json();

      const fetchedComments = commentsData.comments;

      setEditCount(currentYearEditDates.length);
      setComments(fetchedComments);

      // Set state with current year data
      setEditCount(currentYearEditDates.length);
      setEditDates(currentYearEditDates);
      // setComments(currentYearComments);
      setComments(commentsData.comments);
      setFileName(fileData.name);

      const componentsWithIds = extractComponents(fileData.document);
      // console.log("Extracted Component Names:", componentsWithIds); // Debugging line

      // Update componentIds state variable
      setComponentIds(componentsWithIds);

      const componentIds = new Set();
      gatherComponentIds(fileData.document, componentIds);
      // console.log("Component IDs:", Array.from(componentIds));
      setInstanceCount(componentIds.size);

      setDetachedInstanceCount(
        countDetachedInstances(fileData.document, new Set())
      );

      let yearsData = {};
      versionsData.versions.forEach((version) => {
        const year = new Date(version.created_at).getFullYear();
        yearsData[year] = yearsData[year] || { editDates: [], comments: [] };
        yearsData[year].editDates.push(version.created_at);
      });

      commentsData.comments.forEach((comment) => {
        const year = new Date(comment.created_at).getFullYear();
        yearsData[year] = yearsData[year] || { editDates: [], comments: [] };
        yearsData[year].comments.push(comment);
      });

      setAllYearsData(yearsData);

      const uniqueAuthors = [
        ...new Set(commentsData.comments.map((comment) => comment.user.handle)),
      ];
      setAuthors(uniqueAuthors);

      const storageKey = `figmaFile_${figmaFileId}_${username}`;
      // localStorage.setItem(storageKey, JSON.stringify(yearsData));

      const componentData = extractComponents(fileData.document);

      const componentIdSet = new Set(componentData.map((c) => c.id));
      setComponentIds(componentIdSet);

      gatherComponentIds(fileData.document, componentIds);

      setAllYearsData(yearsData);
      setInstanceCount(componentIds.size);
      setDetachedInstanceCount(
        countDetachedInstances(fileData.document, componentIds)
      );

      const extractedComponents = extractComponents(fileData.document);
      setComponentNames(extractedComponents.map((c) => c.name));
      // console.log(
      //   "Names:",
      //   extractedComponents.map((c) => c.name)
      // );

      setComments(fetchedComments);
      setEditCount(currentYearEditDates.length);
      // Storing in localStorage
      const now = new Date().getTime();
      localStorage.setItem(
        storageKey,
        JSON.stringify({
          yearsData,
          instanceCount: componentIds.size,
          detachedInstanceCount: countDetachedInstances(
            fileData.document,
            componentIds
          ),
          componentNames: componentData,
          timestamp: now,
          editCount: currentYearEditDates.length,
          comments: fetchedComments,
          fileName: fileData.name,
          // editsWithUserIds,
        })
      );
    } catch (error) {
      console.error("Error fetching Figma data:", error);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, [
    figmaFileId,
    username,
    currentYear,
    countDetachedInstances,
    gatherComponentIds,
    extractComponents,
    figmaAccessToken,
  ]);

  // useEffect(() => {
  //   // if (componentNames.length > 0) {
  //   //   console.log("Component names updated:", componentNames);
  //   //   // Perform any additional logic if needed when componentNames is updated
  //   // }
  // }, [componentNames]); // Depend on componentNames

  const loadData = useCallback(() => {
    const storageKey = `figmaFile_${figmaFileId}_${username}`;
    const storedData = JSON.parse(localStorage.getItem(storageKey));

    if (storedData) {
      const now = new Date().getTime();
      const oneHour = 3600000;
      if (now - storedData.timestamp < oneHour) {
        // Use stored data
        const { yearsData, instanceCount, detachedInstanceCount } = storedData;

        // Update states with stored data
        setAllYearsData(yearsData);
        setInstanceCount(instanceCount);
        setDetachedInstanceCount(detachedInstanceCount);
        setComments(storedData.comments);
        setEditCount(storedData.editCount);
        setComponentNames(storedData.componentNames);
        setFileName(storedData.fileName);

        // Extract author names from comments
        const allComments = Object.values(yearsData).flatMap(
          (year) => year.comments || []
        );
        const uniqueAuthors = [
          ...new Set(allComments.map((comment) => comment.user.handle)),
        ];
        setAuthors(uniqueAuthors);

        console.log("Data loaded from storage");
      } else {
        console.log("Stored data is outdated, fetching new data");
        fetchFigmaData();
      }
    } else {
      console.log("No stored data found, fetching new data");
      fetchFigmaData();
    }
  }, [figmaFileId, username, fetchFigmaData]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const refreshData = () => {
    setHasError(false); // Reset error state
    localStorage.removeItem(`figmaFile_${figmaFileId}_${username}`);
    fetchFigmaData();
  };

  const [selectedComponentNodeId, setSelectedComponentNodeId] = useState(""); // New state variable to store selected component's node ID

  // Function to handle button click and open Figma URL
  const openFigmaComponent = () => {
    if (selectedComponentNodeId) {
      // Check if a component is selected
      const figmaUrl = `https://www.figma.com/file/${figmaFileId}/?node-id=${selectedComponentNodeId}&mode=design`;
      window.open(figmaUrl, "_blank"); // Open Figma URL in a new tab
    }
  };

  useEffect(() => {
    if (currentUser.widgetFigmaAccessToken) {
      setPersonalAccessToken(currentUser.widgetFigmaAccessToken);
    } else {
      setPersonalAccessToken(figmaAccessToken);
    }
  }, [setPersonalAccessToken, currentUser, figmaAccessToken]);

  const submitReply = async () => {
    // const figmaToken = currentUser?.widgetFigmaAccessToken;
    const figmaToken = figmaAccessToken;

    if (!figmaToken || !replyText.trim()) return;

    // Find the original comment using selectedCommentId
    const originalComment = comments.find(
      (comment) => comment.id === selectedCommentId
    );
    if (!originalComment) {
      console.error("Original comment not found");
      return;
    }

    // Format the reply message to include a reference to the original comment
    const formattedReply = `Reply to [${originalComment.user.handle}]: ${originalComment.message}\n\n${replyText}`;

    console.log(currentUser?.widgetFigmaAccessToken);
    console.log(figmaAccessToken);
    console.log(personalAccessToken);
    try {
      const response = await fetch(
        `https://api.figma.com/v1/files/${figmaFileId}/comments`,
        {
          method: "POST",
          headers: {
            "X-Figma-Token": personalAccessToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            message: formattedReply,
          }),
        }
      );

      if (response.ok) {
        // Handle successful reply
        // setReplyToCommentId(null);
        setReplyText("");
        // Optionally refresh comments here
      } else {
        // Handle error
        console.error("Failed to post reply");
      }
    } catch (error) {
      console.error("Error posting reply:", error);
    }
    closeDialog();
  };

  //

  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const filterComments = (comment) => {
    // Adjust byAuthor to include all authors if filterAuthor is "all"
    const byAuthor =
      filterAuthor === "all" || !filterAuthor
        ? true
        : comment.user.handle === filterAuthor;

    const commentDate = new Date(comment.created_at);
    let byDateRange = true;
    if (startDate && endDate) {
      byDateRange = commentDate >= startDate && commentDate <= endDate;
    } else if (startDate) {
      byDateRange = commentDate >= startDate;
    } else if (endDate) {
      byDateRange = commentDate <= endDate;
    }

    return byAuthor && byDateRange;
  };

  //
  const indexToYearMap = Object.keys(allYearsData)
    .sort()
    .reduce((acc, year, index) => {
      acc[index] = year;
      return acc;
    }, {});

  //

  return (
    <Flex direction={"column"} gap={100} customClass="FigmaFileDetails">
      {isLoading && <Loader />}
      {hasError ? (
        <Heading level={3} weight="bold">
          Unable to fetch data. Please check the Figma file ID or token.
        </Heading>
      ) : (
        <>
          <Flex customClass="actionButtons" gap={100}>
            <DropdownMenu>
              <DropdownMenu.Trigger asChild>
                <IconButton size="medium" variant="ghost">
                  <DotsHorizontalIcon />
                </IconButton>
              </DropdownMenu.Trigger>

              <DropdownMenu.Content sideOffset={4} align="end">
                <Flex
                  direction="column"
                  gap={100}
                  style={{ padding: "var(--size-200)" }}
                >
                  <Heading level={4} weight="bold">
                    Show/hide
                  </Heading>
                  <Checkbox
                    checked={activityShow}
                    onChange={(newCheckedValue) => {
                      setActivityShow(newCheckedValue);
                    }}
                    customID="Activity"
                  >
                    Activity
                  </Checkbox>
                  <Checkbox
                    checked={statsShow}
                    onChange={(newCheckedValue) => {
                      setStatsShow(newCheckedValue);
                    }}
                    customID="Stats"
                  >
                    Stats
                  </Checkbox>
                  <Checkbox
                    checked={componentsShow}
                    onChange={(newCheckedValue) => {
                      setComponentsShow(newCheckedValue);
                    }}
                    customID="Components"
                  >
                    Your components
                  </Checkbox>
                  <Checkbox
                    checked={commentsShow}
                    onChange={(newCheckedValue) => {
                      setCommentsShow(newCheckedValue);
                    }}
                    customID="Comments"
                  >
                    Comments
                  </Checkbox>
                </Flex>
              </DropdownMenu.Content>
            </DropdownMenu>

            <Tooltip delayDuration={200}>
              <Tooltip.Trigger asChild>
                <IconButton size="medium" variant="ghost" onClick={refreshData}>
                  <ReloadIcon />
                </IconButton>
              </Tooltip.Trigger>
              <Tooltip.Content sideOffset={2}>
                <Paragraph size="medium">Reload data</Paragraph>
              </Tooltip.Content>
            </Tooltip>
          </Flex>
          <Flex align="center" gap={100}>
            <img className="logo" src={FigmaLogo} alt="Figma widget" />
            <Heading level={4} weight="bold">
              Figma Widget for '{fileName}' file.{" "}
              {currentUser.widgetFigmaUserId && (
                <>
                  Associated Figma User Id:
                  {currentUser.widgetFigmaUserId
                    ? currentUser.widgetFigmaUserId.slice(0, -14) + "*****"
                    : "Not available"}
                </>
              )}
            </Heading>
          </Flex>
          <Separator />
          {activityShow && (
            <Flex
              direction="column"
              gap={100}
              align="start"
              customClass="activityGraph"
            >
              <Flex
                customClass="activityRow Header Year"
                justify="center"
                align="center"
                style={{ width: "100%" }}
                gap={200}
              >
                <Flex customClass="IconButtonContainer">
                  {currentIndex > 0 && (
                    <IconButton
                      size="small"
                      variant="ghost"
                      onClick={prevSlide}
                      aria-label="Previous"
                    >
                      <ChevronLeftIcon />
                    </IconButton>
                  )}
                </Flex>

                <Heading level={4} weight="bold">
                  {indexToYearMap[currentIndex]}
                </Heading>

                <Flex customClass="IconButtonContainer">
                  {currentIndex < Object.keys(allYearsData).length - 1 && (
                    <IconButton
                      size="small"
                      variant="ghost"
                      onClick={nextSlide}
                      aria-label="Next"
                    >
                      <ChevronRightIcon />
                    </IconButton>
                  )}
                </Flex>
              </Flex>

              <Flex gap={200} align="end" style={{ width: "100%" }}>
                <Flex gap={100} direction="column" customClass="legend">
                  <Paragraph>Figma Activity</Paragraph>
                  <Paragraph>Comments</Paragraph>
                  <Paragraph>Detached</Paragraph>
                </Flex>
                <Swiper
                  onSwiper={setSwiperInstance}
                  slidesPerView={1}
                  spaceBetween={0}
                  loop={false}
                  onSlideChange={updateCurrentIndex}
                >
                  {Object.keys(allYearsData).map((year) => {
                    const isCurrentYear = parseInt(year) === currentYear;

                    const yearData = allYearsData[year];
                    const editWeeks = yearData.editDates.map((date) =>
                      getWeekNumber(new Date(date))
                    );
                    const commentWeeks = yearData.comments.map((comment) =>
                      getWeekNumber(new Date(comment.created_at))
                    );

                    return (
                      <SwiperSlide key={year}>
                        <ScrollArea>
                          <Flex gap={100} direction="column">
                            {/* Month names */}
                            <Flex customClass="activityRow">
                              {monthsWithWeeks.map((month) => (
                                <div
                                  key={month.month}
                                  style={{
                                    textAlign: "center",
                                    width: `${
                                      20 * (month.endWeek - month.startWeek + 1)
                                    }px`,
                                  }}
                                >
                                  <Paragraph weight="bold">
                                    {month.month}
                                  </Paragraph>
                                </div>
                              ))}
                            </Flex>

                            {/* Edits row */}

                            <Flex customClass="activityRow">
                              {monthsWithWeeks.map((month, monthIndex) => (
                                <div
                                  key={month.month}
                                  className="activityMonth"
                                >
                                  {Array.from({
                                    length: month.endWeek - month.startWeek + 1,
                                  }).map((_, weekIndex) => {
                                    const weekNumber =
                                      month.startWeek + weekIndex;
                                    const isCurrentMonth =
                                      monthIndex === currentMonth &&
                                      weekNumber >= currentMonthStartWeek &&
                                      weekNumber <= currentMonthEndWeek;
                                    return (
                                      <div
                                        key={weekIndex}
                                        className={classnames("cubeData", {
                                          cubeCurrentMonth:
                                            isCurrentMonth && isCurrentYear,
                                        })}
                                        style={{
                                          backgroundColor: checkWeekActivity(
                                            weekNumber,
                                            editWeeks
                                          )
                                            ? "var(--brand-2)"
                                            : "var(--background-neutral-hover)",
                                          display: "inline-block",
                                        }}
                                      />
                                    );
                                  })}
                                </div>
                              ))}
                            </Flex>

                            {/* Comments row */}
                            <Flex customClass="activityRow">
                              {monthsWithWeeks.map((month, monthIndex) => (
                                <div
                                  key={month.month}
                                  className="activityMonth"
                                >
                                  {Array.from({
                                    length: month.endWeek - month.startWeek + 1,
                                  }).map((_, i) => {
                                    const weekNumber = month.startWeek + i;
                                    const isCurrentMonth =
                                      monthIndex === currentMonth &&
                                      weekNumber >= currentMonthStartWeek &&
                                      weekNumber <= currentMonthEndWeek;
                                    return (
                                      <div
                                        key={i}
                                        className={classnames("cubeData", {
                                          cubeCurrentMonth:
                                            isCurrentMonth && isCurrentYear,
                                        })}
                                        style={{
                                          backgroundColor: checkWeekActivity(
                                            weekNumber,
                                            commentWeeks
                                          )
                                            ? "var(--brand-2)"
                                            : "var(--background-neutral-hover)",
                                          display: "inline-block",
                                        }}
                                      />
                                    );
                                  })}
                                </div>
                              ))}
                            </Flex>

                            {/* Detached row */}

                            <Flex customClass="activityRow">
                              {monthsWithWeeks.map((month, monthIndex) => (
                                <div
                                  key={month.month}
                                  className="activityMonth"
                                >
                                  {Array.from({
                                    length: month.endWeek - month.startWeek + 1,
                                  }).map((_, i) => {
                                    const weekNumber = month.startWeek + i;
                                    const isCurrentMonth =
                                      monthIndex === currentMonth &&
                                      weekNumber >= currentMonthStartWeek &&
                                      weekNumber <= currentMonthEndWeek;
                                    return (
                                      <div
                                        key={i}
                                        className={classnames("cubeData", {
                                          cubeCurrentMonth:
                                            isCurrentMonth && isCurrentYear,
                                        })}
                                        style={{
                                          backgroundColor:
                                            "var(--background-neutral-hover)",
                                          display: "inline-block",
                                        }}
                                      />
                                    );
                                  })}
                                </div>
                              ))}
                            </Flex>
                          </Flex>
                        </ScrollArea>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </Flex>
            </Flex>
          )}
          {statsShow && (
            <Flex gap={400} customClass="figmaStats">
              <Flex gap={100} align={"center"}>
                <Paragraph size="large">Edits:</Paragraph>
                <BadgeLevel badge={editCount} />
              </Flex>
              {/* {editDates.map((date, index) => (
          <Paragraph key={index} size="small">
            Edit on {new Date(date).toLocaleDateString()}
          </Paragraph>
        ))} */}
              <Flex gap={100} align={"center"}>
                <Paragraph size="large">Components:</Paragraph>
                <BadgeLevel badge={instanceCount} />
              </Flex>

              <Flex gap={100} align={"center"}>
                <Paragraph size="large">Detached instances:</Paragraph>
                <BadgeLevel badge={detachedInstanceCount} />
              </Flex>

              <Flex gap={100} align={"center"}>
                <Paragraph size="large">Comments:</Paragraph>
                <BadgeLevel badge={comments.length} />
              </Flex>
            </Flex>
          )}

          <Flex gap={200} customClass="extraWidgets">
            {componentsShow && (
              <Flex
                direction="column"
                gap={200}
                customClass="widgetCard yourComponents"
              >
                <Flex
                  align="center"
                  gap="100"
                  style={{ height: "var(--size-500)" }}
                >
                  <Component1Icon />
                  <Heading level={4} weight="bold">
                    Your components
                  </Heading>
                </Flex>
                <Flex gap={100} direction="column">
                  {!isLoading ? (
                    componentNames.length > 0 ? (
                      <Select
                        value={selectedComponentNodeId || ""}
                        onValueChange={(newValue) =>
                          setSelectedComponentNodeId(newValue)
                        }
                      >
                        {/* Render Select options here */}
                        <Select.Trigger aria-label="Components">
                          <Select.Value placeholder="Select a component" />
                        </Select.Trigger>
                        <Select.Content
                          side="bottom"
                          sideOffset={0}
                          align="start"
                        >
                          {componentNames
                            .filter(
                              (component) =>
                                component.name &&
                                !component.name.includes("=") &&
                                !component.name.includes(", ")
                            )
                            .map((component, index) => (
                              <Select.Item key={index} value={component.id}>
                                {component.name}
                              </Select.Item>
                            ))}
                        </Select.Content>
                      </Select>
                    ) : (
                      <Paragraph>No components available.</Paragraph>
                    )
                  ) : (
                    <Paragraph>Loading components...</Paragraph>
                  )}

                  {/* <p>Total Count: {componentNames.length}</p> */}

                  <Button
                    size="medium"
                    variant="outline"
                    onClick={openFigmaComponent}
                    fluid
                  >
                    <FigmaLogoIcon />
                    Open in Figma
                  </Button>
                  {/* Button to open Figma */}
                </Flex>
              </Flex>
            )}
            {commentsShow && (
              <Flex
                direction={"column"}
                gap={200}
                customClass="widgetCard yourComments"
              >
                <Flex
                  align="center"
                  gap={100}
                  customClass="Header"
                  justify="between"
                >
                  <Flex
                    gap={100}
                    align="center"
                    style={{ height: "var(--size-500)" }}
                  >
                    <ChatBubbleIcon />
                    <Heading level={4} weight="bold">
                      Comments
                    </Heading>
                  </Flex>
                  <DropdownMenu>
                    <DropdownMenu.Trigger asChild>
                      <IconButton size="small" variant="ghost">
                        <MixerHorizontalIcon />
                      </IconButton>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content
                      align="end"
                      side="bottom"
                      sideOffset={4}
                    >
                      <Flex
                        direction="column"
                        style={{ padding: "0 var(--size-100) var(--size-200)" }}
                        gap={100}
                      >
                        {/* Author Filter */}
                        <Heading level={4} weight="bold">
                          Filter by
                        </Heading>
                        <Flex gap={200} direction="column">
                          <Select
                            value={filterAuthor}
                            onValueChange={handleAuthorChange}
                          >
                            <Select.Trigger aria-label="Author">
                              <Select.Value>{filterAuthorDisplay}</Select.Value>
                            </Select.Trigger>
                            <Select.Content
                              position="popper"
                              side="bottom"
                              align="start"
                              sideOffset={4}
                            >
                              <Select.Item value="all">All authors</Select.Item>
                              {authors.map((author, index) => (
                                <Select.Item key={index} value={author}>
                                  {author}
                                </Select.Item>
                              ))}
                            </Select.Content>
                          </Select>

                          {/* Date Filter */}
                          <Flex gap={100}>
                            <DatePicker
                              selectsRange={true}
                              startDate={startDate}
                              endDate={endDate}
                              onChange={(update) => {
                                setDateRange(update);
                              }}
                              customInput={
                                <DateRangePickerInput
                                  label="Dates"
                                  placeholder="Enter start date"
                                />
                              }
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                    </DropdownMenu.Content>
                  </DropdownMenu>
                </Flex>
                <ScrollArea>
                  <Flex direction={"row"} gap={100} style={{ width: "100%" }}>
                    {comments.filter(filterComments).length > 0 ? (
                      comments
                        .filter(filterComments) // Apply the filter here
                        .map((comment, index) => (
                          <Flex
                            key={comment.id}
                            customClass="commentCard"
                            direction="column"
                            gap={100}
                            style={{ width: "100%" }}
                          >
                            <Paragraph size="medium">
                              By {comment.user.handle || "Unknown"} on
                              {new Date(
                                comment.created_at
                              ).toLocaleDateString()}
                              :
                            </Paragraph>
                            <Separator />
                            <Paragraph size="medium">
                              {comment.message}
                            </Paragraph>
                            <Flex
                              justify="end"
                              style={{
                                marginTop: "auto",
                                paddingTop: "var(--size-200)",
                              }}
                            >
                              <Button
                                size="medium"
                                variant="solid"
                                onClick={() => openDialog(comment.id)}
                              >
                                Answer
                              </Button>
                            </Flex>
                          </Flex>
                        ))
                    ) : (
                      <Flex
                        customClass="noComments"
                        justify="center"
                        align="center"
                      >
                        <Heading level={3} weight="bold">
                          No comments
                        </Heading>
                      </Flex>
                    )}
                  </Flex>
                </ScrollArea>
              </Flex>
            )}
          </Flex>
        </>
      )}
      <DialogAnswer
        open={isDialogOpen}
        onOpenChange={setIsDialogOpen}
        replyText={replyText}
        handleReplyChange={handleReplyChange}
        submitReply={() => submitReply()}
      />
    </Flex>
  );
};

export default FigmaFileDetails;
