import React, { useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon, SizeIcon } from "@radix-ui/react-icons";
import "./DialogTemplatePreview.scss";
import {
  Flex,
  Heading,
  Button,
  CodeHighlight,
  Separator,
  Paragraph,
  IconButton,
  Iframe,
  ScrollArea,
} from "blocksin-system";
import TableComponent3 from "../pages/TableComponent3";

function DialogTemplatePreview({ isOpen, onClose, template }) {
  const [expandedCards, setExpandedCards] = useState({}); // Store expanded state of cards

  const toggleCardExpansion = (index) => {
    setExpandedCards((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const renderContent = (content, parentIndex) => {
    return content
      .slice()
      .reverse()
      .map((item, index) => {
        // Implement rendering logic for each type of item
        if (typeof item === "string") {
          const parser = new DOMParser();
          const doc = parser.parseFromString(item, "text/html");
          const body = doc.body;

          return Array.from(body.childNodes).map((node, nodeIndex) => {
            const element = node;
            const classes = Array.from(element.classList);

            if (element.tagName === "DIV" && classes.includes("H1")) {
              return (
                <Heading
                  level={1}
                  key={`${parentIndex}-${index}-${nodeIndex}`}
                  weight="bold"
                >
                  {element.textContent}
                </Heading>
              );
            }

            if (element.tagName === "DIV" && classes.includes("H2")) {
              return (
                <Heading
                  level={2}
                  key={`${parentIndex}-${index}-${nodeIndex}`}
                  weight="bold"
                >
                  {element.textContent}
                </Heading>
              );
            }
            if (element.tagName === "DIV" && classes.includes("H3")) {
              return (
                <Heading
                  level={3}
                  key={`${parentIndex}-${index}-${nodeIndex}`}
                  weight="bold"
                >
                  {element.textContent}
                </Heading>
              );
            }
            if (element.tagName === "DIV" && classes.includes("H4")) {
              return (
                <Heading
                  level={4}
                  key={`${parentIndex}-${index}-${nodeIndex}`}
                  weight="bold"
                >
                  {element.textContent}
                </Heading>
              );
            }
            if (element.tagName === "DIV" && classes.includes("text")) {
              return (
                <Paragraph
                  size="large"
                  key={`${parentIndex}-${index}-${nodeIndex}`}
                >
                  {element.textContent}
                </Paragraph>
              );
            }

            if (element.tagName === "A" && classes.includes("button")) {
              return (
                <Button
                  variant="solid"
                  size="medium"
                  key={`${parentIndex}-${index}-${nodeIndex}`}
                >
                  {element.textContent}
                </Button>
              );
            }

            if (element.tagName === "IMG" && classes.includes("image")) {
              return (
                <img
                  className="image"
                  key={`${parentIndex}-${index}-${nodeIndex}`}
                  src={item.attributes && item.attributes.src}
                  alt={(item.attributes && item.attributes.alt) || "Image"}
                />
              );
            }

            if (typeof item === "string" && item.includes("<table")) {
              return (
                <>
                  {/* <TableComponent2
                    key={`${parentIndex}-${index}`}
                    tableHtml={item}
                  /> */}
                  <TableComponent3
                    key={`${parentIndex}-${index}`}
                    tableData={item}
                  />
                </>
              );
            }

            if (element.tagName === "IFRAME" || classes.includes("iframe")) {
              return (
                <Iframe
                  key={`${parentIndex}-${index}-${nodeIndex}`}
                  url={element.getAttribute("src")}
                  title={element.getAttribute("title")}
                />
              );
            }

            if (element.tagName === "CODE" && classes.includes("code-block")) {
              return (
                <CodeHighlight
                  key={`${parentIndex}-${index}-${nodeIndex}`}
                  text={element.textContent}
                />
              );
            }

            if (element.tagName === "DIV" && classes.includes("container")) {
              // Parse the inner HTML of the container element to get its components
              const containerParser = new DOMParser();
              const containerDoc = containerParser.parseFromString(
                element.innerHTML,
                "text/html"
              );
              const containerComponents = Array.from(
                containerDoc.body.childNodes
              );

              return (
                <Flex
                  direction={"row"}
                  customClass="container"
                  key={`${parentIndex}-${index}`}
                >
                  {containerComponents.map((comp, compIndex) => {
                    const componentClasses = Array.from(comp.classList);

                    // Check if the component is a card
                    if (componentClasses.includes("card")) {
                      return renderCard(
                        comp,
                        `${parentIndex}-${index}-${compIndex}`
                      );
                    }

                    return null;
                  })}
                </Flex>
              );
            }

            const combinedIndex = parentIndex
              ? `${parentIndex}-${index}`
              : `${index}`;

            if (element.tagName === "DIV" && classes.includes("card")) {
              // Parse the inner HTML of the card element to get its components
              const cardParser = new DOMParser();
              const cardDoc = cardParser.parseFromString(
                element.innerHTML,
                "text/html"
              );

              const cardImage = cardDoc.querySelector("img");
              const cardTexts = Array.from(
                cardDoc.querySelectorAll("div")
              ).filter((div) => !div.classList.contains("card"));
              const cardButton = cardDoc.querySelector("a.button");
              const cardIframe = cardDoc.querySelector("iframe");

              return (
                <Flex
                  direction={"column"}
                  customClass={`card ${
                    expandedCards[combinedIndex] ? "cardExpanded" : ""
                  }`}
                  key={index}
                >
                  {/* Render image, texts, and button */}
                  <Flex
                    direction={"column"}
                    customClass={"cardImageText"}
                    align={"start"}
                  >
                    {cardImage && (
                      <img
                        src={cardImage.src}
                        alt={cardImage.alt}
                        className="image"
                      />
                    )}
                    <Flex customClass={"cardTexts"} direction={"column"}>
                      {cardTexts.map((text, textIndex) => (
                        <Paragraph key={`${combinedIndex}-text-${textIndex}`}>
                          {text.textContent}
                        </Paragraph>
                      ))}
                      {cardButton && (
                        <Button variant="solid" size="large">
                          {cardButton.textContent}
                        </Button>
                      )}
                    </Flex>
                  </Flex>

                  {/* Toggle Button for Expansion */}
                  <IconButton
                    size="medium"
                    onClick={() => toggleCardExpansion(combinedIndex)}
                  >
                    <SizeIcon />
                  </IconButton>

                  {/* Iframe Component for Expanded View */}
                  {expandedCards[combinedIndex] && cardIframe && (
                    <Flex customClass={"showMore"}>
                      <Iframe
                        url={cardIframe.src}
                        title={
                          cardIframe.getAttribute("title") || "Embedded Content"
                        }
                      />
                    </Flex>
                  )}
                </Flex>
              );
            }

            // Add more conditions for other HTML tags and classes
            return null;
          }); // eslint-disable-next-line
        } else {
          return null;
        }
      });
  };

  const renderCard = (cardElement, key) => {
    const cardImage = cardElement.querySelector("img");
    const cardTexts = Array.from(cardElement.querySelectorAll("div")).filter(
      (div) => !div.classList.contains("card")
    );
    const cardButton = cardElement.querySelector("a.button");
    const cardIframe = cardElement.querySelector("iframe");

    // Determine if the card should be expanded
    const isExpanded = expandedCards[key];

    return (
      <Flex
        direction={"column"}
        customClass={`card ${isExpanded ? "cardExpanded" : ""}`}
        key={key}
      >
        {/* Render image, texts, and button */}
        {cardImage && (
          <img
            src={cardImage.src}
            alt={cardImage.alt || "Card image"}
            className="image"
          />
        )}
        <Flex customClass={"cardTexts"} direction={"column"}>
          {cardTexts.map((text, textIndex) => (
            <Paragraph key={`${key}-text-${textIndex}`}>
              {text.textContent}
            </Paragraph>
          ))}
          {cardButton && (
            <Button variant="solid" size="large">
              {cardButton.textContent}
            </Button>
          )}
        </Flex>

        {/* Toggle Button for Expansion */}
        <IconButton size="medium" onClick={() => toggleCardExpansion(key)}>
          <SizeIcon />
        </IconButton>

        {/* Iframe Component for Expanded View */}
        {isExpanded && cardIframe && (
          <Flex customClass={"showMore"}>
            <Iframe
              url={cardIframe.src}
              title={cardIframe.getAttribute("title") || "Embedded Content"}
            />
          </Flex>
        )}
      </Flex>
    );
  };

  // const TableComponent2 = ({ tableHtml }) => {
  //   return (
  //     <div className="Table" dangerouslySetInnerHTML={{ __html: tableHtml }} />
  //   );
  // };

  return (
    <Dialog.Root className="DialogRoot" open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />

        <Dialog.Content className="DialogContent DialogTemplatePreview">
          <ScrollArea>
            <Flex direction={"column"} customClass={"templateContent"}>
              {template ? (
                <>
                  <Heading level={4} weight="bold">
                    {template.title}
                  </Heading>
                  <Separator />
                  <Flex customClass="renderedTemplate" direction="column">
                    {renderContent(template.content)}
                  </Flex>
                </>
              ) : (
                <Paragraph>No template selected</Paragraph>
              )}
            </Flex>
          </ScrollArea>
          <Flex customClass="closeButton">
            <Dialog.Close asChild>
              <IconButton aria-label="Close" size="small" variant="ghost">
                <Cross2Icon />
              </IconButton>
            </Dialog.Close>
          </Flex>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default DialogTemplatePreview;
