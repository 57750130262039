import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./Updates.scss";
import GithubLogoWhite from "./assets/github-mark-white.svg";
import GithubLogoBlack from "./assets/github-mark-black.svg";
import { ExternalLinkIcon } from "@radix-ui/react-icons";
import { Autoplay } from "swiper/modules";
import { Flex, Paragraph, IconButton, Tooltip } from "blocksin-system";

const UpdatesSwiper = ({ currentUser }) => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  const [commits, setCommits] = useState([]);
  const [currentTheme, setCurrentTheme] = useState(
    localStorage.getItem("theme") || "Light mode"
  );
  const [fetchError, setFetchError] = useState("");
  const [isLoading, setIsLoading] = useState(true); // New loading state
  //
  //
  const githubUsername = currentUser?.widgetGithubUsername;
  const githubRepo = currentUser?.widgetGithubRepo;
  //
  //
  const openCommitUrl = (commitUrl) => {
    window.open(commitUrl, "_blank", "noopener,noreferrer");
  };
  //
  //
  //
  //
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${serverUrl}/api/users/users/${currentUser._id}/github-commits`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        // console.log("Fetched data:", data);

        setCommits(data);
        setFetchError(null);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setFetchError("Failed to fetch updates: " + error.message);
      }
      setIsLoading(false);
    };

    if (currentUser) {
      fetchData();
    }
  }, [currentUser, serverUrl]);

  useEffect(() => {
    const handleThemeChange = (event) => {
      setCurrentTheme(localStorage.getItem("theme") || "Light mode");
    };

    window.addEventListener("themeChange", handleThemeChange);

    return () => {
      window.removeEventListener("themeChange", handleThemeChange);
    };
  }, []);

  const getLogo = () => {
    return currentTheme === "Dark mode" ? GithubLogoBlack : GithubLogoWhite;
  };

  if (isLoading && githubUsername) {
    return (
      <Flex direction={"column"} customClass={"WidgetsPlaceholder"}>
        <Paragraph size="large" weight="bold">
          Loading
        </Paragraph>
      </Flex>
    );
  }

  if (fetchError && !isLoading) {
    return (
      <Flex direction={"column"} customClass={"WidgetsPlaceholder"}>
        <Paragraph customClass="Error" size="large" weight="bold">
          Error: Used URL: {githubUsername} and {githubRepo} seems to be wrong.{" "}
          {fetchError}
        </Paragraph>
      </Flex>
    );
  }

  // Render placeholder or Swiper based on the URL and commits data
  return (
    <>
      {commits.length > 0 ? (
        <Flex direction={"column"} customClass={"Updates"}>
          <Swiper
            key={commits.length}
            modules={[Autoplay]}
            spaceBetween={16}
            // centeredSlides={true}
            slidesPerView={3}
            loop={true}
            autoplay={{ delay: 3500, disableOnInteraction: false }}
          >
            {commits.map((commit) => (
              <SwiperSlide key={commit.sha}>
                <Flex direction={"column"} style={{ height: "100%" }}>
                  <img
                    src={getLogo()}
                    className="UpdatesLogo"
                    alt="GitHub Logo"
                  />
                  <Paragraph
                    customClass={"CommitTitle"}
                    size="medium"
                    weight="normal"
                  >
                    {commit.commit.message}
                  </Paragraph>
                  <Flex customClass={"UpdatesFooter"}>
                    <Flex direction={"column"}>
                      <Paragraph size="medium">
                        by {commit.committer.login} / GitHub
                      </Paragraph>
                      <Paragraph customClass="CommitDate" size="medium">
                        {new Date(
                          commit.commit.committer.date
                        ).toLocaleDateString()}
                      </Paragraph>
                    </Flex>
                    <Tooltip delayDuration={200}>
                      <Tooltip.Trigger asChild>
                        <IconButton
                          size="small"
                          onClick={() => openCommitUrl(commit.html_url)}
                        >
                          <ExternalLinkIcon />
                        </IconButton>
                      </Tooltip.Trigger>
                      <Tooltip.Content sideOffset={2} side={"top"}>
                        <Paragraph size="medium">
                          See commit in Github
                        </Paragraph>
                      </Tooltip.Content>
                    </Tooltip>
                  </Flex>
                </Flex>
              </SwiperSlide>
            ))}
          </Swiper>
        </Flex>
      ) : (
        <Flex direction={"column"} gap={100} customClass={"WidgetsPlaceholder"}>
          <Paragraph size="large" weight="bold">
            Control center
          </Paragraph>
          <Paragraph size="large">
            Here you can find all the widgets.
            <br />
            Customise your Control center with Widget configurator on the right
            side.
          </Paragraph>
        </Flex>
      )}
    </>
  );
};

export default UpdatesSwiper;
