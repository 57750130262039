import React, { useEffect, useState, useRef } from "react";
import "./Community.scss";
import Nav from "./Nav";
import DialogTemplatePreview from "./../dialogs/DialogTemplatePreview";
import TemplateCard from "../TemplateCard";
import TemplateItem from "./TemplateItem";
import { Flex, Heading, Input, Loader, ScrollArea } from "blocksin-system";
// import axios from "axios";

const Community = () => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  //
  const dropdownRef = useRef(null); // Ref for the dropdown
  const searchInputRef = useRef(null); // Ref for the search input
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        (!searchInputRef.current ||
          !searchInputRef.current.contains(event.target))
      ) {
        setDropdownOpen(false);
        setSearchQuery(""); // Clear the search query
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, searchInputRef]);

  // Function to filter templates based on the search query
  const filteredTemplates = templates.filter(
    (template) =>
      template.title.toLowerCase().includes(searchQuery) ||
      template.author?.username.toLowerCase().includes(searchQuery)
  );
  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    setDropdownOpen(query.length > 0 && filteredTemplates.length > 0);
  };

  useEffect(() => {
    const fetchPublicTemplates = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${serverUrl}/api/templates/public`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const templatesData = await response.json();
        setTemplates(templatesData); // Set public templates
        console.log(templatesData);
      } catch (error) {
        console.error("Error fetching public templates:", error);
      } finally {
        setIsLoading(false);
      }
    };
    const allTemplates = [];
    fetchPublicTemplates();
    setTemplates(allTemplates); // Set local templates
  }, [serverUrl]);

  const handlePreview = (template) => {
    setSelectedTemplate(template);
    setIsPreviewOpen(true);
  };

  // Check if handleTemplateClick is being called
  const handleTemplateClick = (template) => {
    setSelectedTemplate(template);
    setIsPreviewOpen(true);
    setDropdownOpen(false); // Close dropdown after selection
    setSearchQuery(""); // Clear the search query
  };

  // Sort templates by date in descending order and take the first two
  const sortedTemplates = templates
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .slice(0, 2);

  useEffect(() => {
    if (dropdownOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [dropdownOpen]);

  return (
    <Flex direction={"column"} customClass={"Community"} align={"center"}>
      <DialogTemplatePreview
        isOpen={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
        template={selectedTemplate}
        content={selectedTemplate}
      />
      {/* <Flex direction={"column"} customClass={"CommunityHeader"}>
        <MenubarPage />
      </Flex> */}
      <Nav />

      <Flex direction={"column"} customClass={"CommunityBody"}>
        <Flex direction={"column"} customClass={"CommunityHero flareGreen"}>
          <Heading level={2} weight="bold">
            Welcome to the BlocksIn Community
          </Heading>
          <Heading level={3}>
            Kick start your Design System Documentaions with ready templates
            made by experienced product designers.
          </Heading>
          <Flex direction={"column"} customClass={"templateSearch"}>
            <Input
              label="Search"
              placeholder="Enter keyword"
              errormsg={false}
              type="search"
              value={searchQuery} // Set value to searchQuery
              onChange={handleSearchChange}
              ref={searchInputRef}
            />
            {dropdownOpen && filteredTemplates.length > 0 && (
              <Flex
                customClass="customDropdown"
                direction={"column"}
                ref={dropdownRef}
              >
                <ScrollArea>
                  {filteredTemplates.map((template, index) => (
                    <TemplateItem
                      key={index}
                      template={template}
                      handleTemplateClick={handleTemplateClick}
                    />
                  ))}
                </ScrollArea>
              </Flex>
            )}
          </Flex>
        </Flex>
        <Heading level={3} weight="bold">
          Discover what's new
        </Heading>

        <Flex customClass={"featuredTemplatesGrid"} gap={100}>
          {isLoading && <Loader />}
          {sortedTemplates.map((template, index) => (
            <TemplateCard
              key={index}
              template={template}
              handlePreview={handlePreview}
              featured
            />
          ))}
        </Flex>
        <Heading level={3} weight="bold">
          By Design System Community
        </Heading>

        <Flex customClass={"templatesGrid"} gap={100}>
          {isLoading && <Loader />}
          {templates.map((template, index) => (
            <TemplateCard
              key={index}
              template={template}
              handlePreview={handlePreview}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Community;
