import React from "react";
// import Heading from "../components/Heading";
// import Paragraph from "../components/Paragraph";
// import CodeHighlight from "../components/CodeHighlight";
import "../Table.scss";
import {
  Heading,
  CodeHighlight,
  Paragraph,
} from "blocksin-system";

const TableComponent3 = ({ tableData }) => {
  // console.log(tableData);
  const renderTable = (data) => {
    if (!data || data.length === 0) {
      return null;
    }

    return (
      <table className="Table">
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={row.id || rowIndex}>
              {row.cells.map((cell, cellIndex) =>
                cell.isHeader ? (
                  <th key={cell.id || cellIndex}>{renderCellContent(cell)}</th>
                ) : (
                  <td key={cell.id || cellIndex}>{renderCellContent(cell)}</td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderCellContent = (cell) => {
    // Handle different types of cell content
    if (cell.type === "code") {
      return <CodeHighlight text={cell.content} />;
    } else if (cell.type === "text") {
      // Check for heading levels in cell content
      if (cell.content.includes("<h1>")) {
        return <Heading level={1}>{parseHTML(cell.content)}</Heading>;
      } else if (cell.content.includes("<h2>")) {
        return <Heading level={2}>{parseHTML(cell.content)}</Heading>;
      } else if (cell.content.includes("<h3>")) {
        return <Heading level={3}>{parseHTML(cell.content)}</Heading>;
      } else {
        return <Paragraph size="large">{parseHTML(cell.content)}</Paragraph>;
      }
    }
    return null;
  };

  const parseHTML = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };

  // Transform your HTML content data into a format that can be used by the component
  const transformData = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const rows = Array.from(doc.querySelectorAll("table.Table tr"));

    return rows.map((row) => {
      const cells = Array.from(row.children).map((cell) => {
        const isHeader = cell.tagName.toLowerCase() === "th";
        let content;
        let type;

        if (cell.querySelector("code")) {
          content = cell.querySelector("code").innerHTML; // Use innerHTML for code to preserve formatting
          type = "code";
        } else {
          content = cell.querySelector(".text")?.textContent || "";
          type = "text";
        }

        return { isHeader, content, type };
      });
      return { cells };
    });
  };

  const transformedData = transformData(tableData);

  return renderTable(transformedData);
};

export default TableComponent3;
