import React, { useState, useEffect } from "react";
import * as Menubar from "@radix-ui/react-menubar";
import { useNavigate, useLocation } from "react-router-dom";

import { DotFilledIcon } from "@radix-ui/react-icons";
import "./radix-styles/Menubar.scss";
import AppLogo from "./assets/AppLogo2.svg";
import AppLogoWhite from "./assets/AppLogo2-White.svg";
import { Button, Flex, Toast } from "blocksin-system";

const RADIO_ITEMS = ["Dark mode", "Light mode"];

const MenubarDemo = ({ logo }) => {
  const [theme, setTheme] = useState("Dark mode"); // Default to Light mode
  const [showToastCopied, setShowToastCopied] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // const goBack = () => {
  //   navigate("/app");
  // };

  const navigateToOrganizationMainPage = () => {
    // Extract the organization name from the current path
    const pathParts = location.pathname.split("/");
    const organizationName = pathParts[1]; // Assuming the URL structure is /organizationName/otherSegments
    navigate(`/${organizationName}`); // Redirect to the main page of the organization
  };

  const corporateLogoBase64 = logo;

  const goBack = () => {
    if (location.pathname.includes("/app/")) {
      navigate("/app");
    } else if (location.pathname.includes("/community")) {
      navigate("/");
    } else {
      // Handle other cases or use navigate(-1) to go back to the previous page
      navigate(-1);
    }
  };

  useEffect(() => {
    // Check local storage for saved theme
    const savedTheme = localStorage.getItem("theme") || "Dark mode";
    setTheme(savedTheme);

    // Apply class to HTML element
    document.documentElement.className =
      savedTheme === "Dark mode" ? "darkmode" : "";
  }, []);

  const handleThemeChange = (newTheme) => {
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
    document.documentElement.className =
      newTheme === "Dark mode" ? "darkmode" : "";

    // Dispatch a custom event
    window.dispatchEvent(new CustomEvent("themeChange", { detail: newTheme }));
  };
  const logoSrc = theme === "Dark mode" ? AppLogoWhite : AppLogo;
  //
  //
  //
  // Function to copy current URL to clipboard
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        // console.log("URL copied to clipboard");
        setShowToastCopied(true);
        // Optionally, show feedback to the user (e.g., toast notification)
      })
      .catch((err) => {
        // console.error("Failed to copy URL: ", err);
      });
  };

  return (
    <Menubar.Root className="MenubarRoot">
      <Toast
        showToast={showToastCopied}
        setShowToast={setShowToastCopied}
        text="Copied to clipboard 🚀"
        time={3000}
        simple
      />

      <Flex
        customClass="NavLogo"
        style={{ marginLeft: "var(--size-200)" }}
        onClick={navigateToOrganizationMainPage}
      >
        {location.pathname.startsWith("/") && corporateLogoBase64 ? (
          <img
            src={corporateLogoBase64}
            alt="Corporate Logo"
            style={{ cursor: "pointer" }}
          />
        ) : (
          <img
            src={logoSrc}
            alt="Design System App Logo"
            style={{ cursor: "pointer" }}
          />
        )}
      </Flex>

      <Flex customClass="MainNav">
        <Menubar.Menu>
          <Menubar.Trigger className="MenubarTrigger">Menu</Menubar.Trigger>
          <Menubar.Portal>
            <Menubar.Content
              className="MenubarContent"
              align="start"
              sideOffset={4}
              alignOffset={0}
            >
              <Menubar.RadioGroup
                value={theme}
                onValueChange={handleThemeChange}
              >
                {RADIO_ITEMS.map((item) => (
                  <Menubar.RadioItem
                    className="MenubarRadioItem inset"
                    key={item}
                    value={item}
                  >
                    <Menubar.ItemIndicator className="MenubarItemIndicator">
                      <DotFilledIcon />
                    </Menubar.ItemIndicator>
                    {item}
                  </Menubar.RadioItem>
                ))}
              </Menubar.RadioGroup>
              <Menubar.Separator className="MenubarSeparator" />

              <Menubar.Item className="MenubarItem" onClick={copyToClipboard}>
                Share
              </Menubar.Item>
            </Menubar.Content>
          </Menubar.Portal>
        </Menubar.Menu>
      </Flex>
      <Flex gap={200} customClass={"NavActionsPage"}>
        <Button size="small" onClick={goBack}>
          Go back
        </Button>
      </Flex>
    </Menubar.Root>
  );
};

export default MenubarDemo;
