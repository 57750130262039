import React from "react";
import "./ScoreBoardPage.scss";
// { useState, useEffect }
import { Flex, Separator, Heading, TopBanner } from "blocksin-system";

const ScoreBoard = ({
  currentUser,
  dismissNotification,
  currentNotification,
}) => {
  return (
    <>
      <Flex direction={"column"} customClass="Page" align={"center"}>
        {currentNotification && (
          <TopBanner
            username={currentUser?.username}
            notification={currentNotification}
            onDismiss={dismissNotification}
          />
        )}
        
        <Flex direction={"column"} gap={200} customClass="PageBody">
          <Flex direction={"column"} gap={200} customClass="PageContent">
            <Heading level={3} weight="bold">
              Score Board
            </Heading>
            <Flex customClass={"PageHeader"}>
              <Flex direction={"column"} gap={100}>
                <Separator />
                <Heading level={4}>Top performers</Heading>

                <Flex
                  gap={200}
                  style={{
                    marginTop: "var(--size-100)",
                    marginBottom: "var(--size-100)",
                  }}
                ></Flex>
                <Separator />
                <Heading level={4}>Your achievements</Heading>

                <Flex
                  gap={200}
                  style={{
                    marginTop: "var(--size-100)",
                    marginBottom: "var(--size-100)",
                  }}
                ></Flex>
                <Flex
                  gap={200}
                  style={{
                    marginTop: "var(--size-100)",
                    marginBottom: "var(--size-100)",
                  }}
                ></Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default ScoreBoard;
