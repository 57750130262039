import React, { useState, useEffect } from "react";
import "./../radix-styles/Dialog.scss";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon, EyeClosedIcon, EyeOpenIcon } from "@radix-ui/react-icons";
import {
  Button,
  Flex,
  Paragraph,
  Heading,
  Input,
  IconButton,
  Tooltip,
} from "blocksin-system";

const DialogGithubWidget = ({
  onUpdate,
  // username,
  open,
  onOpenChange,
  //
  // probably to delete
  currentMainGithubUsername,
  currentMainGithubAccessToken,
  currentGithubRepo,
  currentUser,
  refreshUserData,
  //
  //
  githubTokenExists,
}) => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  const [blurSensitiveInfo, setBlurSensitiveInfo] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [mainGithubUsername, setMainGithubUsername] = useState(
    currentMainGithubUsername || ""
  );
  const [githubRepo, setGithubRepo] = useState(currentGithubRepo || "");
  const [mainGithubAccessToken, setMainGithubAccessToken] = useState(
    currentMainGithubAccessToken || ""
  );
  //
  //
  //
  //
  useEffect(() => {
    if (!open) {
      document.body.style.pointerEvents = ""; // Remove the style when dialog is closed
    }
  }, [open]);
  //
  //
  // Function to toggle blur effect
  const toggleBlur = () => {
    setBlurSensitiveInfo(!blurSensitiveInfo);
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token is missing for authenticated request.");
      return;
    }

    // Validation checks
    if (!mainGithubUsername.trim()) {
      setErrorMessage("GitHub Username cannot be empty.");
      return;
    }
    // Check for access token only if it does not exist
    if (!githubTokenExists && !mainGithubAccessToken.trim()) {
      setErrorMessage("GitHub Access Token cannot be empty.");
      return;
    }
    if (!githubRepo.trim()) {
      setErrorMessage("GitHub Repo name cannot be empty.");
      return;
    }

    const requestBody = {
      widgetGithubUsername: mainGithubUsername,
      widgetGithubRepo: githubRepo,
      ...(mainGithubAccessToken && {
        widgetGithubAccessToken: mainGithubAccessToken,
      }),
    };

    try {
      const response = await fetch(
        `${serverUrl}/api/users/users/${currentUser._id}/github-widget`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message);
      }
      await refreshUserData();
      // Call the update function and reset the state
      // onUpdate(updatedUser);
      setMainGithubUsername("");
      setMainGithubAccessToken("");
      setGithubRepo("");
      setErrorMessage("");

      // Close the dialog manually if everything is valid
      // onOpenChange(false);
    } catch (error) {
      setErrorMessage(error.message);
    }
    if (mainGithubAccessToken) {
      setMainGithubAccessToken("");
    }
    onUpdate({ mainGithubUsername, mainGithubAccessToken, githubRepo });
    onOpenChange(false);
  };

  return (
    <>
      <Dialog.Root
        className="DialogRoot"
        open={open}
        onOpenChange={onOpenChange}
      >
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent">
            <Heading level={3} weight="bold">
              Add GitHub Widget
            </Heading>

            <Flex customClass="DialogDescription" direction={"column"}>
              <Paragraph size="large">
                Add GitHub intergation to your Control Panel by using endpoint
                URL / REST API.
              </Paragraph>
              <br />
              <Paragraph size="large">
                https://api.github.com/repos/username/reponame/
                <br />
                commits?sha=branchname
              </Paragraph>
            </Flex>
            <Flex direction="column" gap={200}>
              {!githubTokenExists && (
                <Input
                  label="Access Token"
                  placeholder="Enter Access Token"
                  errormsg={false}
                  type="text"
                  value={mainGithubAccessToken}
                  onChange={(e) => setMainGithubAccessToken(e.target.value)}
                  className={blurSensitiveInfo ? "inputBlurEffect" : ""}
                />
              )}
              <Input
                label="GitHub Username"
                placeholder="Enter GitHub Username"
                errormsg={false}
                type="text"
                value={mainGithubUsername}
                onChange={(e) => setMainGithubUsername(e.target.value)}
                className={blurSensitiveInfo ? "inputBlurEffect" : ""}
              />
              <Input
                label="GitHub Repo"
                placeholder="Enter GitHub repo name"
                errormsg={false}
                type="text"
                value={githubRepo}
                onChange={(e) => setGithubRepo(e.target.value)}
              />
              {errorMessage && <p className="Error">{errorMessage}</p>}
            </Flex>

            <Flex
              direction="row"
              align="end"
              justify={"end"}
              gap={200}
              style={{
                marginTop: "var(--size-400)",
                paddingTop: "var(--size-150)",
                borderTop: "1px solid var(--border-neutral-subtle)",
              }}
            >
              <Dialog.Close asChild>
                <Button
                  size="medium"
                  variant="solid"
                  onClick={(e) => handleUpdateProfile(e)}
                >
                  Add integration
                </Button>
              </Dialog.Close>
            </Flex>
            <Flex customClass="closeButton" gap={100}>
              <Tooltip delayDuration={200}>
                <Tooltip.Trigger asChild>
                  <IconButton
                    aria-label={
                      blurSensitiveInfo
                        ? "Show sensitive information"
                        : "Hide sensitive information"
                    }
                    size="small"
                    variant="ghost"
                    onClick={toggleBlur}
                  >
                    {blurSensitiveInfo ? <EyeClosedIcon /> : <EyeOpenIcon />}
                  </IconButton>
                </Tooltip.Trigger>
                <Tooltip.Content sideOffset={2}>
                  <Paragraph size="medium">Hide sensitive data</Paragraph>
                </Tooltip.Content>
              </Tooltip>

              <Dialog.Close asChild>
                <IconButton aria-label="Close" size="small" variant="ghost">
                  <Cross2Icon />
                </IconButton>
              </Dialog.Close>
            </Flex>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

export default DialogGithubWidget;
