import React, { useState, useEffect } from "react";
import "./../radix-styles/Dialog.scss";
import * as Dialog from "@radix-ui/react-dialog";
// import Input from "../components/Input";
import {
  Cross2Icon,
  EyeClosedIcon,
  EyeOpenIcon,
  QuestionMarkIcon,
} from "@radix-ui/react-icons";
import * as Popover from "@radix-ui/react-popover";
import Video1 from "../assets/slides/figmaWidget/figma-how-to-slide-1.mp4";
import Video2 from "../assets/slides/figmaWidget/figma-how-to-slide-2.mp4";
import {
  Button,
  Flex,
  Input,
  Paragraph,
  Heading,
  Tooltip,
  IconButton,
} from "blocksin-system";
const DialogFigmaWidget = ({
  onUpdate,
  username,
  open,
  onOpenChange,
  currentMainFigmaAccessToken,
  currentMainFigmaFileId,
}) => {
  const [blurSensitiveInfo, setBlurSensitiveInfo] = useState(false);

  // const handleHowToFigmaWidget = () => {
  // };

  const [errorMessage, setErrorMessage] = useState("");
  const [mainFigmaFileId, setMainFigmaFileId] = useState(
    currentMainFigmaFileId || ""
  );
  const [mainFigmaUserId, setMainFigmaUserId] = useState("");

  const [mainFigmaAccessToken, setMainFigmaAccessToken] = useState(
    currentMainFigmaAccessToken || ""
  );

  // Function to toggle blur effect
  const toggleBlur = () => {
    setBlurSensitiveInfo(!blurSensitiveInfo);
  };

  // Check if the local storage is updated and update the state accordingly
  useEffect(() => {
    const accessToken = localStorage.getItem("mainFigmaAccessToken");
    if (accessToken) {
      setMainFigmaAccessToken(accessToken);
    }
  }, [open]); // Rerun this effect when dialog is opened

  const handleUpdateProfile = (e) => {
    e.preventDefault();

    if (!mainFigmaAccessToken.trim() || !mainFigmaFileId.trim()) {
      setErrorMessage("Figma Access Token and File Id cannot be empty.");
      return;
    }

    const updatedUser = {
      mainFigmaFileId,
      mainFigmaAccessToken,
      mainFigmaUserId,
    };

    onUpdate(updatedUser); // Use the onUpdate function passed as a prop

    setErrorMessage("");
    onOpenChange(false);
  };

  return (
    <>
      <Dialog.Root
        className="DialogRoot"
        open={open}
        onOpenChange={onOpenChange}
      >
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent">
            <Heading level={3} weight="bold">
              Add Figma Widget
            </Heading>
            <Flex customClass="DialogDescription" direction={"column"}>
              <Paragraph size="large">
                Add Figma intergation to your Control Panel by using Access
                Token.
              </Paragraph>
            </Flex>
            <Flex direction="column" gap={200}>
              {!currentMainFigmaAccessToken && (
                <Flex gap={100}>
                  <Popover.Root className="PopoverRoot">
                    <Popover.Trigger asChild>
                      <IconButton size="medium" variant="ghost">
                        <QuestionMarkIcon />
                      </IconButton>
                    </Popover.Trigger>
                    <Popover.Portal>
                      <Popover.Content
                        className="PopoverContent"
                        side="right"
                        align="center"
                        alignOffset={0}
                        sideOffset={12}
                      >
                        <Flex customClass="PopoverContentInner">
                          <Flex direction="column" customClass="leftSide">
                            <video
                              autoPlay
                              loop
                              muted
                              style={{ width: "100%", height: "auto" }}
                            >
                              <source src={Video1} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          </Flex>

                          <Flex direction="column" customClass="rightSide">
                            <Paragraph size="medium">
                              To obtain a Figma Access Token, click on your
                              profile in the top left corner to open the
                              dropdown menu. Select 'Settings' and navigate to
                              the 'Account' tab. Scroll to the 'Personal Access
                              Token' section. Click on 'Generate New Token'.
                              Ensure 'File content' is set to 'Read-only' and
                              'Comments' to 'Write'.
                            </Paragraph>
                          </Flex>
                        </Flex>
                      </Popover.Content>
                    </Popover.Portal>
                  </Popover.Root>
                  <Input
                    label="Access Token"
                    placeholder="Enter Access Token"
                    errormsg={false}
                    type="text"
                    value={mainFigmaAccessToken}
                    onChange={(e) => setMainFigmaAccessToken(e.target.value)}
                    className={blurSensitiveInfo ? "inputBlurEffect" : ""}
                  />
                </Flex>
              )}
              <Flex gap={100}>
                <Popover.Root className="PopoverRoot">
                  <Popover.Trigger asChild>
                    <IconButton size="medium" variant="ghost">
                      <QuestionMarkIcon />
                    </IconButton>
                  </Popover.Trigger>
                  <Popover.Portal>
                    <Popover.Content
                      className="PopoverContent"
                      side="right"
                      align="center"
                      alignOffset={0}
                      sideOffset={12}
                    >
                      <Flex customClass="PopoverContentInner">
                        <Flex direction="column" customClass="leftSide">
                          <video
                            autoPlay
                            loop
                            muted
                            style={{ width: "100%", height: "auto" }}
                          >
                            <source src={Video2} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        </Flex>

                        <Flex direction="column" customClass="rightSide">
                          <Paragraph size="medium">
                            To find your Figma File ID, click the 'Share' button
                            in Figma and select 'Copy Link'. When you paste this
                            link into a browser or document, the File ID is
                            located between '/file/' and
                            '/[your-figma-file-name]/'.
                          </Paragraph>
                        </Flex>
                      </Flex>
                    </Popover.Content>
                  </Popover.Portal>
                </Popover.Root>
                <Input
                  label="Figma File Id"
                  placeholder="Enter Figma File Id"
                  errormsg={false}
                  type="text"
                  value={mainFigmaFileId}
                  onChange={(e) => setMainFigmaFileId(e.target.value)}
                  className={blurSensitiveInfo ? "inputBlurEffect" : ""}
                />
              </Flex>
              <Input
                label="Figma User Id"
                placeholder="Enter Figma User Id"
                errormsg={false}
                type="text"
                value={mainFigmaUserId}
                onChange={(e) => setMainFigmaUserId(e.target.value)}
                className={blurSensitiveInfo ? "inputBlurEffect" : ""}
              />
              {errorMessage && <p className="Error">{errorMessage}</p>}
            </Flex>

            <Flex
              direction="row"
              align="end"
              justify={"end"}
              gap={200}
              style={{
                marginTop: "var(--size-400)",
                paddingTop: "var(--size-150)",
                borderTop: "1px solid var(--border-neutral-subtle)",
              }}
            >
              <Dialog.Close asChild>
                <Button
                  size="medium"
                  variant="solid"
                  onClick={(e) => handleUpdateProfile(e)}
                >
                  Add integration
                </Button>
              </Dialog.Close>
            </Flex>
            <Flex customClass="closeButton" gap={100}>
              <Tooltip delayDuration={200}>
                <Tooltip.Trigger asChild>
                  <IconButton
                    aria-label={
                      blurSensitiveInfo
                        ? "Show sensitive information"
                        : "Hide sensitive information"
                    }
                    size="small"
                    variant="ghost"
                    onClick={toggleBlur}
                  >
                    {blurSensitiveInfo ? <EyeClosedIcon /> : <EyeOpenIcon />}
                  </IconButton>
                </Tooltip.Trigger>
                <Tooltip.Content sideOffset={2}>
                  <Paragraph size="medium">Hide sensitive data</Paragraph>
                </Tooltip.Content>
              </Tooltip>

              <Dialog.Close asChild>
                <IconButton aria-label="Close" size="small" variant="ghost">
                  <Cross2Icon />
                </IconButton>
              </Dialog.Close>
            </Flex>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

export default DialogFigmaWidget;
