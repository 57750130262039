import React, { useState } from "react";
import "./../radix-styles/Dialog.scss";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import {
  Button,
  Flex,
  Paragraph,
  Heading,
  Input,
  IconButton,
  Separator,
  Switch,
} from "blocksin-system";

const DialogSaveTemplate = ({ onSave, open, onOpenChange, loggedInUser }) => {
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState(loggedInUser);
  const [organisation, setOrganisation] = useState(loggedInUser);
  const [image, setImage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isPublic, setIsPublic] = React.useState(false);
  const [imageName, setImageName] = useState(""); // New state for storing image name
  const generateUniqueId = () => {
    return `template_${Date.now()}`;
  };
  const handleSave = () => {
    if (!title.trim()) {
      setErrorMessage("Title cannot be empty.");
      return; // Stop the function if the title is empty
    }

    // Get the current date and time
    const currentDate = new Date();

    onSave({
      title,
      author,
      isPublic,
      image,
      organisation,
      id: generateUniqueId(),
      date: currentDate,
    }); // Include unique ID for each card
    setIsPublic("");
    setAuthor("");
    setOrganisation("");
    setTitle("");
    setImage("");
    setErrorMessage(""); // Clear any error message
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 202400) {
      setErrorMessage("File size should not exceed 200 KB.");
      return;
    }

    setImageName(file.name); // Update the image name

    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <Dialog.Root
        className="DialogRoot"
        open={open}
        onOpenChange={onOpenChange}
      >
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent">
            <Heading level={3} weight="bold">
              Save template
            </Heading>

            <Flex customClass="DialogDescription" direction={"column"}>
              <Paragraph size="large">
                Here you can create and publish templates to community!
              </Paragraph>
            </Flex>
            <Flex direction="column" gap={200}>
              <Input
                label="Template title"
                placeholder="Enter template title"
                errormsg={false}
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)} // Fix the onChange handler
              />
              <input
                type="file"
                onChange={handleImageChange}
                style={{ display: "none" }}
                id="image-upload"
              />
              <Button
                size="medium"
                variant="outline"
                fluid
                onClick={() => document.getElementById("image-upload").click()}
              >
                {imageName || "Upload Image"}
              </Button>
            </Flex>

            {errorMessage && <p className="Error">{errorMessage}</p>}

            <Flex
              direction="column"
              style={{
                marginTop: "var(--size-200)",
              }}
            >
              <Separator />
              <Paragraph size="large">
                Do you want to make this page public?
              </Paragraph>
              <Flex
                customClass={"publishSettings"}
                style={{
                  marginTop: "var(--size-100)",
                }}
              >
                <Switch
                  id="public-page"
                  checked={isPublic}
                  onCheckedChange={(checked) => setIsPublic(checked)}
                >
                  Public
                </Switch>
              </Flex>
            </Flex>

            <Flex
              direction="column"
              align="end"
              style={{
                marginTop: "var(--size-400)",
                paddingTop: "var(--size-150)",
                borderTop: "1px solid var(--border-neutral-subtle)",
              }}
            >
              <Button size="medium" variant="solid" onClick={handleSave}>
                Save
              </Button>
            </Flex>
            <Flex customClass="closeButton">
              <Dialog.Close asChild>
                <IconButton aria-label="Close" size="small" variant="ghost">
                  <Cross2Icon />
                </IconButton>
              </Dialog.Close>
            </Flex>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

export default DialogSaveTemplate;
