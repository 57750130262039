import React, { useState, useEffect, useCallback } from "react";
import { parse } from "papaparse";
import { CopyIcon } from "@radix-ui/react-icons";
import {
  Button,
  Flex,
  Paragraph,
  Toast,
  IconButton,
} from "blocksin-system";

const ColorTable = ({ currentColorsURL }) => {
  const [colorTableData, setColorTableData] = useState([]);

  // Convert Google Sheets URL to CSV URL
  const convertToCsvUrl = (url) => {
    if (!url) return null;
    const matches = url.match(/\/d\/(.+)\/edit/);
    return matches && matches[1]
      ? `https://docs.google.com/spreadsheets/d/${matches[1]}/gviz/tq?tqx=out:csv`
      : null;
  };

  // Function to download color table data as JSON
  const convertTableDataWithColorsToJson = () => {
    const jsonData = JSON.stringify(colorTableData, null, 2);
    const blob = new Blob([jsonData], { type: "application/json" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "table_data_with_colors.json";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  // Function to fetch and render color table data
  const fetchAndRenderColorTable = useCallback((url, forceRefresh = false) => {
    const cacheKey = `colorTableData-${url}`;
    const cachedData = localStorage.getItem(cacheKey);

    if (cachedData && !forceRefresh) {
      return Promise.resolve(JSON.parse(cachedData));
    } else {
      return fetch(url)
        .then((response) =>
          response.ok
            ? response.text()
            : Promise.reject("Failed to fetch color table data")
        )
        .then(
          (text) =>
            new Promise((resolve) => {
              parse(text, {
                complete: (results) => {
                  const rows = results.data;
                  localStorage.setItem(cacheKey, JSON.stringify(rows));
                  resolve(rows);
                },
                header: false,
              });
            })
        )
        .catch((error) => console.error("Error fetching color table:", error));
    }
  }, []);

  useEffect(() => {
    const csvUrl = convertToCsvUrl(currentColorsURL);
    if (csvUrl) {
      fetchAndRenderColorTable(csvUrl).then((rows) => {
        if (rows) {
          setColorTableData(rows);
        }
      });
    }
  }, [currentColorsURL, fetchAndRenderColorTable]);

  // ColorCell component for rendering color values
  const ColorCell = ({ color }) => {
    const [showToast, setShowToast] = useState(false);

    const copyToClipboard = () => {
      navigator.clipboard.writeText(color);
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    };

    return (
      <td style={{ backgroundColor: color }}>
        <Flex
          direction="row"
          align="center"
          justify="between"
          customClass="customCell"
        >
          <Paragraph size="large">{color}</Paragraph>
          <Flex customClass="copyButton">
            <IconButton size="small" variant="ghost" onClick={copyToClipboard}>
              <CopyIcon />
            </IconButton>
          </Flex>
        </Flex>
        {showToast && (
          <Toast simple text="Color value copied to clipboard!" />
        )}
      </td>
    );
  };

  // Rendering the table
  return (
    <Flex direction="column" gap={200} customClass="Table" align="end">
      <Button
        variant="solid"
        size="medium"
        onClick={convertTableDataWithColorsToJson}
      >
        Download JSON
      </Button>
      <table className="Table colorTable">
        <tbody>
          {colorTableData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => {
                if (rowIndex === 0) {
                  return <th key={cellIndex}>{cell}</th>;
                } else if (typeof cell === "string" && cell.startsWith("#")) {
                  return <ColorCell key={cellIndex} color={cell} />;
                } else {
                  return <td key={cellIndex}>{cell}</td>;
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </Flex>
  );
};

export default ColorTable;
