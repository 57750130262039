import React, { useState, useEffect } from "react";
import Card from "./Card";
import DialogCards from "./dialogs/Dialog";
import Lottie from "lottie-react";
import animationData from "./assets/confetti.json";
import DialogProjectCardUpdate from "./dialogs/DialogProjectCardUpdate";
import axios from "axios";
import { Button, Flex, Paragraph, Toast } from "blocksin-system";

const ProjectsDashboard = ({
  username,
  onViewProject,
  showDialog,
  onProjectsUpdate,
  organisationInfo,
  fetchOrganisation,
  setProjectData,
  projectData,
  selectedOrgProjects,
  setSelectedOrgProjects,
  fetchCombinedData,
  // setProjects,
}) => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  // eslint-disable-next-line no-unused-vars
  const [newProjectTitle, setNewProjectTitle] = useState("");

  //
  // eslint-disable-next-line no-unused-vars
  const [cards, setCards] = useState([]);
  const [showAddCardDialog, setShowAddCardDialog] = useState(false);
  const [showEditCardDialog, setShowEditCardDialog] = useState(false);
  const [selectedCardForEditing, setSelectedCardForEditing] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [allowToast, setAllowToast] = useState(true);
  const [hasCreatedCard, setHasCreatedCard] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);

  //
  //
  //
  //

  // const handleChange = (e) => {
  //   setProjectData({ ...projectData, [e.target.name]: e.target.value });
  // };

  //
  //
  //
  //

  //
  //
  //
  //
  const handleRenameProject = async (projectId, newTitle) => {
    const token = localStorage.getItem("token");
    if (!token || !newTitle) {
      console.error("Token is missing or new project title is empty.");
      return;
    }

    try {
      const response = await axios.patch(
        `${serverUrl}/api/projects/projects/${projectId}`,
        { title: newTitle },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        // Assuming fetchOrganisation updates selectedOrgProjects
        await fetchOrganisation();
        // await fetchCombinedData();
      } else {
        console.error("Failed to rename project:", response.data);
      }
    } catch (error) {
      console.error("Error renaming project:", error);
    }
  };

  const handleDeleteProject = async (projectId) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token is missing.");
      return;
    }

    try {
      await axios.delete(`${serverUrl}/api/projects/projects/${projectId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchOrganisation(); // Refresh data
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };

  //
  //
  //
  //
  //
  useEffect(() => {
    onProjectsUpdate(cards);
  }, [cards, onProjectsUpdate]);

  //   useEffect(() => {
  //     // This will be called when projects data in the parent component changes
  //     setProjects(selectedOrgProjects);
  // }, [selectedOrgProjects, setProjects]);

  useEffect(() => {
    const firstCardCreated = JSON.parse(
      localStorage.getItem(`${username}_firstCardCreated`)
    );
    setHasCreatedCard(!!firstCardCreated);
  }, [username]);

  useEffect(() => {
    setShowAddCardDialog(showDialog);
  }, [showDialog]);

  useEffect(() => {
    const dismissToast = JSON.parse(
      localStorage.getItem(`${username}_dismissToast`)
    );
    if (dismissToast !== null) {
      setAllowToast(!dismissToast);
    }
  }, [username]);

  const handleAddCard = () => {
    setShowAddCardDialog(true);
  };

  const handleSaveCard = async (project) => {
    const token = localStorage.getItem("token");

    // Check if the token is available
    if (!token) {
      console.error("Token is missing.");
      return;
    }

    try {
      const response = await axios.post(
        `${serverUrl}/api/projects/projects`,
        {
          title: project.title,
          projectImage: project.image,
          organisationId: organisationInfo._id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Check if the project was successfully created
      if (response.status === 200) {
        // Fetch the updated organisations data
        await fetchOrganisation();
        // await fetchCombinedData();
        // Optionally reset the project data
        setProjectData({ title: "" });
      } else {
        console.error("Failed to create project:", response.data);
      }
    } catch (error) {
      console.error("Error creating project:", error);
    }

    setShowAddCardDialog(false);
    if (allowToast) {
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    }

    if (!hasCreatedCard) {
      setShowAnimation(true);
      setTimeout(() => setShowAnimation(false), 3000);
      localStorage.setItem(
        `${username}_firstCardCreated`,
        JSON.stringify(true)
      );
      setHasCreatedCard(true);
    }
  };

  const handleEditProjectCard = (card) => {
    setSelectedCardForEditing(card);
    setShowEditCardDialog(true);
  };

  const handleDialogSave = () => {
    setShowEditCardDialog(false);
  };

  const handleDismissForever = () => {
    localStorage.setItem(`${username}_dismissToast`, JSON.stringify(true));
    setAllowToast(false);
    setShowToast(false);
  };

  return (
    <>
      {showAnimation && (
        <Lottie
          className="lottie"
          animationData={animationData}
          loop={false}
          autoplay={true}
          rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
        />
      )}
      {showToast && (
        <Toast
          showToast={showToast}
          setShowToast={setShowToast}
          headline="Success!"
          text="Library has been saved 🚀"
          time={3000}
          showAgain={true}
          onDismissForever={handleDismissForever}
        />
      )}

      <Flex direction={"column"} gap={200} id={"LibraryCardsHeader"}>
        <Paragraph size="large" weight="bold">
          Your projects
        </Paragraph>
        <Button size="medium" variant="solid" onClick={handleAddCard}>
          Add Design Library
        </Button>
      </Flex>

      <Flex id="LibraryCards" direction={"row"}>
        {selectedOrgProjects.map((project) => (
          <Card
            key={project._id}
            title={project.title}
            image={project.projectImage}
            onViewProject={() => onViewProject(project)}
            id={project._id}
            owners={[project.author]}
            onEditProjectCard={() => handleEditProjectCard(project)}
            handleDeleteProjectCard={() => handleDeleteProject(project._id)}
            onRenameProject={(newTitle) =>
              handleRenameProject(project._id, newTitle)
            }
          />
        ))}
      </Flex>

      {showAddCardDialog && (
        <DialogCards
          onSave={handleSaveCard}
          open={showAddCardDialog}
          onOpenChange={setShowAddCardDialog}
          username={username}
        />
      )}

      {showEditCardDialog && selectedCardForEditing && (
        <DialogProjectCardUpdate
          open={showEditCardDialog}
          onOpenChange={setShowEditCardDialog}
          onSave={handleDialogSave}
          cardTitle={selectedCardForEditing.title}
          cardId={selectedCardForEditing._id}
          onRenameProject={handleRenameProject}
          setNewProjectTitle={setNewProjectTitle}
        />
      )}
    </>
  );
};

export default ProjectsDashboard;
