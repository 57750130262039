import React, { useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import "./../radix-styles/Dialog.scss";
import { Cross2Icon } from "@radix-ui/react-icons";
import {
  Button,
  Flex,
  Paragraph,
  Heading,
  IconButton,
  Separator,
  Switch,
} from "blocksin-system";

const DialogNotePublished = ({
  open,
  onOpenChange,
  onPublish,
  pageKey,
  isNotePublished,
}) => {
  const [isPublic, setIsPublic] = React.useState(false);
  const [showAPI, setShowAPI] = React.useState(false);
  // eslint-disable-next-line
  const [isPagePublished, setIsPagePublished] = useState(false);

  useEffect(() => {
    const publishedData = localStorage.getItem(pageKey);
    if (publishedData) {
      const data = JSON.parse(publishedData);
      setIsPagePublished(data.isPublished);
      setIsPublic(data.isPublic);
      setShowAPI(data.showAPI);
    }
  }, [pageKey]);

  const handlePublish = () => {
    onPublish(isPublic, showAPI, true); // true for isPublished
    setIsPagePublished(true);
    onOpenChange(false);
  };

  const handleSaveDraft = () => {
    // Set the local storage data to reflect the draft state
    const draftData = {
      isPublished: false,
      isPublic: false,
      showAPI: false,
    };
    localStorage.setItem(pageKey, JSON.stringify(draftData));

    // Update component state to reflect the draft state
    setIsPagePublished(false);
    setIsPublic(false);
    setShowAPI(false);

    // Call onPublish with the updated state, indicating it's a draft save
    onPublish(false, false, false, true); // The last true indicates it's being saved as a draft

    // Close the dialog
    onOpenChange(false);
  };

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <Heading level={3} weight="bold">
            Publish page
          </Heading>

          <Flex customClass="DialogDescription" direction={"column"}>
            <Paragraph size="large">
              This will publish your note to a custom domain.
              <br />
              Make it happen! 🚀
            </Paragraph>
          </Flex>
          <Separator />
          <Paragraph size="large">
            Do you want to make this page public?
          </Paragraph>
          <Flex
            customClass={"publishSettings"}
            style={{
              marginTop: "var(--size-100)",
              marginBottom: "var(--size-200)",
            }}
          >
            <Switch
              id="public-page"
              checked={isPublic}
              onCheckedChange={(checked) => setIsPublic(checked)}
            >
              Public
            </Switch>
          </Flex>
          <Paragraph size="large">
            Do you want to expose JSON via API?
          </Paragraph>
          <Flex
            customClass={"publishSettings"}
            style={{ marginTop: "var(--size-100)" }}
          >
            <Switch
              id="api-page"
              checked={showAPI}
              onCheckedChange={(checked) => setShowAPI(checked)}
            >
              API
            </Switch>
          </Flex>

          <Flex
            justify={"end"}
            gap={100}
            style={{ marginTop: "var(--size-200)" }}
          >
            {isNotePublished ? (
              <>
                <Button size="large" variant="ghost" onClick={handleSaveDraft}>
                  Save to draft
                </Button>
                <Button size="large" onClick={handlePublish}>
                  Save
                </Button>
              </>
            ) : (
              <Button size="large" onClick={handlePublish}>
                Publish
              </Button>
            )}
          </Flex>

          <Flex customClass="closeButton">
            <Dialog.Close asChild>
              <IconButton
                aria-label="Close"
                size="small"
                variant="ghost"
                onClick={() => onOpenChange(false)}
              >
                <Cross2Icon />
              </IconButton>
            </Dialog.Close>
          </Flex>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default DialogNotePublished;
