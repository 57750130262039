import React from "react";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import "./radix-styles/AlertDialog.scss";
import { Button, Flex, Paragraph, Heading } from "blocksin-system";

const AlertDialogCustom = ({
  title,
  description,
  cta,
  onClick,
  isOpen,
  onOpenChange,
  children,
  disabled,
}) => (
  <AlertDialog.Root
    className="AlertDialogRoot"
    open={isOpen}
    onOpenChange={onOpenChange}
  >
    <AlertDialog.Portal>
      <AlertDialog.Overlay className="AlertDialogOverlay" />
      <AlertDialog.Content className="AlertDialogContent">
        <Heading level={4} weight="bold">
          {title}
        </Heading>
        <Paragraph size="large">{description}</Paragraph>
        {children}
        <Flex
          gap={100}
          justify={"end"}
          style={{ marginTop: "var(--size-200)" }}
        >
          <AlertDialog.Cancel asChild>
            <Button variant="outline">Cancel</Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action asChild>
            <Button disabled={disabled} onClick={onClick} variant="danger">
              {cta}
            </Button>
          </AlertDialog.Action>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Portal>
  </AlertDialog.Root>
);

export default AlertDialogCustom;
