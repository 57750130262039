import React, { useState, useEffect } from "react";
import "./Cookies.scss";
import { Button, Flex, Paragraph, Checkbox } from "blocksin-system";

function Cookies() {
  const [isVisible, setIsVisible] = useState(false);
  const [hotjarAllowed, setHotjarAllowed] = useState(true);

  useEffect(() => {
    const dismissedDate = localStorage.getItem("cookieNoticeDismissed");
    const hotjarSetting = localStorage.getItem("allowHotjar");

    if (hotjarSetting !== null) {
      setHotjarAllowed(hotjarSetting === "true");
    }

    if (!dismissedDate || new Date(dismissedDate) < new Date()) {
      setIsVisible(true);
    }
  }, []);

  const handleDismiss = () => {
    const futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + 14); // 14 days from now
    localStorage.setItem("cookieNoticeDismissed", futureDate);
    localStorage.setItem("allowHotjar", hotjarAllowed);
    setIsVisible(false);

    // Dispatch an event to notify the change in Hotjar consent
    const event = new CustomEvent("hotjarConsentChange", {
      detail: hotjarAllowed,
    });
    window.dispatchEvent(event);
  };

  const toggleHotjar = () => {
    const newHotjarAllowed = !hotjarAllowed;
    setHotjarAllowed(newHotjarAllowed);
    localStorage.setItem("allowHotjar", newHotjarAllowed);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Flex customClass="Cookies" direction={"column"} gap={200}>
      <Paragraph size="medium">
        BlocksIn uses cookies, including third-party cookies like Hotjar. Hotjar
        helps me see what is most interesting to you, allowing me to improve my
        MVP product. By using BlocksIn.com site, you consent to our use of
        cookies 🍪
      </Paragraph>
      <Checkbox customID={"PPTerms"}>
        <Paragraph size="medium">
          Accept <a href="/privacy-policy">terms and conditions</a>
        </Paragraph>
      </Checkbox>
      <Flex gap={100} customClass={hotjarAllowed ? "" : "disabled"}>
        <Button size="medium" variant="solid" onClick={handleDismiss}>
          Got it!
        </Button>
        <Button size="medium" variant="danger" onClick={toggleHotjar}>
          {hotjarAllowed ? "Disable Hotjar" : "Enable Hotjar"}
        </Button>
        <a
          className="button button-medium button-outline"
          href="https://help.hotjar.com/hc/en-us/articles/360045420794"
          target="_blank"
          rel="noreferrer"
        >
          About Hotjar
        </a>
      </Flex>
    </Flex>
  );
}

export default Cookies;
