import React, { useEffect, useState } from "react";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import {
  CaretDownIcon,
  MoonIcon,
  HamburgerMenuIcon,
} from "@radix-ui/react-icons";
import ListItem from "./ListItem";
import AppLogo from "./../assets/AppLogo2.svg";
import AppLogoWhite from "./../assets/AppLogo2-White.svg";
import {
  Flex,
  Badge,
  IconButton,
  Paragraph,
  Separator,
  Switch,
  ScrollArea,
} from "blocksin-system";

const Nav = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("theme") === "Dark mode"
  );
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // State to control mobile menu visibility

  // Function to toggle the mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prevState) => !prevState);
  };

  useEffect(() => {
    // Check local storage for saved theme
    document.documentElement.className = isDarkMode ? "darkmode" : "";

    const handleScroll = () => {
      const offset = window.scrollY;
      setIsScrolled(offset > 64);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isDarkMode]);

  const logoSrc = isDarkMode ? AppLogoWhite : AppLogo;
  const navClasses = `NavigationMenuRoot${isScrolled ? " scrolled" : ""}`;

  const handleThemeChange = (newCheckedState) => {
    setIsDarkMode(newCheckedState); // Update the isDarkMode state

    const newTheme = newCheckedState ? "Dark mode" : "Light mode";
    localStorage.setItem("theme", newTheme);

    // Set class on the HTML element
    document.documentElement.className = newCheckedState ? "darkmode" : "";
  };

  return (
    <NavigationMenu.Root className={navClasses}>
      <NavigationMenu.List className="NavigationMenuList">
        {isMobileMenuOpen && (
          <Flex customClass="MobileNavigation" direction="column" gap={200}>
            <ScrollArea
              style={{
                maxHeight: "100vh",
              }}
            >
              <NavigationMenu.Item>
                <NavigationMenu.Link
                  className="NavigationMenuLink"
                  href="/about"
                >
                  About
                </NavigationMenu.Link>
              </NavigationMenu.Item>
              <Flex direction="column" gap={100}>
                <Flex customClass="NavLabel" direction="column">
                  <Paragraph size="medium" weight="bold">
                    Features
                  </Paragraph>
                  <Separator />
                </Flex>

                <NavigationMenu.Item>
                  <ul className="List two">
                    <ListItem title="Dynamic Dashboard" href="/dashboard">
                      All team members across design, development, and project
                      management can track projects in real-time.
                    </ListItem>
                    <ListItem
                      title="Project Management"
                      href="/project-management"
                    >
                      Create dynamic cards. Assign roles and integrate tools
                      like Figma and GitHub for seamless collaboration.
                    </ListItem>
                    <ListItem title="Contributions" href="/contributions">
                      Recognize and connect with leading designers and
                      developers in your team, promoting knowledge sharing.
                    </ListItem>
                    <ListItem
                      title="Block Builder"
                      href="/documentation-builder"
                    >
                      Quickly create and share design systems or product
                      documentation using our easy-to-use drag-and-drop builder.
                    </ListItem>
                    <ListItem title="Templates" href="/templates">
                      Speed up documentation with customizable templates.
                      Create, share, or use community templates for efficient
                      documentation setup.
                    </ListItem>
                    <ListItem title="APIs" href="/apis">
                      Build custom React applications for documentation using
                      our APIs. Easily modify content with our intuitive block
                      builder.
                    </ListItem>
                  </ul>
                </NavigationMenu.Item>
              </Flex>

              <NavigationMenu.Item>
                <NavigationMenu.Link
                  className="NavigationMenuLink"
                  href="/design-system"
                >
                  Demo
                </NavigationMenu.Link>
              </NavigationMenu.Item>

              <NavigationMenu.Item>
                <NavigationMenu.Link
                  className="NavigationMenuLink"
                  href="/pricing"
                >
                  Pricing
                </NavigationMenu.Link>
              </NavigationMenu.Item>

              <NavigationMenu.Item>
                <NavigationMenu.Link
                  className="NavigationMenuLink"
                  href="/community"
                >
                  Comunity
                  <Badge label="new" />
                </NavigationMenu.Link>
              </NavigationMenu.Item>
            </ScrollArea>

            {/* <NavigationMenu.Indicator className="NavigationMenuIndicator">
            <div className="Arrow" />
          </NavigationMenu.Indicator> */}
          </Flex>
        )}
        <Flex align="center" gap={400}>
          <a href="/">
            <img
              className="Logo"
              src={logoSrc}
              alt="Empower Your Design & Development Teams with BlocksInGrids Logo"
            />
          </a>
          <Flex customClass="MainNavigation">
            <NavigationMenu.Item>
              <NavigationMenu.Link className="NavigationMenuLink" href="/about">
                About
              </NavigationMenu.Link>
            </NavigationMenu.Item>

            <NavigationMenu.Item>
              <NavigationMenu.Trigger className="NavigationMenuTrigger">
                Features <CaretDownIcon className="CaretDown" aria-hidden />
              </NavigationMenu.Trigger>
              <NavigationMenu.Content className="NavigationMenuContent">
                <ul className="List two">
                  <ListItem title="Dynamic Dashboard" href="/dashboard">
                    All team members across design, development, and project
                    management can track projects in real-time.
                  </ListItem>
                  <ListItem
                    title="Project Management"
                    href="/project-management"
                  >
                    Create dynamic cards. Assign roles and integrate tools like
                    Figma and GitHub for seamless collaboration.
                  </ListItem>
                  <ListItem title="Contributions" href="/contributions">
                    Recognize and connect with leading designers and developers
                    in your team, promoting knowledge sharing.
                  </ListItem>
                  <ListItem title="Block Builder" href="/documentation-builder">
                    Quickly create and share design systems or product
                    documentation using our easy-to-use drag-and-drop builder.
                  </ListItem>
                  <ListItem title="Templates" href="/templates">
                    Speed up documentation with customizable templates. Create,
                    share, or use community templates for efficient
                    documentation setup.
                  </ListItem>
                  <ListItem title="APIs" href="/apis">
                    Build custom React applications for documentation using our
                    APIs. Easily modify content with our intuitive block
                    builder.
                  </ListItem>
                </ul>
              </NavigationMenu.Content>
            </NavigationMenu.Item>

            <NavigationMenu.Item>
              <NavigationMenu.Link
                className="NavigationMenuLink"
                href="/design-system"
              >
                Demo
              </NavigationMenu.Link>
            </NavigationMenu.Item>

            <NavigationMenu.Item>
              <NavigationMenu.Link
                className="NavigationMenuLink"
                href="/pricing"
              >
                Pricing
              </NavigationMenu.Link>
            </NavigationMenu.Item>

            <NavigationMenu.Item>
              <NavigationMenu.Link
                className="NavigationMenuLink"
                href="/community"
              >
                Comunity
                <Badge label="new" />
              </NavigationMenu.Link>
            </NavigationMenu.Item>

            <NavigationMenu.Indicator className="NavigationMenuIndicator">
              <div className="Arrow" />
            </NavigationMenu.Indicator>
          </Flex>
        </Flex>
        <Flex align="center" gap={100} customClass="CTA">
          {/* <a className="button button-outline button-small" href="/login">
            Sign In
          </a> */}
          <Switch
            checked={isDarkMode}
            onCheckedChange={handleThemeChange}
            id="dark-mode"
          >
            <MoonIcon />
          </Switch>
          <a
            className="button button-solid button-small"
            href="https://www.linkedin.com/in/sebastiangrochocki/"
          >
            Request a demo
          </a>
        </Flex>
        <Flex customClass="MobileMenuToggle">
          <IconButton size="small" variant="ghost" onClick={toggleMobileMenu}>
            <HamburgerMenuIcon />
          </IconButton>
        </Flex>
      </NavigationMenu.List>

      <div className="ViewportPosition">
        <NavigationMenu.Viewport className="NavigationMenuViewport" />
      </div>
    </NavigationMenu.Root>
  );
};
export default Nav;
