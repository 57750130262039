import React from "react";
import "./../radix-styles/Dialog.scss";
import * as Dialog from "@radix-ui/react-dialog";
// import Input from "../components/Input";
import { Button, Flex, TextArea } from "blocksin-system";

const DialogAnswer = ({
  open,
  onOpenChange,
  replyText,
  handleReplyChange,
  submitReply,
}) => {
  return (
    <>
      <Dialog.Root
        className="DialogRoot"
        open={open}
        onOpenChange={onOpenChange}
        modal
      >
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent CommentReply">
            <Flex direction="column" gap={200}>
              <TextArea
                label={false}
                placeholder="Enter your reply"
                type="text"
                value={replyText}
                onChange={handleReplyChange}
              />
            </Flex>

            <Flex
              direction="column"
              align="end"
              style={{
                paddingTop: "var(--size-200)",
                borderTop: "1px solid var(--border-neutral-subtle)",
                paddingLeft: "var(--size-300)",
                paddingRight: "var(--size-300)",
                paddingBottom: "var(--size-200)",
              }}
            >
              <Button size="medium" variant="solid" onClick={submitReply}>
                Submit Reply
              </Button>
            </Flex>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

export default DialogAnswer;
