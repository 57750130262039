import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Menubar from "@radix-ui/react-menubar";
import {
  DotFilledIcon,
  HamburgerMenuIcon,
  ResetIcon,
  BellIcon,
} from "@radix-ui/react-icons";
import "./radix-styles/Menubar.scss";
import AppLogo from "./assets/AppLogo2.svg";
import AppLogoWhite from "./assets/AppLogo2-White.svg";
import AvatarPlaceholder from "./assets/AvatarDemo2.svg";
// import usernotifications from "./json/userNotifications.json";
import NotificationItem from "./components/NotificationItem";
import DialogFeedback from "./dialogs/DialogFeedback";
import {
  Separator,
  IconButton,
  Flex,
  Button,
  DropdownMenu,
  ScrollArea,
} from "blocksin-system";
const RADIO_ITEMS = ["Dark mode", "Light mode"];

const MenubarDemo = ({
  onLogout,
  onShowDashboard,
  onShowSettings,
  onToggleSidebar,
  showSettings,
  onShowScoreBoard,
  showScoreBoard,
  username,
  currentUserAvatar,
  currentUserId,
  organisationNotifications,
  setOrganisationNotifications,
}) => {
  const [theme, setTheme] = useState("Dark mode"); // Default to Light mode
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  // const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate("/community");
  };
  // useEffect(() => {
  //   // Load notifications from imported JSON
  //   setNotifications(usernotifications.notifications);
  // }, []);
  //
  //
  //
  //

  const displayNotifications = () => {
    if (
      !organisationNotifications ||
      organisationNotifications.length === 0 ||
      !currentUserId
    ) {
      return null;
    }

    // Filter notifications
    // const filteredNotifications = organisationNotifications;
    // console.log("Filtered Notifications:", filteredNotifications);

    return organisationNotifications
      .filter((notif) => currentUserId && notif.author._id !== currentUserId)
      .slice() // Create a shallow copy to avoid mutating the original array
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      .map((notif, index) => (
        <NotificationItem
          key={notif._id}
          notification={notif}
          onDismiss={handleDismissNotification}
          isDismissedByCurrentUser={notif.dismissedByUser.includes(
            currentUserId
          )}
        />
      ));
  };

  //
  //
  //
  //
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);

  const handleOpenFeedbackDialog = () => {
    setIsFeedbackDialogOpen(true);
  };

  const handleDismissNotification = async (notificationId) => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        `${serverUrl}/api/projects/dismiss-notification/${notificationId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`, // Replace YOUR_AUTH_TOKEN with the user's authentication token
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        // Notification dismissed successfully, update state
        setOrganisationNotifications((prevNotifications) =>
          prevNotifications.map((notification) => {
            if (notification._id === notificationId) {
              // Add the current user's ID to the dismissedByUser array
              return {
                ...notification,
                dismissedByUser: [
                  ...(notification.dismissedByUser || []), // Ensure it's an array
                  currentUserId, // Assuming you have access to currentUserId
                ],
              };
            }
            return notification;
          })
        );
      } else {
        const responseData = await response.json();
        console.error("Error dismissing notification:", responseData.message);
      }
    } catch (error) {
      console.error("Error dismissing notification:", error);
    }
  };

  const handleClearAllNotifications = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        `${serverUrl}/api/users/dismiss-all-notifications`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: currentUserId }),
        }
      );

      if (response.status === 200) {
        // Update the notifications state to mark all notifications as dismissed
        setOrganisationNotifications((prevNotifications) =>
          prevNotifications.map((notification) => ({
            ...notification,
            dismissedByUser: [
              ...(notification.dismissedByUser || []),
              currentUserId,
            ],
          }))
        );
      } else {
        const responseData = await response.json();
        console.error(
          "Error clearing all notifications:",
          responseData.message
        );
      }
    } catch (error) {
      console.error("Error clearing all notifications:", error);
    }
  };

  const unreadNotificationsCount = organisationNotifications.filter(
    (n) =>
      !n.dismissedByUser.includes(currentUserId) &&
      n.author._id !== currentUserId
  ).length;

  useEffect(() => {
    // Check local storage for saved theme
    const savedTheme = localStorage.getItem("theme") || "Dark mode";
    setTheme(savedTheme);

    // Apply class to HTML element
    document.documentElement.className =
      savedTheme === "Dark mode" ? "darkmode" : "";
  }, []);

  const handleThemeChange = (newTheme) => {
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
    document.documentElement.className =
      newTheme === "Dark mode" ? "darkmode" : "";

    // Dispatch a custom event
    window.dispatchEvent(new CustomEvent("themeChange", { detail: newTheme }));
  };

  const logoSrc = theme === "Dark mode" ? AppLogoWhite : AppLogo;

  const [isSidebarToggled, setIsSidebarToggled] = useState(
    localStorage.getItem("sidebarVisible") === "true"
  );

  const handleSidebarToggle = () => {
    onToggleSidebar();
    setIsSidebarToggled(!isSidebarToggled);
  };

  useEffect(() => {
    localStorage.setItem("sidebarVisible", isSidebarToggled);
  }, [isSidebarToggled]);

  return (
    <Menubar.Root className="MenubarRoot">
      {!showSettings && !showScoreBoard && (
        <IconButton size="small" variant="ghost" onClick={handleSidebarToggle}>
          {isSidebarToggled ? <ResetIcon /> : <HamburgerMenuIcon />}
        </IconButton>
      )}
      <Flex customClass="NavLogo" style={{ marginLeft: "var(--size-200)" }}>
        <img src={logoSrc} alt="Design System App Logo" />
      </Flex>

      <Flex gap={100} customClass={"NavActions"}>
        {/* <Button
          size="small"
          variant="ghost"
          disabled
          onClick={onShowScoreBoard}
        >
          Score board
        </Button> */}
        <Button size="small" variant="ghost" onClick={onShowDashboard}>
          Dashboard
        </Button>
        <Button size="small" variant="ghost" onClick={onShowSettings}>
          Settings
        </Button>
        <Button
          size="small"
          variant="ghost"
          onClick={handleRedirect}
          showBadge
          badgeLabel="new"
        >
          Community
        </Button>
      </Flex>

      <Flex gap={200} customClass={"NavActionsUser"}>
        <Flex customClass="MainNav">
          <Menubar.Menu>
            <Menubar.Trigger className="MenubarTrigger">
              <Flex align={"center"} gap={100}>
                {currentUserAvatar ? (
                  <img src={currentUserAvatar} alt={username} />
                ) : (
                  <img src={AvatarPlaceholder} alt={username} />
                )}
                {username}
              </Flex>
            </Menubar.Trigger>
            <Menubar.Portal>
              <Menubar.Content
                className="MenubarContent"
                align="start"
                sideOffset={4}
                alignOffset={0}
              >
                <Menubar.RadioGroup
                  value={theme}
                  onValueChange={handleThemeChange}
                >
                  {RADIO_ITEMS.map((item) => (
                    <Menubar.RadioItem
                      className="MenubarRadioItem inset"
                      key={item}
                      value={item}
                    >
                      <Menubar.ItemIndicator className="MenubarItemIndicator">
                        <DotFilledIcon />
                      </Menubar.ItemIndicator>
                      {item}
                    </Menubar.RadioItem>
                  ))}
                </Menubar.RadioGroup>
                <Menubar.Separator className="MenubarSeparator" />

                <Menubar.Item className="MenubarItem" onClick={onShowSettings}>
                  Settings
                </Menubar.Item>
                <Menubar.Item
                  className="MenubarItem"
                  onClick={handleOpenFeedbackDialog}
                >
                  Feedback
                </Menubar.Item>
                <Menubar.Item className="MenubarItem" onClick={onLogout}>
                  Logout
                </Menubar.Item>
              </Menubar.Content>
            </Menubar.Portal>
          </Menubar.Menu>

          <DropdownMenu modal={false}>
            <DropdownMenu.Trigger asChild>
              <IconButton
                size="small"
                variant="ghost"
                showBadge={unreadNotificationsCount > 0}
                badgeLabel={unreadNotificationsCount.toString()}
                // aria-pressed={unreadNotificationsCount > 0 ? "true" : "false"}
                aria-label={`You have ${unreadNotificationsCount} unread notifications.`}
              >
                <BellIcon />
              </IconButton>
            </DropdownMenu.Trigger>

            <DropdownMenu.Content
              className="DropdownMenuContent userNotifications"
              sideOffset={4}
              align="end"
            >
              <ScrollArea>{displayNotifications()}</ScrollArea>
              <Separator />
              <Flex style={{ marginTop: "var(--size-150)" }}>
                <Button
                  size="small"
                  variant="outline"
                  onClick={handleClearAllNotifications}
                >
                  Clear all
                </Button>
              </Flex>
            </DropdownMenu.Content>
          </DropdownMenu>
        </Flex>
        <Button size="small" variant="outline" onClick={onLogout}>
          Logout
        </Button>
      </Flex>
      <DialogFeedback
        open={isFeedbackDialogOpen}
        onOpenChange={setIsFeedbackDialogOpen}
      />
    </Menubar.Root>
  );
};

export default MenubarDemo;
