import React, { useState } from "react";
import "./../radix-styles/Dialog.scss";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import {
  Button,
  Flex,
  Paragraph,
  Heading,
  Input,
  IconButton,
  Tag,
  Tabs,
} from "blocksin-system";

const DialogComponentCardUpdateColors = ({
  open,
  onOpenChange,
  cardId,
  currentColorsURL,
  currentFigmaFileId,
  onUpdateColors,
}) => {
  const [colorsURL, setColorsURL] = useState(currentColorsURL);
  const [figmaFileId, setFigmaFileId] = useState(currentFigmaFileId);

  const handleSaveColorsURL = () => {
    if (!colorsURL && !figmaFileId) {
      console.error("No URL or Figma File ID provided");
      return;
    }

    let csvUrl = null;
    if (colorsURL) {
      csvUrl = convertToCsvUrl(colorsURL);
      if (!csvUrl) {
        console.error("Invalid Google Sheets URL");
        return;
      }
    }
    // Save the CSV URL (if available) or the Figma File ID
    onUpdateColors(cardId, csvUrl, figmaFileId);
    onOpenChange(false); // Close the dialog
  };

  const convertToCsvUrl = (url) => {
    const matches = url.match(/\/d\/(.+)\/edit/);
    if (matches && matches[1]) {
      return `https://docs.google.com/spreadsheets/d/${matches[1]}/gviz/tq?tqx=out:csv`;
    }
    return null;
  };

  return (
    <>
      <Dialog.Root
        className="DialogRoot"
        open={open}
        onOpenChange={onOpenChange}
      >
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent">
            <Heading level={3} weight="bold">
              Add Colors Library
            </Heading>
            <Flex customClass="DialogDescription" direction={"column"}>
              <Paragraph size="large">
                Create Color Table from imported data.
              </Paragraph>
            </Flex>

            <Tabs defaultValue="tab1">
              <Tabs.List aria-label="Manage your account">
                <Tabs.Trigger value="tab1">From Google Sheet</Tabs.Trigger>
                <Tabs.Trigger value="tab2">From Figma</Tabs.Trigger>
              </Tabs.List>
              <Tabs.Content value="tab1">
                <Flex
                  direction="column"
                  gap={200}
                  style={{ marginTop: "var(--size-300)" }}
                >
                  <Input
                    label="URL"
                    placeholder="Enter URL"
                    type="url"
                    value={colorsURL}
                    onChange={(e) => setColorsURL(e.target.value)}
                  />
                </Flex>
              </Tabs.Content>
              <Tabs.Content value="tab2">
                <Flex
                  direction="column"
                  gap={200}
                  style={{ marginTop: "var(--size-300)" }}
                >
                  <Flex align="center" gap={100}>
                    <Paragraph>Figma file</Paragraph>
                    <Tag variant="Demo" text="coming soon" />
                  </Flex>

                  <Input
                    label="Figma File Id"
                    placeholder="Enter Figma File Id"
                    type="text"
                    value={figmaFileId}
                    onChange={(e) => setFigmaFileId(e.target.value)}
                    disabled
                  />
                </Flex>
              </Tabs.Content>
            </Tabs>

            <Flex
              direction="row"
              align="end"
              justify={"end"}
              gap={200}
              style={{
                marginTop: "var(--size-400)",
                paddingTop: "var(--size-150)",
                borderTop: "1px solid var(--border-neutral-subtle)",
              }}
            >
              <Button
                size="medium"
                variant="solid"
                onClick={handleSaveColorsURL}
              >
                Update Component
              </Button>
            </Flex>
            <Flex customClass="closeButton">
              <Dialog.Close asChild>
                <IconButton aria-label="Close" size="small" variant="ghost">
                  <Cross2Icon />
                </IconButton>
              </Dialog.Close>
            </Flex>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

export default DialogComponentCardUpdateColors;
