import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "./PageHomePreview.scss";
import MenubarPage from "../menubarPage";
// import * as ScrollArea from "@radix-ui/react-scroll-area";
import { FileTextIcon, LockClosedIcon } from "@radix-ui/react-icons";
import "../Table.scss";
import axios from "axios";
import { Flex, Heading, Loader } from "blocksin-system";

// import NotesContext from "../contexts/NotesContext";

const PageHomePreview = ({ loggedInUser }) => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [organizationId, setOrganizationId] = useState("");
  const [primaryColor, setPrimaryColor] = useState("");
  const [logo, setLogo] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  //
  //
  //
  //
  //
  useEffect(() => {
    // Set default color to red if primaryColor is empty or undefined
    const color = primaryColor || "#07feb4";

    document.documentElement.style.setProperty("--brand-2", color);
    document.documentElement.style.setProperty("--brand-2-200", color);
    document.documentElement.style.setProperty("--brand-2-300", color);
  }, [primaryColor]);

  //
  //
  //
  //
  //

  const navigate = useNavigate(); // Hook to enable navigation
  const location = useLocation(); // Get the current location

  const { id } = useParams();

  useEffect(() => {
    const fetchOrganisationIdByName = async () => {
      const urlParts = location.pathname.split("/");
      const orgNameFromUrl = urlParts[1]; // Extract the name from the URL
      // console.log(orgNameFromUrl);
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${serverUrl}/api/organisations/name/${orgNameFromUrl}`
        );
        setOrganizationId(response.data._id);
      } catch (error) {
        console.error("Error fetching organisation ID by name:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrganisationIdByName();
  }, [serverUrl, location.pathname]);

  const [publishedPages, setPublishedPages] = useState([]);

  //
  //
  //
  //
  // eslint-disable-next-line
  const [content, setContent] = useState([]); // Define content state
  //
  useEffect(() => {
    const fetchContent = async () => {
      if (!id) {
        console.error("Note ID is empty.");
        return;
      }

      const token = localStorage.getItem("token");
      let endpoint = `${serverUrl}/api/notes/`;
      let headers = {};

      if (token) {
        endpoint += `notes/${id}`;
        headers = { Authorization: `Bearer ${token}` };
      } else {
        endpoint += `public-notes/${id}`;
      }

      try {
        const response = await axios.get(endpoint, { headers });
        if (response.data) {
          // Process the response data
          const grapesContent =
            response.data.content.length > 0
              ? JSON.parse(response.data.content[0])
              : { components: [], styles: [] };
          setContent(grapesContent.components || []);
          // setPrimaryColor(response.data.orientation?.primaryColor);
          // console.log(response.data);
        } else {
          console.error("No content found.");
          setContent([]);
        }
      } catch (error) {
        console.error("Error fetching content:", error);
        setContent([]);
      }
    };

    fetchContent();
  }, [id, serverUrl]);

  //
  //
  //
  //
  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const response = await axios.get(
          `${serverUrl}/api/notes/notes-by-organization/${organizationId}`
        );
        setPublishedPages(response.data);
        setPrimaryColor(response.data[0].primaryColor);
        setLogo(response.data[0].logo);
      } catch (error) {
        console.error("Error fetching notes:", error);
      }
    };

    fetchNotes();
  }, [organizationId, serverUrl, primaryColor]);
  //
  //
  //

  const handleNavigation = (path, isPublic) => {
    if (!loggedInUser && !isPublic) {
      // Redirect to login if the user is not logged in and the page is not public
      navigate("/login");
    } else {
      // Normal navigation
      navigate(path);
    }
  };

  return (
    <Flex direction={"column"} customClass={"PageHomePreview"} align={"center"}>
      <Flex direction={"column"} customClass={"PageHomePreviewHeader"}>
        <MenubarPage logo={logo} />
      </Flex>
      <Flex gap={400} customClass={"PageHomePreviewBodyContainer"}>
        <Flex direction={"column"} customClass={"PageHomePreviewBody"}>
          {isLoading ? (
            <Loader />
          ) : publishedPages.length > 0 ? (
            publishedPages.map((note, index) => (
              // ${note.projectTitle}
              <div
                className="EntryCard"
                key={index}
                onClick={() =>
                  handleNavigation(
                    `/${note.organisationName}/${note.projectFileId}`,
                    note.isPublic
                  )
                }
              >
                <Flex
                  fluid
                  align="between"
                  direction="column"
                  customClass="EntryCardContent"
                >
                  <Flex customClass="Thumbnail">
                    <FileTextIcon />
                  </Flex>
                  <Flex customClass="Title">
                    <Heading level={4} weight="bold">
                      {note.projectFileTitle}
                    </Heading>
                  </Flex>
                </Flex>

                <Flex customClass="LockIcon">
                  {!note.isPublic && <LockClosedIcon />}
                </Flex>
              </div>
            ))
          ) : (
            <p>No pages available.</p>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PageHomePreview;
