import React from "react";
// import { useParams } from "react-router-dom";
import "./PrivacyPolicy.scss";
import Nav from "./Nav";
import { Flex, Heading, Paragraph } from "blocksin-system";

const PrivacyPolicy = ({ loggedInUser }) => {
  return (
    <Flex direction={"column"} customClass={"PrivacyPolicy"} align={"center"}>
      {/* <Flex direction={"column"} customClass={"PrivacyPolicyHeader"}>
        <MenubarPage />
      </Flex> */}
      <Nav />
      <Flex direction={"column"} customClass={"PrivacyPolicyBody"}>
        <Heading level={2} weight="bold">
          Privacy Policy
        </Heading>
        <Paragraph>Last updated: 05.02.2024</Paragraph>
        <Paragraph size="medium">
          At BlocksIn App, accessible from blocksin.com/app, one of our main
          priorities is the privacy of our users. This Privacy Policy document
          contains types of information that is collected and recorded by
          BlocksIn App and how we use it.
        </Paragraph>
        <Heading level={3} weight="bold">
          1. General Information
        </Heading>
        <Paragraph size="medium">
          This privacy policy applies only to our online activities and is valid
          for visitors to our website and users of our app with regards to the
          information that they shared and/or collect in [Your Product Name].
          This policy is not applicable to any information collected offline or
          via channels other than this website and our app.
        </Paragraph>

        <Heading level={3} weight="bold">
          2. Consent
        </Heading>
        <Paragraph size="medium">
          By using our website or app, you hereby consent to our Privacy Policy
          and agree to its terms.
        </Paragraph>
        <Heading level={3} weight="bold">
          3. Information We Collect
        </Heading>
        <Paragraph size="medium">
          The only personal information we ask for is your email address. This
          serves as a unique identifier for logging into our app. We collect
          this information to provide app functionality, including but not
          limited to, account creation and password resets.
        </Paragraph>
        <Heading level={3} weight="bold">
          4. How We Use Your Information
        </Heading>
        <Paragraph size="medium">
          We use the information we collect in various ways, including to:
          Provide, operate, and maintain our website and app Improve,
          personalize, and expand our website and app Understand and analyze how
          you use our website and app Develop new products, services, features,
          and functionality Communicate with you directly to provide you with
          updates and other information relating to the website and app, for
          service and support purposes.
        </Paragraph>
        <Heading level={3} weight="bold">
          5. Log Files
        </Heading>
        <Paragraph size="medium">
          BlocksIn App follows a standard procedure of using log files. These
          files log visitors when they visit websites or apps. All hosting
          companies do this as part of their hosting services' analytics.
        </Paragraph>
        <Heading level={3} weight="bold">
          6. Hotjar
        </Heading>
        <Paragraph size="medium">
          We use Hotjar in order to better understand our users’ needs and to
          optimize this service and experience. Hotjar is a technology service
          that helps us better understand our users' experience (e.g., how much
          time they spend on which pages, which links they choose to click, what
          users do and don’t like, etc.) and this enables us to build and
          maintain our service with user feedback. Hotjar uses cookies and other
          technologies to collect data on our users’ behavior and their devices.
          This includes a device's IP address (processed during your session and
          stored in a de-identified form), device screen size, device type
          (unique device identifiers), browser information, geographic location
          (country only), and the preferred language used to display our
          website. Hotjar stores this information on our behalf in a
          pseudonymized user profile. Hotjar is contractually forbidden to sell
          any of the data collected on our behalf.
        </Paragraph>
        <Heading level={3} weight="bold">
          7. Email Policy
        </Heading>
        <Paragraph size="medium">
          We do not use your email address for marketing purposes. Your email
          may be used in the future to reset your password or to provide
          essential app functionality. We reserve the right to send you an email
          if we decide to discontinue our app and notify you of account
          deletion.
        </Paragraph>
        <Heading level={3} weight="bold">
          8. Account Deletion
        </Heading>
        <Paragraph size="medium">
          You have the right to delete your account and associated data at any
          time. Upon deletion, all your data will be permanently removed from
          our servers.
        </Paragraph>
        <Heading level={3} weight="bold">
          9. Data Protection
        </Heading>
        <Paragraph size="medium">
          We are committed to protecting your personal information and have
          implemented industry-standard security measures to ensure its safety.
        </Paragraph>

        <Heading level={3} weight="bold">
          10. Changes to Our Privacy Policy
        </Heading>
        <Paragraph size="medium">
          We reserve the right to make changes to this privacy policy at any
          time. You are encouraged to review this policy periodically for any
          changes.
        </Paragraph>
        <Paragraph size="medium">
          For any questions or concerns regarding this privacy policy, please
          contact us at https://www.linkedin.com/in/sebastiangrochocki/.
        </Paragraph>
      </Flex>
    </Flex>
  );
};

export default PrivacyPolicy;
