import React from "react";
import "./TemplateItem.scss";
import PlaceholderImage from "./../assets/PlaceholderImage.svg";
import { Flex, Paragraph } from "blocksin-system";

const TemplateItem = ({ template, handleTemplateClick }) => {
  const authorUsername =
    template && template.author ? template.author.username : "Summer Break 🌴";

  return (
    <Flex
      customClass="templateItem"
      direction="row"
      onClick={() => handleTemplateClick(template)}
    >
      <Flex customClass={"image"}>
        <img
          src={template.image || PlaceholderImage}
          alt={template.title}
          className="templateThumbnail"
        />
      </Flex>
      <Flex direction="column" customClass="templateInfo">
        <Paragraph size="small" weight="bold">
          {template.title}
        </Paragraph>
        <Paragraph size="small">{authorUsername}</Paragraph>
      </Flex>
    </Flex>
  );
};

export default TemplateItem;
