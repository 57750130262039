import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import "./LeftSideBar.scss";
import * as Accordion from "@radix-ui/react-accordion";
import { ChevronDownIcon, FileTextIcon } from "@radix-ui/react-icons";
import ImagePlaceholder from "./assets/PlaceholderImage.svg";
import {
  IconButton,
  Separator,
  UserListItem,
  Button,
  Heading,
  Input,
  Paragraph,
  Flex,
  ScrollArea,
} from "blocksin-system";

const LeftSideBar = (
  {
    projects,
    onCardSelect,
    onNoteDisplay,
    onShowNotes,
    organisation,
    organisationInfo,
    activeCard,
    setActiveCard,
  },
  ref
) => {
  // const [projects, setProjects] = useState([]);
  const [activeNote, setActiveNote] = useState(null); // New state to track the active note
  const [searchQuery, setSearchQuery] = useState("");

  const [topUsers, setTopUsers] = useState([]);
  // const [projectCards, setProjectCards] = useState({}); // State to store project-specific cards
  //
  //
  //
  //
  //
  useEffect(() => {
    if (organisationInfo && organisationInfo.users) {
      const sortedUsers = [...organisationInfo.users]
        .sort((a, b) => {
          // Assuming points are stored as numbers. If stored as strings, use: parseInt(a.points, 10) - parseInt(b.points, 10)
          return b.badge - a.badge;
        })
        .slice(0, 3); // Get top 3 users

      setTopUsers(sortedUsers);
    }
  }, [organisationInfo]);
  //
  //
  //
  //
  //
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const getAllProjectFiles = () => {
    return projects.flatMap((project) =>
      project.projectFiles.map((file) => ({
        ...file,
        projectTitle: project.title,
      }))
    );
  };

  const allProjectFiles = getAllProjectFiles();

  const filteredProjectFiles = allProjectFiles.filter((card) =>
    card.title.toLowerCase().includes(searchQuery)
  );

  useImperativeHandle(ref, () => ({
    setActiveCard: (cardId) => {
      setActiveCard(cardId);
    },
  }));

  const handleCardButtonClick = (projectFile) => {
    const projectData = projects.find(
      (project) =>
        Array.isArray(project.projectFiles) &&
        project.projectFiles.includes(projectFile)
    );
    if (projectData) {
      setActiveCard(projectFile._id);
      onCardSelect(projectFile, projectData);
    }
  };

  const handleNoteIconClick = (card) => {
    const isActive = activeNote === card._id;
    setActiveNote(isActive ? null : card._id); // Toggle the active note
    setActiveCard(null); // Reset the active card
    if (!isActive) {
      onNoteDisplay(card); // Call onNoteDisplay only if activating the note
      onShowNotes(card._id);
      localStorage.removeItem("gjsProject");
    }
  };

  return (
    <Flex direction="column" customClass={"LeftSideBar"}>
      <Flex direction={"column"} customClass={"LeftSideBarBody"}>
        <Flex
          gap={200}
          align={"center"}
          justify={"between"}
          customClass="paddingLR"
        >
          <Heading level={4} weight="bold">
            {organisationInfo?.name}
          </Heading>
          {organisationInfo?.logo && (
            <img
              className="organisationLogo"
              src={organisationInfo?.logo}
              alt={`${organisation} Logo`}
            />
          )}
        </Flex>

        <Flex
          direction={"column"}
          customClass="paddingLR"
          style={{ marginTop: "var(--size-300)" }}
        >
          <Input
            label="Search"
            placeholder="Enter keyword"
            errormsg={false}
            type="search"
            onChange={handleSearchChange} // Attach the onChange handler
          />
        </Flex>
        <Separator />

        <Flex
          direction={"column"}
          customClass="paddingLR"
          style={{ marginTop: "var(--size-100)" }}
        >
          <Heading level={4} weight="bold">
            Contributions
          </Heading>
          <Paragraph>
            User list with top 3 best design and dev contributors based on the
            design system adoption level.
          </Paragraph>
          <Flex
            direction={"column"}
            gap={100}
            style={{ marginTop: "var(--size-200)" }}
          >
            {topUsers.map((user, index) => (
              <UserListItem
                key={index}
                name={user.username}
                badge={user.badge}
                avatar={user.avatar}
                role={user.role.join(", ")}
              />
            ))}
          </Flex>
        </Flex>

        <Separator />

        <Flex
          direction={"column"}
          customClass="paddingLR"
          style={{ marginTop: "var(--size-100)" }}
        >
          <Heading level={4} weight="bold">
            Projects
          </Heading>

          <ScrollArea>
            {searchQuery ? (
              filteredProjectFiles.length > 0 ? (
                filteredProjectFiles.map((card, index) => (
                  <Button
                    key={index}
                    variant="ghost"
                    size="medium"
                    fluid
                    aria-pressed={activeCard === card._id ? "true" : "false"}
                    onClick={() => {
                      setActiveCard(card._id);
                      onCardSelect(card, { title: card.projectTitle });
                    }}
                  >
                    {card.title}
                  </Button>
                ))
              ) : (
                <Flex>
                  <Heading level={4} weight="bold">
                    Ooops, no results
                  </Heading>
                </Flex>
              )
            ) : (
              <Accordion.Root
                className="AccordionRoot"
                type="multiple"
                // defaultValue={projects[0]?.title}
                // collapsible
              >
                {projects.map((project, index) => (
                  <Accordion.Item
                    className="AccordionItem"
                    key={index}
                    value={project.title}
                  >
                    <Accordion.Trigger asChild className="AccordionTrigger">
                      <Button fluid size="small" variant="solid-neutral">
                        <Flex customClass={"AccordionItemThumbnail"}>
                          <ChevronDownIcon />
                        </Flex>
                        <span>{project.title}</span>
                      </Button>
                    </Accordion.Trigger>
                    <Accordion.Content className="AccordionContent">
                      {project.projectFiles &&
                      project.projectFiles.length > 0 ? (
                        project.projectFiles.map((card, cardIndex) => (
                          <Flex gap={100} key={card._id}>
                            <Button
                              aria-pressed={
                                activeCard === card._id ? "true" : "false"
                              }
                              variant="ghost"
                              size="small"
                              key={cardIndex}
                              fluid
                              onClick={() => handleCardButtonClick(card)}
                            >
                              {!card.projectFileImage ? (
                                <img
                                  src={ImagePlaceholder}
                                  alt="Accordion Content Item Thumbnail"
                                />
                              ) : (
                                <img
                                  src={card.projectFileImage}
                                  alt="Accordion Content Item Thumbnail"
                                />
                              )}

                              <span>{card.title}</span>
                            </Button>
                            <IconButton
                              variant="ghost"
                              size="small"
                              aria-pressed={
                                activeNote === card._id ? "true" : "false"
                              }
                              onClick={() => handleNoteIconClick(card)}
                            >
                              <FileTextIcon />
                            </IconButton>
                          </Flex>
                        ))
                      ) : (
                        <Paragraph>
                          No cards available for this project.
                        </Paragraph>
                      )}
                    </Accordion.Content>
                  </Accordion.Item>
                ))}
              </Accordion.Root>
            )}
          </ScrollArea>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default forwardRef(LeftSideBar);
