import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
// Import js-beautify
import { js as beautify } from "js-beautify";

// Override some styles from the dark theme
const customStyle = {
  ...dark,
  // Customize specific elements, for example:
  'code[class*="language-"]': {
    ...dark['code[class*="language-"]'],
    fontSize: "16px",
    background: "var(--black)",
  },
  'pre[class*="language-"]': {
    ...dark['pre[class*="language-"]'],
    background: "var(--black)",
    border: "none",
    borderRadius: "var(--size-100)",
    padding: "var(--size-300)",
  },
  // Continue overriding other styles as needed
};

const CodeFormatter = ({ codeString, language }) => {
  // Format the code string using js-beautify
  // You can customize the options as needed
  const formattedCode = beautify(codeString, {
    indent_size: 2,
    space_in_empty_paren: true,
  });

  return (
    <SyntaxHighlighter language={language} style={customStyle}>
      {formattedCode}
    </SyntaxHighlighter>
  );
};

export default CodeFormatter;
