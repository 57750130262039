import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "./PagePreview.scss";
import MenubarPage from "../menubarPage";
import {
  FileTextIcon,
  SizeIcon,
  LockClosedIcon,
  CopyIcon,
} from "@radix-ui/react-icons";
import TableComponent from "./TableComponent";
import { parse } from "papaparse";
import "../Table.scss";
import axios from "axios";
import {
  Flex,
  Heading,
  IconButton,
  Input,
  CodeHighlight,
  Button,
  Paragraph,
  Toast,
  Iframe,
  Loader,
  ScrollArea,
} from "blocksin-system";
import CodeFormatter from "../components/CodeFormatter";

// import NotesContext from "../contexts/NotesContext";

const PagePreview = ({ loggedInUser }) => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [organizationId, setOrganizationId] = useState("");
  const [primaryColor, setPrimaryColor] = useState("");
  const [logo, setLogo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showToastCopied, setShowToastCopied] = useState(false);

  //
  //
  //
  //
  //
  useEffect(() => {
    // Set default color to red if primaryColor is empty or undefined
    const color = primaryColor || "#07feb4";

    document.documentElement.style.setProperty("--brand-2", color);
    document.documentElement.style.setProperty("--brand-2-200", color);
    document.documentElement.style.setProperty("--brand-2-300", color);
  }, [primaryColor]);

  //
  //
  //
  //
  //

  const navigate = useNavigate(); // Hook to enable navigation
  const location = useLocation(); // Get the current location

  const { id } = useParams();

  useEffect(() => {
    const fetchOrganisationIdByName = async () => {
      const urlParts = location.pathname.split("/");
      const orgNameFromUrl = urlParts[1]; // Extract the name from the URL
      // console.log(orgNameFromUrl);
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${serverUrl}/api/organisations/name/${orgNameFromUrl}`
        );
        setOrganizationId(response.data._id);
      } catch (error) {
        console.error("Error fetching organisation ID by name:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrganisationIdByName();
  }, [serverUrl, location.pathname]);

  const [publishedPages, setPublishedPages] = useState([]);

  const [searchQuery, setSearchQuery] = useState(""); // Declare search query state

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value); // Update search query based on input
  };
  // Filter menu items based on search query
  const filteredMenuItems = publishedPages.filter((note) => {
    const query = searchQuery.toLowerCase();
    const projectFileTitle = note.projectFileTitle?.toLowerCase() || "";
    const organisationName = note.organisationName?.toLowerCase() || "";
    const authorName = note.authorName?.toLowerCase() || "";

    return (
      projectFileTitle.includes(query) ||
      organisationName.includes(query) ||
      authorName.includes(query)
    );
  });

  const [expandedCards, setExpandedCards] = useState({}); // Store expanded state of cards

  const toggleCardExpansion = (index) => {
    setExpandedCards((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  //
  //
  //
  //
  const [content, setContent] = useState([]); // Define content state
  //
  useEffect(() => {
    const fetchContent = async () => {
      if (!id) {
        console.error("Note ID is empty.");
        return;
      }

      const token = localStorage.getItem("token");
      let endpoint = `${serverUrl}/api/notes/`;
      let headers = {};

      if (token) {
        endpoint += `notes/${id}`;
        headers = { Authorization: `Bearer ${token}` };
      } else {
        endpoint += `public-notes/${id}`;
      }

      try {
        const response = await axios.get(endpoint, { headers });
        if (response.data) {
          // Process the response data
          const grapesContent =
            response.data.content.length > 0
              ? JSON.parse(response.data.content[0])
              : { components: [], styles: [] };
          setContent(grapesContent.components || []);
          // setPrimaryColor(response.data.orientation?.primaryColor);
          // console.log(response.data);
        } else {
          console.error("No content found.");
          setContent([]);
        }
      } catch (error) {
        console.error("Error fetching content:", error);
        setContent([]);
      }
    };

    fetchContent();
  }, [id, serverUrl]);

  //
  //
  //
  //
  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const response = await axios.get(
          `${serverUrl}/api/notes/notes-by-organization/${organizationId}`
        );
        setPublishedPages(response.data);
        setPrimaryColor(response.data[0].primaryColor);
        setLogo(response.data[0].logo);
        // console.log(primaryColor);
        // Log titles and IDs
        // response.data.forEach((note) => {
        //   console.log(
        //     `/app/${note.organisationName}/${note.projectTitle}/${note.projectFileId}` +
        //       `Author: ${note.authorName} file title ${note.projectFileTitle} parent title ${note.projectTitle}, Organisation: ${note.organisationName}, ID: ${note.noteId}`
        //   );
        // });
      } catch (error) {
        console.error("Error fetching notes:", error);
      }
    };

    fetchNotes();
  }, [organizationId, serverUrl, primaryColor]);
  //
  //
  //
  //
  //
  //
  const [colorTableData, setColorTableData] = useState({});

  const convertTableDataWithColorsToJson = (tableIndex) => {
    // Check if colorTableData for the specified index is available and is an array
    if (
      colorTableData[tableIndex] &&
      Array.isArray(colorTableData[tableIndex])
    ) {
      // Get the table data with colors for the specified index
      const tableData = colorTableData[tableIndex];

      // Convert the data to JSON format
      const jsonData = JSON.stringify(tableData, null, 2);

      // Create a Blob containing the JSON data
      const blob = new Blob([jsonData], { type: "application/json" });

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary <a> element to trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = "table_data_with_colors.json";

      // Programmatically click the <a> element to trigger the download
      a.click();

      // Release the URL object
      window.URL.revokeObjectURL(url);
    }
  };

  const fetchAndRenderColorTable = (url, forceRefresh = false) => {
    const cacheKey = `colorTableData-${url}`;
    const cachedData = localStorage.getItem(cacheKey);

    if (cachedData && !forceRefresh) {
      return Promise.resolve(JSON.parse(cachedData));
    } else {
      return fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch color table data");
          }
          return response.text();
        })
        .then((text) => {
          return new Promise((resolve) => {
            parse(text, {
              complete: (results) => {
                const rows = results.data;
                localStorage.setItem(cacheKey, JSON.stringify(rows));
                resolve(rows);
              },
              header: false,
            });
          });
        })
        .catch((error) => {
          console.error("Error fetching color table:", error);
        });
    }
  };

  useEffect(() => {
    content.forEach((item, index) => {
      if (
        item.type === "table" &&
        item.attributes &&
        item.attributes["data-colors-url"]
      ) {
        // Fetch color table data if the attribute exists
        fetchAndRenderColorTable(item.attributes["data-colors-url"]).then(
          (rows) => {
            if (rows) {
              setColorTableData((prevData) => ({
                ...prevData,
                [index]: rows,
              }));
            }
          }
        );
      }
    });
  }, [content]);

  const refreshColorTableData = useCallback(() => {
    // Your logic to refresh the color table data
    content.forEach((item, index) => {
      if (
        item.type === "table" &&
        item.attributes &&
        item.attributes["data-colors-url"]
      ) {
        fetchAndRenderColorTable(item.attributes["data-colors-url"], true).then(
          (rows) => {
            if (rows) {
              setColorTableData((prevData) => ({
                ...prevData,
                [index]: rows,
              }));
            }
          }
        );
      }
    });
  }, [content]);

  useEffect(() => {
    refreshColorTableData();
  }, [refreshColorTableData]);

  // ColorCell component for rendering color values
  const ColorCell = ({ color }) => {
    const [showToast, setShowToast] = useState(false);

    const copyToClipboard = () => {
      navigator.clipboard.writeText(color);
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    };

    return (
      <td style={{ backgroundColor: color }}>
        <Flex
          direction="row"
          align="center"
          justify="between"
          customClass="customCell"
        >
          <Paragraph size="large">{color}</Paragraph>
          <Flex customClass="copyButton">
            <IconButton size="small" variant="ghost" onClick={copyToClipboard}>
              <CopyIcon />
            </IconButton>
          </Flex>
        </Flex>
        {showToast && <Toast simple text="Color value copied to clipboard!" />}
      </td>
    );
  };

  const renderContent = (content, parentIndex = "") => {
    return content.map((item, index) => {
      if (item.type === "iframe" || item["custom-name"] === "iframe") {
        return (
          <Iframe
            key={index}
            url={item.attributes && item.attributes.src}
            title={item.attributes && item.attributes.title}
          />
        );
      }

      if (item["custom-name"] === "button") {
        return (
          <Button variant="solid" size="large" key={index}>
            {item.components[0].content}
          </Button>
        );
      }

      if (item["custom-name"] === "code-formatter") {
        // Check if attributes exist and have at least one element
        const codeString = item.components[0].content;

        return (
          <CodeFormatter
            key={index}
            codeString={codeString}
            language="javascript"
          />
        );
      }

      if (item["custom-name"] === "section") {
        // Ensure item.components is an array before trying to render it
        if (Array.isArray(item.components)) {
          return (
            <Flex
              customClass="pageContent"
              key={`${parentIndex}-${index}`}
              direction="column"
            >
              {/* Recursively render the components within the section */}
              {renderContent(item.components, `${parentIndex}-${index}`)}
            </Flex>
          );
        }
      }

      // Function to extract the icon-name attribute from SVG content
      const extractIconName = (svgContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(svgContent, "image/svg+xml");
        const svgElement = doc.querySelector("svg");
        return svgElement ? svgElement.getAttribute("icon-name") : "";
      };

      // Then, in your rendering logic, use this function to extract and display the name
      if (item.type === "svg") {
        const iconName = extractIconName(item.content);
        return (
          <div key={index} className="IconBox">
            <div dangerouslySetInnerHTML={{ __html: item.content }} />
            <span>{iconName}</span> {/* Display the extracted icon name */}
            <IconButton
              variant="ghost"
              size="medium"
              onClick={() => {
                navigator.clipboard.writeText(item.content);
                setShowToastCopied(true);
                // Optionally, you can also reset the toast visibility after a delay
                setTimeout(() => setShowToastCopied(false), 2000); // Hide toast after 2 seconds
              }}
            >
              <CopyIcon />
            </IconButton>
          </div>
        );
      }

      // Render table with color cells if 'data-colors-url' attribute is present
      if (item && item.attributes && item.attributes["data-colors-url"]) {
        // Check if colorTableData for this index is available and is an array
        if (colorTableData[index] && Array.isArray(colorTableData[index])) {
          return (
            <Flex direction="column" gap={200} customClass="Table" align="end">
              <Button
                variant="solid"
                size="medium"
                onClick={() => convertTableDataWithColorsToJson(index)}
              >
                Download JSON
              </Button>
              <table className="Table colorTable" key={index}>
                <tbody>
                  {colorTableData[index].map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((cell, cellIndex) => {
                        if (rowIndex === 0) {
                          // Render the first row with <th> elements
                          return <th key={cellIndex}>{cell}</th>;
                        } else if (cell.startsWith("#")) {
                          return <ColorCell key={cellIndex} color={cell} />;
                        } else {
                          return <td key={cellIndex}>{cell}</td>;
                        }
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Flex>
          );
        }
      }
      // Render regular table if 'data-colors-url' attribute is not present
      else if (item.type === "table") {
        return <TableComponent key={index} tableData={item} />;
      }

      if (item.classes && item.classes.includes("code-block")) {
        // console.log("Rendering Code Block:", item.components[0].content); // Debugging
        return <CodeHighlight key={index} text={item.components[0].content} />;
      }

      // Handling link components that should be rendered as buttons
      if (
        item.type === "link" &&
        item.classes &&
        item.classes.includes("button")
      ) {
        // Initialize button label
        let buttonLabel = item.content; // Direct content property

        // If the direct content property is not set, look for the label in the components array
        if (!buttonLabel && item.components && item.components.length > 0) {
          const textComponent = item.components.find(
            (comp) => comp.type === "textnode"
          );
          if (textComponent) {
            buttonLabel = textComponent.content;
          }
        }

        // Handle click event for the button
        const handleClick = () => {
          if (item.attributes.target === "_blank") {
            window.open(item.attributes.href, "_blank");
          } else {
            window.location.href = item.attributes.href;
          }
        };

        // Return the Button component with the correct label
        return (
          <Button
            key={index}
            variant="solid" // Customize as needed
            size="large" // Customize as needed
            onClick={handleClick}
          >
            {buttonLabel}
          </Button>
        );
      }

      if (item.type === "text") {
        const classes = Array.isArray(item.classes) ? item.classes : [];

        if (classes.includes("H1")) {
          return (
            <Heading level={1} key={index} weight="bold">
              {item.components[0].content}
            </Heading>
          );
        } else if (classes.includes("H2")) {
          return (
            <Heading level={2} key={index} weight="bold">
              {item.components[0].content}
            </Heading>
          );
        } else if (classes.includes("H3")) {
          return (
            <Heading level={3} key={index} weight="bold">
              {item.components[0].content}
            </Heading>
          );
        } else {
          return (
            <Paragraph size="large" key={index}>
              {item.components[0].content}
            </Paragraph>
          );
        }
      }

      const classes = Array.isArray(item.classes) ? item.classes : [];
      const combinedIndex = parentIndex
        ? `${parentIndex}-${index}`
        : `${index}`;

      if (item.classes && item.classes.includes("card")) {
        // Extract iframe from the components of the card
        const iframeComponent = item.components.find(
          (comp) => comp.type === "iframe"
        );
        // const textComponent = item.components.find(
        //   (comp) => comp.type === "text"
        // );

        return (
          <Flex
            direction={"column"}
            customClass={`card ${
              expandedCards[combinedIndex] ? "cardExpanded" : ""
            }`}
            key={index}
          >
            {/* Render all components except the iframe */}
            <Flex
              direction={"column"}
              customClass={"cardImageText"}
              align={"start"}
            >
              {item.components
                .filter(
                  (comp) => comp.type !== "iframe" && comp.type !== "text"
                )
                .map((comp, compIndex) =>
                  renderContent([comp], `${combinedIndex}-${compIndex}`)
                )}
              <Flex direction={"column"} customClass={"cardTexts"}>
                {item.components
                  .filter((comp) => comp.type === "text")
                  .map((comp, compIndex) =>
                    renderContent([comp], `${combinedIndex}-${compIndex}`)
                  )}
              </Flex>
            </Flex>
            <IconButton
              size="medium"
              onClick={() => toggleCardExpansion(combinedIndex)}
            >
              <SizeIcon />
            </IconButton>
            {expandedCards[combinedIndex] && iframeComponent && (
              <Flex customClass={"showMore"}>
                <Iframe
                  url={
                    iframeComponent.attributes && iframeComponent.attributes.src
                  }
                  title={
                    iframeComponent.attributes &&
                    iframeComponent.attributes.title
                  }
                />
              </Flex>
            )}
          </Flex>
        );
      }

      if (
        item["custom-name"] === "container" ||
        classes.includes("container")
      ) {
        return (
          <Flex direction={"row"} customClass="container" key={index}>
            {item.components && renderContent(item.components)}
          </Flex>
        );
      }

      if (item["custom-name"] === "image" || item["type"] === "image") {
        return (
          <img
            className="image"
            key={index}
            src={item.attributes && item.attributes.src}
            alt={(item.attributes && item.attributes.alt) || "Image"}
          />
        );
      }

      // Add more cases as needed for other types
      return null;
    });
  };

  useEffect(() => {
    const checkPosition = () => {
      const detector = document.querySelector(".detector");
      const menu = document.querySelector(".sidebarMenu");
      const previewBody = document.querySelector(".PagePreviewBody");

      if (!detector || !menu || !previewBody) {
        console.log("Elements not found");
        return;
      }

      const rect = detector.getBoundingClientRect();
      // const previewBodyRect = previewBody.getBoundingClientRect();

      if (rect.top <= 79) {
        // console.log("Adding Touched class");
        menu.classList.add("Touched");
        previewBody.classList.add("Touched");

        // setTimeout(() => {
        //   // Delay calculating left position
        //   const previewBodyRect = previewBody.getBoundingClientRect();
        //   const newLeft = previewBodyRect.left;
        //   menu.style.left = `${newLeft}px`;
        // }, 0);
        const previewBodyRect = previewBody.getBoundingClientRect();
        const newLeft = previewBodyRect.left;
        menu.style.left = `${newLeft}px`;
      } else {
        // console.log("Removing Touched class");
        menu.style.left = ""; // Reset the left position

        menu.classList.remove("Touched");
        previewBody.classList.remove("Touched");
        // setTimeout(() => {

        // }, 100);
      }
    };

    // Initial check
    checkPosition();

    window.addEventListener("scroll", checkPosition);
    window.addEventListener("resize", checkPosition); // Detect viewport change

    // Cleanup function to remove event listeners
    return () => {
      window.removeEventListener("scroll", checkPosition);
      window.removeEventListener("resize", checkPosition);
    };
  }, []);

  const handleNavigation = (path, isPublic) => {
    if (!loggedInUser && !isPublic) {
      // Redirect to login if the user is not logged in and the page is not public
      navigate("/login");
    } else {
      // Normal navigation
      navigate(path);
    }
  };

  const renderSidebarMenu = () => {
    return filteredMenuItems.map((note, index) => {
      const isActive = note.projectFileId === id;
      // console.log(
      //   `Note ID: ${note.projectFileId}, Active ID: ${id}, isActive: ${isActive}`
      // );

      // /${note.projectTitle}
      return (
        <Button
          size="medium"
          fluid
          variant="ghost"
          key={index}
          onClick={() =>
            handleNavigation(
              `/${note.organisationName}/${note.projectFileId}`,
              note.isPublic
            )
          }
          aria-pressed={isActive ? "true" : "false"}
        >
          <FileTextIcon />
          <span>{note.projectFileTitle}</span>
          {!note.isPublic && <LockClosedIcon />}
        </Button>
      );
    });
  };

  //
  // Check for 'api' query parameter
  const isApiMode = new URLSearchParams(location.search).has("api");
  //
  if (isApiMode) {
    // Render JSON data if 'api' query parameter is present
    return <pre>{JSON.stringify(content, null, 2)}</pre>;
  }

  return (
    <Flex direction={"column"} customClass={"PagePreview"} align={"center"}>
      <Flex direction={"column"} customClass={"PagePreviewHeader"}>
        <MenubarPage logo={logo} />
      </Flex>
      <Toast
        showToast={showToastCopied}
        setShowToast={setShowToastCopied}
        text="Copied to clipboard 🚀"
        time={2000}
        simple
      />
      <Flex gap={400} customClass={"PagePreviewBodyContainer"}>
        <Flex customClass={`sidebarMenu`} direction={"column"}>
          <Input
            label="Search"
            placeholder="Search for page"
            errormsg={false}
            type="search"
            value={searchQuery}
            onChange={handleSearchChange} // Add onChange handler
          />
          <Heading level={4} weight="bold">
            Menu
          </Heading>
          {isLoading && <Loader />}

          <ScrollArea
            style={{
              maxHeight: "70vh",
            }}
          >
            {renderSidebarMenu()}
          </ScrollArea>
        </Flex>
        <Flex direction={"column"} customClass={"PagePreviewBody"}>
          <Flex customClass={"detector"}></Flex>
          {isLoading && <Loader />}

          {content && content.length > 0 ? (
            renderContent(content)
          ) : (
            <p>No content found</p>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PagePreview;
