import React, { useState, useEffect } from "react";
import "./../radix-styles/Dialog.scss";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import {
  Button,
  Flex,
  Paragraph,
  Heading,
  Input,
  IconButton,
  ComboBox,
} from "blocksin-system";

const DialogComponentCardUpdate = ({
  onSave,
  open,
  onOpenChange,
  cardId,
  currentDescription,
  currentTitle,
  currentImage,
  currentOwners,
  allUsers,
}) => {
  const [title, setTitle] = useState(currentTitle || "");
  const [image, setImage] = useState(currentImage);
  const [description, setDescription] = useState(currentDescription);
  const [selectedOwners, setSelectedOwners] = useState([]);
  const [usersData, setUsersData] = useState([]);

  useEffect(() => {
    if (allUsers && allUsers.length > 0) {
      setUsersData(allUsers);
    }
  }, [allUsers]);

  useEffect(() => {
    if (open && currentOwners && usersData.length > 0) {
      const ownersForSelect = currentOwners
        .map((ownerId) => {
          const user = usersData.find((u) => u._id === ownerId);
          return user
            ? {
                label: user.username,
                value: user._id,
                avatar: user.avatar,
              }
            : null;
        })
        .filter((user) => user != null);
      setSelectedOwners(ownersForSelect);
    }
  }, [open, currentOwners, usersData]);

  const handleSave = () => {
    const trimmedTitle = title.trim();
    if (!trimmedTitle) {
      return;
    }
    const ownerIds = selectedOwners.map((selectedOwner) => selectedOwner.value);
    const updatedCardData = {
      title: trimmedTitle,
      description,
      projectFileImage: image,
      owners: ownerIds,
    };
    onSave(
      cardId,
      updatedCardData.description,
      updatedCardData.title,
      updatedCardData.projectFileImage,
      updatedCardData.owners
    );
    onOpenChange(false);
  };

  const renderUserSelect = () => {
    const defaultAvatar =
      "data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_803_1685)'%3E%3Crect width='64' height='64' fill='white'/%3E%3Cg filter='url(%23filter0_f_803_1685)'%3E%3Cpath d='M-8 -5L72 69' stroke='%23F232C8' stroke-width='26'/%3E%3C/g%3E%3Crect x='16' y='32' width='16' height='16' fill='white'/%3E%3Crect x='32' y='32' width='16' height='16' fill='white'/%3E%3Crect x='32' y='16' width='16' height='16' fill='%2307FEB4'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_803_1685' x='-80.8271' y='-78.5432' width='225.654' height='221.087' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='32' result='effect1_foregroundBlur_803_1685'/%3E%3C/filter%3E%3CclipPath id='clip0_803_1685'%3E%3Crect width='64' height='64' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A";

    const options = usersData.map((user) => ({
      label: user.username,
      value: user._id,
      avatar: user.avatar || defaultAvatar,
    }));
    const handleChange = (selectedOptions) => {
      setSelectedOwners(selectedOptions);
    };
    return (
      <ComboBox
        options={options}
        value={selectedOwners}
        onChange={handleChange}
        placeholder="Select owners..."
        isMulti={true}
        fluid
      />
    );
  };

  return (
    <>
      <Dialog.Root
        className="DialogRoot"
        open={open}
        onOpenChange={onOpenChange}
      >
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent">
            <Heading level={3} weight="bold">
              Edit Card Details
            </Heading>
            <Flex customClass="DialogDescription" direction={"column"}>
              <Paragraph size="large">
                Here you can edit information about the component such as:
                update owners or add description.
              </Paragraph>
            </Flex>
            <Flex direction="column" gap={200}>
              <input
                type="file"
                onChange={(e) => setImage(e.target.files[0])}
                style={{ display: "none" }}
                id="image-upload"
              />
              <Button
                size="medium"
                variant="outline"
                fluid
                onClick={() => document.getElementById("image-upload").click()}
              >
                {image ? "Image Selected" : "Upload Image"}
              </Button>
              <Input
                label="Title"
                placeholder="Change title"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <Input
                label="Description"
                placeholder="Enter description"
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              {renderUserSelect()}
            </Flex>
            <Flex
              direction="row"
              align="end"
              justify={"end"}
              gap={200}
              style={{
                marginTop: "var(--size-400)",
                paddingTop: "var(--size-150)",
                borderTop: "1px solid var(--border-neutral-subtle)",
              }}
            >
              <Button size="medium" variant="solid" onClick={handleSave}>
                Update Component
              </Button>
            </Flex>
            <Flex customClass="closeButton">
              <Dialog.Close asChild>
                <IconButton aria-label="Close" size="small" variant="ghost">
                  <Cross2Icon />
                </IconButton>
              </Dialog.Close>
            </Flex>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

export default DialogComponentCardUpdate;
