import React from "react";
import "../Table.scss";
import { CodeHighlight, Paragraph, Heading } from "blocksin-system";

const TableComponent = ({ tableData }) => {
  const renderTable = (data) => {
    if (!data || !data.components || data.components.length === 0) {
      return null;
    }

    const rows = data.components[0].components; // Accessing the rows directly

    return (
      <table className="Table" {...data.attributes}>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {(row.components || []).map((cell, cellIndex) => {
                // Decide if the cell is a 'th' or 'td' - here's an example based on row index
                // You can modify this logic based on your actual data structure
                const isHeader = rowIndex === 0; // Assuming the first row contains headers
                // Or check for a specific property or class name in `cell` that indicates a header
                // const isHeader = cell.isHeader || cell.classes.includes('header-class');

                return isHeader ? (
                  <th key={cellIndex}>{renderCellContent(cell)}</th>
                ) : (
                  <td key={cellIndex}>{renderCellContent(cell)}</td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderCellContent = (cell) => {
    if (cell.components) {
      return cell.components.map((content, contentIndex) => (
        <div key={contentIndex}>{renderContent(content)}</div>
      ));
    }
    return null;
  };

  const renderContent = (item) => {
    if (item.type === "text") {
      const classes = Array.isArray(item.classes) ? item.classes : [];

      if (classes.includes("H1")) {
        return (
          <Heading level={1} key={item.id} weight="bold">
            {item.components[0].content}
          </Heading>
        );
      } else if (classes.includes("H2")) {
        return (
          <Heading level={2} key={item.id} weight="bold">
            {item.components[0].content}
          </Heading>
        );
      } else if (classes.includes("H3")) {
        return (
          <Heading level={3} key={item.id} weight="bold">
            {item.components[0].content}
          </Heading>
        );
      } else if (classes.includes("code-block")) {
        // console.log("Rendering Code Block:", item.components[0].content); // Debugging
        return (
          <CodeHighlight key={item.id} text={item.components[0].content} />
        );
      } else {
        return (
          <Paragraph size="large" key={item.id}>
            {item.components[0].content}
          </Paragraph>
        );
      }
    }
    return null;
  };

  return renderTable(tableData);
};

export default TableComponent;
