import React, { useState, useEffect } from "react";
import DialogInsideProject from "./dialogs/DialogInsideProject";
import {
  ViewGridIcon,
  ViewHorizontalIcon,
  // FigmaLogoIcon,
  // Component1Icon,
  // OpacityIcon,
} from "@radix-ui/react-icons";
import CardInsideProject from "./CardInsideProject";
import "./ProjectGrid.scss";
import DialogComponentCardUpdate from "./dialogs/DialogComponentCardUpdate";
import DialogComponentCardUpdateFigma from "./dialogs/DialogComponentCardUpdateFigma";
import DialogComponentCardUpdateGithub from "./dialogs/DialogComponentCardUpdateGithub";
import DialogComponentCardUpdateColors from "./dialogs/DialogComponentCardUpdateColors";
import DialogComponentCardUpdateMoveTo from "./dialogs/DialogComponentCardUpdateMoveTo";
import DialogInsideProjectIcons from "./dialogs/DialogInsideProjectIcons";
import axios from "axios";
import { Button, Heading, Flex, Toast, ToggleGroup } from "blocksin-system";

const Project = ({
  projectData,
  username,
  onBack,
  onShowCardBoard,
  setActiveCardInSidebar,
  onShowNotes,
  toggleViewState,
  setToggleViewState,
  currentUser,
  // id,
  // description,
  // title,
  // image,
  onCardUpdate,
  onUpdateFigmaURL,
  onUpdateColors,
  onUpdateGithubBranch,
  onDeleteCard,
  // onMoveTo,
  // figmaURL,
  // onNoteDisplay,
  githubBranch,
  // colorsURL,
  // figmaFileId,
  fetchOrganisation,
  allUsers,
  // refreshOrgData,
  organisationInfo,
  setNestedCards,
  nestedCards,
  handleCardUpdate,
  setIsDialogColorsOpen,
  isDialogColorsOpen,
  moveToProjectId,
  setMoveToProjectId,
  userOrganisationProjects,
  handleSaveCardMoveTo,
  setIsDialogGithubOpen,
  isDialogGithubOpen,
  setIsDialogIconsVisible,
  isDialogIconsVisible,
  figmaToken,
  figmaIcons,
  id,
}) => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [currentSubProject, setCurrentSubProject] = useState(null);
  //
  //
  //
  //
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  // const [isDialogIconsVisible, setIsDialogIconsVisible] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastDismissed, setToastDismissed] = useState(false);

  const handleAddCard = () => {
    setIsDialogVisible(true);
  };
  // const handleAddCardIcons = () => {
  //   setIsDialogIconsVisible(true);
  // };

  //
  //
  //
  //
  //
  //
  // newCardData
  // const [newSubProjectTitle, setNewSubProjectTitle] = useState({});
  const [subProjectTitle, setSubProjectTitle] = useState("");
  const [subProjectFigmaToken, setSubProjectFigmaToken] = useState("");
  const [subProjectFigmaURL, setSubProjectFigmaURL] = useState("");

  const handleSaveCard = async (projectId, cardData) => {
    const token = localStorage.getItem("token");
    if (!token || !subProjectTitle) {
      console.error("Token is missing or sub-project title is empty.");
      return;
    }

    try {
      const response = await axios.post(
        `${serverUrl}/api/projects/projects/${projectId}/sub-projects`,
        cardData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSubProjectTitle("");

      if (response.status === 200) {
        // Update nestedCards state with the new sub-project
        await fetchOrganisation();

        setNestedCards((prevCards) => [...prevCards, response.data]);
      } else {
        console.error("Failed to create sub project:", response.data);
      }
    } catch (error) {
      console.error("Error creating sub-project:", error);
    }

    // updateUserCredentials(updatedUser); // Update the user credentials in App.js
    setIsDialogVisible(false);
    setShowToast(true);
    setTimeout(() => setShowToast(false), 5000);
  };

  const handleSaveCardIcons = async (projectId, cardData) => {
    const token = localStorage.getItem("token");
    if (
      !token ||
      !subProjectTitle ||
      !subProjectFigmaToken ||
      !subProjectFigmaURL
    ) {
      console.error("Token is missing or sub-project title is empty.");
      return;
    }

    try {
      const response = await axios.post(
        `${serverUrl}/api/projects/projects/${projectId}/sub-projects`,
        cardData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSubProjectTitle("");
      setSubProjectFigmaToken("");
      setSubProjectFigmaURL("");

      if (response.status === 200) {
        // Update nestedCards state with the new sub-project
        await fetchOrganisation();

        setNestedCards((prevCards) => [...prevCards, response.data]);
      } else {
        console.error("Failed to create sub project:", response.data);
      }
    } catch (error) {
      console.error("Error creating sub-project:", error);
    }

    // updateUserCredentials(updatedUser); // Update the user credentials in App.js
    setIsDialogIconsVisible(false);
    setShowToast(true);
    setTimeout(() => setShowToast(false), 5000);
  };

  const handleDismissForever = () => {
    setToastDismissed(true);
    localStorage.setItem(
      `${username}_${projectData.title}_toastDismissed`,
      "true"
    );
  };
  //
  //
  //
  //
  //
  const [currentCardIdForUpdate, setCurrentCardIdForUpdate] = useState(null);

  // const currentCard = [];

  //
  //
  //
  //
  const [isDialogMoveToOpen, setIsDialogMoveToOpen] = useState(false);

  const openMoveToDialog = (cardId) => {
    const selectedSubProject = nestedCards.find((card) => card._id === cardId);
    setCurrentSubProject(selectedSubProject); // Set the selected subProject
    setCurrentCardIdForUpdate(cardId);
    setIsDialogMoveToOpen(true);
  };
  //
  //
  //
  //
  //
  const openColorsDialog = (cardId) => {
    setCurrentCardIdForUpdate(cardId);
    setIsDialogColorsOpen(true);
  };

  //
  //
  //
  //
  const openGithubDialog = (cardId) => {
    setCurrentCardIdForUpdate(cardId);
    setIsDialogGithubOpen(true);
  };
  //
  //
  //
  //
  //
  //
  const [isDialogFigmaOpen, setIsDialogFigmaOpen] = useState(false);

  const [currentFigmaURL, setCurrentFigmaURL] = useState();

  const openFigmaDialog = (cardId) => {
    const cardToEdit = nestedCards.find((card) => card._id === cardId);
    console.log("Card to Edit:", cardToEdit); // Add this line to log the card data
    if (cardToEdit) {
      setCurrentFigmaURL(cardToEdit.figmaURL);
    }

    setCurrentCardIdForUpdate(cardId);
    setIsDialogFigmaOpen(true);
  };
  //
  //
  //
  //
  // Define handleSaveFigma inside Project component
  const handleSaveFigma = (figmaURL) => {
    if (currentCardIdForUpdate) {
      setIsDialogFigmaOpen(false);
    }
  };

  //
  //
  //
  //
  //
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
  const [currentTitle, setCurrentTitle] = useState();
  const [currentDescription, setCurrentDescription] = useState();
  const [currentImage, setCurrentImage] = useState();
  const [currentOwners, setCurrentOwners] = useState([]);
  // Function to open the update dialog
  const openUpdateDialog = (cardId) => {
    const cardToEdit = nestedCards.find((card) => card._id === cardId);
    console.log("Card to Edit:", cardToEdit); // Add this line to log the card data
    if (cardToEdit) {
      setCurrentTitle(cardToEdit.title);
      setCurrentDescription(cardToEdit.description);
      setCurrentImage(cardToEdit.projectFileImage);
      setCurrentOwners(cardToEdit.owners); // Ensure correct format
    }
    setCurrentCardIdForUpdate(cardId);
    setIsUpdateDialogOpen(true);
  };

  //
  //
  //
  //

  const handleDialogSave = (
    cardId,
    newDescription,
    newTitle,
    newImage,
    updatedOwners
  ) => {
    console.log("Received in handleDialogSave:", {
      cardId,
      newDescription,
      newTitle,
      newImage,
      updatedOwners,
    });

    // Check if updatedOwners is defined and is an array
    if (!Array.isArray(updatedOwners)) {
      console.error(
        "Updated owners data is missing or not an array",
        updatedOwners
      );
      return;
    }

    const updatedCardData = {
      title: newTitle,
      projectFileImage: newImage,
      description: newDescription,
      owners: updatedOwners, // Assuming this is an array of user IDs
    };

    // Debugging log to check the final data structure before sending
    console.log("Updated Card Data to be sent:", updatedCardData);

    onCardUpdate(cardId, updatedCardData);
    handleCardUpdate(cardId, updatedCardData);
  };

  //
  //
  //
  //
  //
  //
  //
  useEffect(() => {
    if (projectData && projectData.projectFiles) {
      setNestedCards(projectData.projectFiles);
    }
  }, [projectData, setNestedCards]);
  //
  //
  //
  //

  return (
    <Flex direction={"column"}>
      <Flex direction={"column"} gap={200} customClass={"LibraryHeader"}>
        {/* <Heading level={4} weight="bold">
          Quick actions
        </Heading>
        <Flex gap={200} customClass="QuickActions">
          {/* <Button size="large" variant="outline" disabled>
            <OpacityIcon />
            Add Colors
          </Button>
          <Button size="large" variant="outline" disabled>
            <FigmaLogoIcon />
            Add Figma
          </Button> */}
        {/* <Button
            size="large"
            variant="outline"
            onClick={handleAddCardIcons}
            showBadge
            badgeLabel="New"
            helper="Sync Figma with React"
            iconLeft={Component1Icon}
          >
            Add Icons
          </Button> */}
        {/* </Flex> */}
        <Heading level={4} weight="bold">
          {projectData.title}
        </Heading>
        <Flex justify={"between"}>
          <Flex gap={200}>
            <Button size="medium" variant="solid" onClick={handleAddCard}>
              Register Component
            </Button>
            <Button size="medium" variant="outline" onClick={onBack}>
              Back to Dashboard
            </Button>
          </Flex>
          <Flex gap={200}>
            <ToggleGroup
              type="single"
              value={toggleViewState} // Step 4: Bind state to toggle
              onValueChange={setToggleViewState} // Update state on toggle change
              aria-label="Project view"
            >
              <ToggleGroup.Item value="Grid" aria-label="Grid view">
                <ViewGridIcon />
              </ToggleGroup.Item>
              <ToggleGroup.Item value="List" aria-label="List view">
                <ViewHorizontalIcon />
              </ToggleGroup.Item>
            </ToggleGroup>
          </Flex>
        </Flex>
      </Flex>

      <div className="ComponentGrid">
        {nestedCards && nestedCards.length > 0 ? (
          nestedCards.map((subProject) => (
            <CardInsideProject
              key={subProject._id}
              title={subProject.title}
              id={subProject._id}
              projectId={projectData?._id}
              figmaIcons={subProject.figmaIcons}
              description={subProject.description}
              colorsURL={subProject.colorsURL}
              figmaURL={subProject.figmaURL}
              githubBranch={subProject.githubBranch}
              githubRepo={subProject.githubRepo}
              image={subProject.projectFileImage}
              owners={subProject.owners}
              allUsers={organisationInfo.users}
              handleDeleteCard={onDeleteCard}
              onShowCardBoard={onShowCardBoard}
              setActiveCardInSidebar={setActiveCardInSidebar}
              figmaToken={subProject.figmaToken}
              // onNoteDisplay={onNoteDisplay}
              onShowNotes={onShowNotes}
              handleSaveCardDescription={handleDialogSave}
              handleSaveCardMoveTo={handleSaveCardMoveTo}
              currentUser={currentUser}
              openUpdateDialog={() => openUpdateDialog(subProject._id)}
              openFigmaDialog={() => openFigmaDialog(subProject._id)}
              openGithubDialog={() => openGithubDialog(subProject._id)}
              openColorsDialog={() => openColorsDialog(subProject._id)}
              openMoveToDialog={() => openMoveToDialog(subProject._id)}
              onUpdateCard={onCardUpdate}
              onDeleteCard={onDeleteCard}
              onUpdateFigmaURL={onUpdateFigmaURL}
              onUpdateGithubBranch={onUpdateGithubBranch}
              onUpdateColors={onUpdateColors}
              toggleViewState={toggleViewState}
              onUpdate={onCardUpdate}
            />
          ))
        ) : (
          <Heading level={4}>No sub-projects</Heading>
        )}
      </div>

      {isUpdateDialogOpen && (
        <DialogComponentCardUpdate
          open={isUpdateDialogOpen}
          onOpenChange={setIsUpdateDialogOpen}
          onSave={handleDialogSave}
          cardId={currentCardIdForUpdate}
          currentDescription={currentDescription} // Modify this if needed
          currentTitle={currentTitle} // Modify this if needed
          currentImage={currentImage} // Modify this if needed
          currentOwners={currentOwners} // Ensure this is the correct data
          onUpdateCard={onCardUpdate}
          allUsers={allUsers}
        />
      )}

      {isDialogFigmaOpen && (
        <DialogComponentCardUpdateFigma
          open={isDialogFigmaOpen}
          onOpenChange={setIsDialogFigmaOpen}
          onSave={handleSaveFigma}
          cardId={currentCardIdForUpdate}
          currentFigmaURL={currentFigmaURL}
          onUpdateFigmaURL={onUpdateFigmaURL}
        />
      )}

      {isDialogGithubOpen && (
        <DialogComponentCardUpdateGithub
          open={isDialogGithubOpen}
          onOpenChange={setIsDialogGithubOpen}
          cardId={currentCardIdForUpdate}
          currentGithubBranch={githubBranch}
          onUpdateGithubBranch={onUpdateGithubBranch}
        />
      )}

      {isDialogColorsOpen && (
        <DialogComponentCardUpdateColors
          open={isDialogColorsOpen}
          onOpenChange={setIsDialogColorsOpen}
          cardId={currentCardIdForUpdate}
          onUpdateColors={onUpdateColors}
        />
      )}
      {isDialogMoveToOpen && (
        <DialogComponentCardUpdateMoveTo
          open={isDialogMoveToOpen}
          onOpenChange={setIsDialogMoveToOpen}
          onSave={handleSaveCardMoveTo}
          cardId={currentCardIdForUpdate}
          moveToProjectId={moveToProjectId}
          setMoveToProjectId={setMoveToProjectId}
          project={projectData}
          userOrganisationProjects={userOrganisationProjects}
          subProject={currentSubProject}
        />
      )}

      {isDialogVisible && (
        <DialogInsideProject
          open={isDialogVisible}
          onOpenChange={setIsDialogVisible}
          onSave={handleSaveCard}
          username={username}
          updateSubProjectTitle={(title) => setSubProjectTitle(title)}
          projectId={projectData._id}
        />
      )}

      {isDialogIconsVisible && (
        <DialogInsideProjectIcons
          open={isDialogIconsVisible}
          onOpenChange={setIsDialogIconsVisible}
          onSave={handleSaveCardIcons}
          username={username}
          updateSubProjectTitle={(title) => setSubProjectTitle(title)}
          updateSubProjectFigmaToken={(figmaToken) =>
            setSubProjectFigmaToken(figmaToken)
          }
          updateSubProjectFigmaURL={(figmaURL) =>
            setSubProjectFigmaURL(figmaURL)
          }
          projectId={projectData._id}
        />
      )}

      {showToast && !toastDismissed && (
        // <ToastInsideProjectSaved
        //   showToast={showToast}
        //   onDismissForever={handleDismissForever}
        // />
        <Toast
          showToast={showToast}
          setShowToast={setShowToast} // Add this line
          headline="Success!"
          text="Component Card has been saved 🚀"
          time={3000}
          showAgain={true}
          onDismissForever={handleDismissForever}
        />
      )}
    </Flex>
  );
};

export default Project;
