import React, { useState } from "react";
import "./../radix-styles/Dialog.scss";
import { Cross2Icon } from "@radix-ui/react-icons";
import * as Dialog from "@radix-ui/react-dialog";
import {
  Button,
  Flex,
  Paragraph,
  Heading,
  IconButton,
  Input,
} from "blocksin-system";

const DialogInsideProject = ({
  onSave,
  open,
  onOpenChange,
  // username,
  updateSubProjectTitle,
  projectId,
}) => {
  const [title, setTitle] = useState("");
  // const [author, setAuthor] = useState(username);
  const [image, setImage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [imageName, setImageName] = useState(""); // New state for storing image name
  // eslint-disable-next-line no-unused-vars
  const [cardTitles, setCardTitles] = useState([]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    updateSubProjectTitle(e.target.value); // Update title in parent component
  };

  const handleSave = () => {
    const trimmedTitle = title.trim();

    if (!trimmedTitle) {
      setErrorMessage("Title cannot be empty.");
      return;
    }

    onSave(projectId, { title: trimmedTitle, projectFileImage: image }); // Include image data
    setTitle("");
    setImage("");
    setImageName("");
    setErrorMessage("");

    // Update the cardTitles array to include the new title
    setCardTitles((prevTitles) => [...prevTitles, trimmedTitle]);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 202400) {
      setErrorMessage("File size should not exceed 200 KB.");
      return;
    }

    setImageName(file.name); // Update the image name

    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <Dialog.Root
        className="DialogRoot"
        open={open}
        onOpenChange={onOpenChange}
      >
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent">
            <Heading level={3} weight="bold">
              Add Component
            </Heading>
            <Flex customClass="DialogDescription" direction={"column"}>
              <Paragraph size="large">
                Here you can add new component to your Design Library, assign
                Dev and Design ownership and connect with relevant resources
                like Figma files and Storybook documentations for more
                comprehensive overview.
              </Paragraph>
            </Flex>
            <Flex direction="column" gap={200}>
              <Input
                label="Component name"
                placeholder="Enter component name"
                errormsg={false}
                type="text"
                value={title}
                onChange={handleTitleChange}
              />
              <input
                type="file"
                onChange={handleImageChange}
                style={{ display: "none" }}
                id="image-upload"
              />
              <Button
                size="medium"
                variant="outline"
                fluid
                onClick={() => document.getElementById("image-upload").click()}
              >
                {imageName || "Upload Image"}
              </Button>
            </Flex>

            {errorMessage && <p className="Error">{errorMessage}</p>}
            <Flex
              direction="column"
              align="end"
              style={{
                marginTop: "var(--size-400)",
                paddingTop: "var(--size-150)",
                borderTop: "1px solid var(--border-neutral-subtle)",
              }}
            >
              <Button size="medium" variant="solid" onClick={handleSave}>
                Save
              </Button>
            </Flex>
            <Flex customClass="closeButton">
              <Dialog.Close asChild>
                <IconButton aria-label="Close" size="small" variant="ghost">
                  <Cross2Icon />
                </IconButton>
              </Dialog.Close>
            </Flex>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

export default DialogInsideProject;
