import React, { useState, useEffect } from "react";
import "./SettingsPage.scss";
import { HexColorPicker } from "react-colorful";
import AlertDialogCustom from "./AlertDialog";
import axios from "axios";
import {
  Separator,
  Button,
  Flex,
  Input,
  Paragraph,
  Heading,
  BadgeLevel,
  TopBanner,
  Checkbox,
  Toast,
  UserOnHoldItem,
  DropdownMenu,
  Select,
} from "blocksin-system";
// import UserOnHoldItem from "./components/UserOnHoldItem";

const SettingsPage = ({
  username,
  currentUser,
  loggedInUser,
  organisationInfo,
  setLoggedInUser,
  isUserAdmin,
  refreshUserData,
  refreshOrgData,
  setOrganisationInfo,
  onLogout,
  dismissNotification,
  currentNotification,
}) => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  const [firstName, setFirstName] = useState("");
  const [figmaFileId, setFigmaFileId] = useState("");
  const [figmaAccessToken, setFigmaAccessToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [newMainGithubAccessToken, setNewMainGithubAccessToken] = useState("");
  const [newMainFigmaAccessToken, setNewMainFigmaAccessToken] = useState("");

  // const [currentUserDetails, setCurrentUserDetails] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [showToastGithub, setShowToastGithub] = useState(false);
  const [showToastFigma, setShowToastFigma] = useState(false);
  const [showToastFirstName, setShowToastFirstName] = useState(false);
  const [showToastOrgDelete, setShowToastOrgDelete] = useState(false);

  const [showToastAvatar, setShowToastAvatar] = useState(false);
  const [showToastLogo, setShowToastLogo] = useState(false);
  const [showCloseAccountDialog, setShowCloseAccountDialog] = useState(false);
  const [showDeleteOrgDialog, setShowDeleteOrgDialog] = useState(false);
  //
  const [selectedNewAdmin, setSelectedNewAdmin] = useState(null);
  //
  //
  //
  //
  //
  //
  //
  //
  // Updated to handle multiple roles
  const [newUserRole, setNewUserRole] = useState(loggedInUser?.role || []);
  // const isUserAdmin = loggedInUser?.role?.includes("Admin");

  useEffect(() => {
    // Set newUserRole to the roles of the loggedInUser whenever it changes
    setNewUserRole(loggedInUser?.role || []);
  }, [loggedInUser]);

  // Handle checkbox change for roles
  const handleRoleChange = (role) => {
    setNewUserRole((prevRoles) => {
      if (prevRoles.includes(role)) {
        // Remove role if already selected
        return prevRoles.filter((r) => r !== role);
      } else {
        // Add new role
        return [...prevRoles, role];
      }
    });
  };

  // Function to handle role update
  const handleRoleUpdate = async () => {
    // Check if removing Admin role and no other admins exist
    const isRemovingAdmin =
      loggedInUser.role.includes("Admin") && !newUserRole.includes("Admin");
    if (isRemovingAdmin && !isOtherAdminExists()) {
      console.error("Cannot remove Admin role as no other Admins are present.");
      return;
    }

    // Proceed with role update
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token is missing.");
      return;
    }

    try {
      const response = await axios.patch(
        `${serverUrl}/api/users/users/${loggedInUser._id}`,
        { role: newUserRole },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Check if the role update was successful
      if (response.status === 200) {
        // Update the role in the local state
        const updatedUser = { ...loggedInUser, role: newUserRole };
        setLoggedInUser(updatedUser);
        localStorage.setItem("loggedInUser", JSON.stringify(updatedUser));
        refreshUserData();
      } else {
        console.error("Failed to update user role:", response.data);
      }
    } catch (error) {
      console.error("Error updating user role:", error);
    }
  };

  const isOtherAdminExists = () => {
    if (Array.isArray(organisationInfo.users)) {
      return organisationInfo.users.some(
        (user) => user.role.includes("Admin") && user._id !== loggedInUser._id
      );
    }
    return false;
  };

  //
  //
  //
  //
  //
  //
  //
  //

  // Handle new admin selection
  const handleSelectNewAdmin = (newAdminName) => {
    setSelectedNewAdmin(newAdminName);
  };
  //

  const [color, setColor] = useState("");

  const handleCloseAccount = () => {
    setShowCloseAccountDialog(true);
  };
  //
  //
  //
  //
  //
  // Function to handle saving the brand color
  const handleSaveBrandColor = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token is missing.");
        return;
      }

      const response = await axios.patch(
        `${serverUrl}/organisations/${loggedInUser.organisation}/primary-color`,
        { primaryColor: color },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        // Show success message
        // setShowToast(true);
        // setTimeout(() => setShowToastGithub(false), 3000);
        // setNewMainGithubAccessToken("");
        console.log("Primary color updated successfully");
        refreshOrgData(); // Update organization data
      } else {
        // Handle error
        console.error("Failed to update primary color:", response.data);
      }
    } catch (error) {
      console.error("Error updating primary color:", error);
    }
  };
  //
  //
  //
  //
  //
  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleRepeatPasswordChange = (e) => {
    setRepeatPassword(e.target.value);
  };

  const handleGithubAccessTokenChange = (e) => {
    setNewMainGithubAccessToken(e.target.value);
  };

  const handleFigmaAccessTokenChange = (e) => {
    setNewMainFigmaAccessToken(e.target.value);
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };
  //
  //
  //
  const handleUpdatePassword = async () => {
    if (newPassword !== repeatPassword) {
      setPasswordError("Passwords do not match.");
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token is missing.");
      return;
    }

    try {
      const response = await axios.patch(
        `${serverUrl}/api/users/users/${loggedInUser._id}/password`,
        { newPassword },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000);
        setNewPassword("");
        setRepeatPassword("");
        setPasswordError("");
      } else {
        console.error("Failed to update password:", response.data);
      }
    } catch (error) {
      console.error("Error updating password:", error);
    }
  };
  //
  //
  //
  //
  const handleGithubAccessToken = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token is missing.");
      return;
    }

    try {
      const response = await axios.patch(
        `${serverUrl}/api/users/users/${loggedInUser._id}/github-token`,
        { widgetGithubAccessToken: newMainGithubAccessToken },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        setShowToastGithub(true);
        setTimeout(() => setShowToastGithub(false), 3000);
        setNewMainGithubAccessToken("");
      } else {
        console.error("Failed to update GitHub Access Token:", response.data);
      }
    } catch (error) {
      console.error("Error updating GitHub Access Token:", error);
    }
  };
  //
  //
  //
  const handleFigmaAccessToken = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token is missing.");
      return;
    }

    try {
      const response = await axios.patch(
        `${serverUrl}/api/users/users/${loggedInUser._id}/figma-token`,
        { widgetFigmaAccessToken: newMainFigmaAccessToken },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        setShowToastFigma(true);
        setTimeout(() => setShowToastFigma(false), 3000);
        setNewMainFigmaAccessToken("");
      } else {
        console.error("Failed to update Figma Access Token:", response.data);
      }
    } catch (error) {
      console.error("Error updating Figma Access Token:", error);
    }
  };
  //
  //
  //
  const handleFirstName = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token is missing.");
      return;
    }

    try {
      const response = await axios.patch(
        `${serverUrl}/api/users/users/${loggedInUser._id}/name`,
        { name: firstName },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        setShowToastFirstName(true);
        setTimeout(() => setShowToastFirstName(false), 3000);
        refreshUserData();
      } else {
        console.error("Failed to update user name:", response.data);
      }
    } catch (error) {
      console.error("Error updating user name:", error);
    }
  };
  //
  //
  //
  //
  //
  //
  const [newAvatar, setNewAvatar] = useState("");
  const [avatarName, setAvatarName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 202400) {
      setErrorMessage("File size should not exceed 200 KB.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setNewAvatar(reader.result); // Base64 encoded string
      setAvatarName(file.name);
    };
    reader.readAsDataURL(file);
  };

  const handleAvatarSave = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token is missing.");
      return;
    }

    try {
      const response = await axios.patch(
        `${serverUrl}/api/users/users/${loggedInUser._id}/avatar`,
        { avatar: newAvatar },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        // Update avatar in local state and storage
        // setCurrentUserDetails((prevDetails) => ({
        //   ...prevDetails,
        //   avatar: newAvatar,
        // }));

        setShowToastAvatar(true);
        setTimeout(() => setShowToastAvatar(false), 3000);
        refreshUserData();

        // Update local storage and user credentials as needed
        // Show success message
      } else {
        console.error("Failed to update avatar:", response.data);
      }
    } catch (error) {
      console.error("Error updating avatar:", error);
    }
  };
  //
  //
  //
  //
  //
  const [logo, setNewLogo] = useState("");
  const [logoName, setLogoName] = useState("");

  const handleLogoSave = async () => {
    if (!logo) return;

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token is missing.");
        return;
      }

      const response = await axios.patch(
        `${serverUrl}/organisations/${loggedInUser.organisation}/logo`,
        { logo },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Check if the response status is 200
      if (response.status === 200) {
        setShowToastLogo(true);
        setTimeout(() => setShowToastLogo(false), 3000);
        refreshOrgData(); // Call the function to refresh org data
      }

      // Handle success (e.g., show a success message)
    } catch (error) {
      console.error("Error uploading logo:", error);
    }
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 202400) {
      setErrorMessage("File size should not exceed 200 KB.");
      return;
    }

    setLogoName(file.name); // Update the image name

    const reader = new FileReader();
    reader.onloadend = () => {
      setNewLogo(reader.result);
    };
    reader.readAsDataURL(file);
  };
  //
  //
  //
  //
  //

  const sections = [
    { id: "userSettings", title: "User Settings" },
    { id: "apis", title: "APIs" },
    { id: "brandManager", title: "Brand Manager" },
    { id: "users", title: "Users" },
    { id: "dangerZone", title: "Danger Zone" },
    // Add more sections here as needed
  ];

  const [activeSection, setActiveSection] = useState(sections[0].id);

  const handleSectionChange = (sectionId) => {
    setActiveSection(sectionId);
  };
  //
  //
  //
  //
  //
  const handleApproveUser = async (userId, approve) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token is missing.");
      return;
    }

    try {
      await axios.patch(
        `${serverUrl}/api/users/users/${userId}/approve`,
        { isApproved: approve, orgId: organisationInfo._id },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Optimistically update the local state
      setOrganisationInfo((prevState) => {
        return {
          ...prevState,
          users: prevState.users.map((user) =>
            user._id === userId ? { ...user, isApproved: approve } : user
          ),
          pendingApprovals: prevState.pendingApprovals.filter(
            (user) => user._id !== userId
          ),
        };
      });
      refreshOrgData();
    } catch (error) {
      console.error("Error updating user approval status:", error);
      // Refresh the list from server to ensure consistency
      refreshOrgData();
    }
  };
  //
  //
  //
  //
  const updateNewAdminRole = async (newAdminUsername) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token is missing.");
      return;
    }

    const newAdmin = organisationInfo.users.find(
      (user) => user.username === newAdminUsername
    );
    if (newAdmin) {
      try {
        const response = await axios.put(
          `${serverUrl}/api/users/users/${newAdmin._id}/role`,
          { role: ["Admin"] },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (response.status === 200) {
          console.log("New admin role updated successfully");
        }
      } catch (error) {
        console.error("Error updating new admin role:", error);
      }
    }
  };

  //

  const handleDeleteAccount = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token is missing.");
      return;
    }

    // Check if current user is the only user in the organization
    const isOnlyUser = organisationInfo.users.length === 1;

    // Update the new admin's role first if there are other users and current user is admin
    if (!isOnlyUser && selectedNewAdmin && isUserAdmin) {
      await updateNewAdminRole(selectedNewAdmin);
    }

    // Then proceed with account deletion
    try {
      await axios.delete(`${serverUrl}/api/users/users/${loggedInUser._id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Log out the user after account deletion
      setShowCloseAccountDialog(false);
      onLogout();
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };
  //
  //
  const handleDeleteOrganization = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token is missing.");
      return;
    }

    try {
      const response = await axios.delete(
        `${serverUrl}/api/users/organisations/${organisationInfo._id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        setShowDeleteOrgDialog(false); // Close the dialog
        await refreshOrgData();
        setShowToastOrgDelete(true); // Show success toast
        setTimeout(() => setShowToastOrgDelete(false), 3000); // Hide the toast after 3 seconds
      }
    } catch (error) {
      console.error("Error deleting organization:", error);
      // Handle error (e.g., show error message)
    }
  };

  //
  //
  //
  //
  //
  const handleSaveOrgFigmaDetails = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token is missing.");
      return;
    }
    console.log("Figma File ID:", figmaFileId); // Log the Figma File ID
    console.log("Figma Access Token:", figmaAccessToken);
    try {
      const response = await axios.patch(
        `${serverUrl}/organisations/${organisationInfo._id}/figma`,
        {
          figmaFileId: figmaFileId,
          figmaAccessToken: figmaAccessToken,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        console.log("Organization's Figma details updated successfully");
        refreshOrgData(); // Update the organization data in your app
      } else {
        console.error(
          "Failed to update organization's Figma details:",
          response.data
        );
      }
    } catch (error) {
      console.error("Error updating organization's Figma details:", error);
    }
  };

  //
  //
  //
  //
  // const isOnlyAdmin = () => {
  //   // Assuming organisationInfo.users is an array of user objects with a 'role' field
  //   return (
  //     organisationInfo.users.filter((u) => u.role.includes("Admin")).length ===
  //     1
  //   );
  // };
  // const canDeleteAccount = () => {
  //   return !isUserAdmin || (isUserAdmin && !isOnlyAdmin());
  // };
  //
  //
  //
  //
  const renderUserSelect = (users, selectedValue, handleSelectChange) => {
    const filteredUsers = users.filter((user) => user._id !== loggedInUser._id);

    return (
      <Flex
        direction={"column"}
        style={{
          marginTop: "var(--size-200)",
          marginBottom: "var(--size-400)",
        }}
        gap={100}
      >
        <Paragraph size="large">
          Please pass Admin ownership to next user.
        </Paragraph>
        <Select value={selectedValue} onValueChange={handleSelectChange}>
          <Select.Trigger aria-label="Select User">
            <Select.Value placeholder="Select User" />
          </Select.Trigger>
          <Select.Content>
            <Select.Viewport>
              {filteredUsers.map((user) => (
                <Select.Item
                  key={user.username}
                  value={user.username}
                  avatar={user.avatar}
                >
                  {user.username}
                </Select.Item>
              ))}
            </Select.Viewport>
          </Select.Content>
        </Select>
      </Flex>
    );
  };

  return (
    <>
      <Toast
        showToast={showToast}
        setShowToast={setShowToast} // Add this line
        headline="Success!"
        text="Password has been updated 🚀"
        time={3000}
        // showAgain={true}
        // onDismissForever={handleDismissForever}
      />

      <Toast
        showToast={showToastOrgDelete}
        setShowToast={setShowToastOrgDelete}
        headline="Success!"
        text="Organization data deleted successfully 🚀"
        time={3000}
      />

      <Toast
        showToast={showToastGithub}
        setShowToast={setShowToastGithub} // Add this line
        headline="Success!"
        text="GitHub Access Token has been updated 🚀"
        time={3000}
      />

      <Toast
        showToast={showToastFirstName}
        setShowToast={setShowToastFirstName} // Add this line
        headline="Success!"
        text="First name has been updated 🚀"
        time={3000}
      />

      <Toast
        showToast={showToastFigma}
        setShowToast={setShowToastFigma} // Add this line
        headline="Success!"
        text="Figma Access Token has been updated 🚀"
        time={3000}
      />

      <Toast
        showToast={showToastAvatar}
        setShowToast={setShowToastAvatar} // Add this line
        headline="Success!"
        text="Your avatar has been updated 🚀"
        time={3000}
      />

      <Toast
        showToast={showToastLogo}
        setShowToast={setShowToastLogo} // Add this line
        headline="Success!"
        text="Your logo has been updated 🚀"
        time={3000}
      />

      <AlertDialogCustom
        title="Delete Organization"
        description="Are you sure you want to delete all data in organization? This action cannot be undone."
        cta="Yes, delete"
        onClick={handleDeleteOrganization} // Call the deletion function on confirm
        isOpen={showDeleteOrgDialog}
        onOpenChange={setShowDeleteOrgDialog}
      />

      <Flex
        direction={"column"}
        customClass="Page PageSettings"
        align={"center"}
      >
        {currentNotification && (
          <TopBanner
            username={currentUser?.username}
            notification={currentNotification}
            onDismiss={dismissNotification}
          />
        )}

        <Flex gap={300} customClass={"PageSettingsContainer"}>
          <Flex customClass={"SettingsMenuSidebar"} direction={"column"}>
            <Heading level={4} weight="bold">
              Navigation
            </Heading>
            <Flex customClass={"SettingsItems"} direction={"column"}>
              {sections.map(
                (section) =>
                  (section.id !== "users" || isUserAdmin) &&
                  (section.id !== "brandManager" || isUserAdmin) && (
                    <Button
                      variant="ghost"
                      size="small"
                      fluid
                      key={section.id}
                      onClick={() => handleSectionChange(section.id)}
                      aria-pressed={
                        activeSection === section.id ? "true" : undefined
                      }
                    >
                      {section.title}
                    </Button>
                  )
              )}
            </Flex>
          </Flex>
          <Flex
            direction={"column"}
            gap={200}
            customClass="PageBody PageBodySettings"
          >
            <Flex direction={"column"} gap={200} customClass="PageContent">
              <Heading level={3} weight="bold">
                Settings
              </Heading>

              <Flex customClass={"SettingsHeader"}>
                <Flex direction={"column"} gap={100}>
                  <Paragraph size="large">Username: {username}</Paragraph>
                  <Paragraph size="large">Name: {currentUser?.name}</Paragraph>
                  <Paragraph size="large">
                    Organisation: {currentUser?.organisation?.name}
                  </Paragraph>
                  <Paragraph size="large">
                    Email: {currentUser?.email}
                  </Paragraph>
                  <Paragraph size="large">
                    Role: {currentUser?.role.join(", ")}
                  </Paragraph>
                  <Flex gap={100} align={"center"}>
                    <Paragraph size="large">Badge level:</Paragraph>
                    <BadgeLevel badge={currentUser.badge} />
                  </Flex>
                </Flex>
                <Flex customClass={"SettingsAvatar"}>
                  {currentUser.avatar && (
                    <img src={currentUser.avatar} alt="Avatar" />
                  )}
                </Flex>
              </Flex>
              <Separator />
            </Flex>

            {sections.map((section) => (
              <div
                key={section.id}
                style={{
                  display: activeSection === section.id ? "block" : "none",
                }}
              >
                {section.id === "userSettings" && (
                  <Flex
                    id="userSettings"
                    customClass={"PageContent"}
                    direction={"column"}
                    gap={200}
                  >
                    <Heading level={4} weight="bold">
                      User settings
                    </Heading>
                    <Flex
                      gap={200}
                      style={{
                        marginTop: "var(--size-100)",
                        marginBottom: "var(--size-100)",
                      }}
                    >
                      <Input
                        label="Name"
                        type="text"
                        placeholder="Enter new name"
                        value={firstName}
                        onChange={handleFirstNameChange}
                      />
                      <Button onClick={handleFirstName}>Save</Button>
                    </Flex>
                    <Flex
                      gap={200}
                      style={{
                        marginTop: "var(--size-100)",
                        marginBottom: "var(--size-100)",
                      }}
                    >
                      <Input
                        label="New Password"
                        type="password"
                        value={newPassword}
                        onChange={handlePasswordChange}
                        placeholder="Enter new password"
                      />
                    </Flex>
                    <Flex
                      gap={200}
                      style={{
                        marginTop: "var(--size-100)",
                        marginBottom: "var(--size-100)",
                      }}
                    >
                      <Input
                        label="Repeat Password"
                        type="password"
                        value={repeatPassword}
                        onChange={handleRepeatPasswordChange}
                        placeholder="Repeat new password"
                      />
                      <Button onClick={handleUpdatePassword}>Save</Button>
                    </Flex>
                    {passwordError && <p className="Error">{passwordError}</p>}

                    <Flex direction={"column"} gap={50}>
                      <Paragraph>Your avatar</Paragraph>

                      <Flex
                        gap={200}
                        customClass={"uploadAvatar"}
                        style={{
                          marginTop: "var(--size-100)",
                          marginBottom: "var(--size-100)",
                        }}
                      >
                        <input
                          type="file"
                          onChange={handleAvatarChange}
                          style={{ display: "none" }}
                          id="image-upload"
                        />
                        <Button
                          size="medium"
                          variant="outline"
                          onClick={() =>
                            document.getElementById("image-upload").click()
                          }
                        >
                          {avatarName || "Upload Image"}
                        </Button>
                        <Button
                          size="medium"
                          variant="solid"
                          onClick={handleAvatarSave}
                        >
                          Save
                        </Button>
                      </Flex>
                      {errorMessage && <p className="Error">{errorMessage}</p>}
                    </Flex>

                    <Flex direction="column" gap={100}>
                      {/* Admin Checkbox - Disabled for admin users */}
                      <Checkbox
                        customID="admin-checkbox"
                        checked={newUserRole.includes("Admin")}
                        onChange={() => handleRoleChange("Admin")}
                        disabled // Disable this checkbox if the user is an admin
                      >
                        Admin
                      </Checkbox>
                      <Checkbox
                        customID="designer-checkbox"
                        checked={newUserRole.includes("Designer")}
                        onChange={() => handleRoleChange("Designer")}
                      >
                        Designer
                      </Checkbox>
                      <Checkbox
                        customID="developer-checkbox"
                        checked={newUserRole.includes("Developer")}
                        onChange={() => handleRoleChange("Developer")}
                      >
                        Developer
                      </Checkbox>
                      <Checkbox
                        customID="pm-checkbox"
                        checked={newUserRole.includes("PM")}
                        onChange={() => handleRoleChange("PM")}
                      >
                        PM
                      </Checkbox>
                      <Button onClick={handleRoleUpdate}>Update Role</Button>
                    </Flex>
                  </Flex>
                )}

                {section.id === "apis" && (
                  <Flex
                    id="apis"
                    customClass={"PageContent"}
                    direction={"column"}
                    gap={200}
                  >
                    <Heading level={4} weight="bold">
                      APIs
                    </Heading>

                    <Flex
                      gap={200}
                      style={{
                        marginTop: "var(--size-100)",
                        marginBottom: "var(--size-100)",
                      }}
                    >
                      <Input
                        label="GitHub Access Token"
                        type="text"
                        value={newMainGithubAccessToken}
                        onChange={handleGithubAccessTokenChange}
                        placeholder="Enter new Access Token"
                      />
                      <Button onClick={handleGithubAccessToken}>Save</Button>
                    </Flex>
                    <Flex
                      gap={200}
                      style={{
                        marginTop: "var(--size-100)",
                        marginBottom: "var(--size-100)",
                      }}
                    >
                      <Input
                        label="Figma Access Token"
                        type="text"
                        placeholder="Enter new Access Token"
                        value={newMainFigmaAccessToken}
                        onChange={handleFigmaAccessTokenChange}
                      />
                      <Button onClick={handleFigmaAccessToken}>Save</Button>
                    </Flex>

                    {isUserAdmin && (
                      <Flex direction="column" gap={200} id="orgFigmaSettings">
                        <Heading level={4} weight="bold">
                          Organization Figma Settings
                        </Heading>
                        <Flex
                          gap={200}
                          style={{
                            marginTop: "var(--size-100)",
                            marginBottom: "var(--size-100)",
                          }}
                        >
                          <Input
                            label="Figma File ID"
                            type="text"
                            placeholder="Enter Figma File ID"
                            value={figmaFileId}
                            onChange={(e) => setFigmaFileId(e.target.value)}
                          />
                          <Input
                            label="Figma Access Token"
                            type="text"
                            placeholder="Enter Figma Access Token"
                            value={figmaAccessToken}
                            onChange={(e) =>
                              setFigmaAccessToken(e.target.value)
                            }
                          />
                          <Button onClick={handleSaveOrgFigmaDetails}>
                            Save
                          </Button>
                        </Flex>
                      </Flex>
                    )}
                  </Flex>
                )}

                {section.id === "brandManager" && isUserAdmin && (
                  <Flex
                    customClass={"PageContent BrandManager"}
                    direction={"column"}
                    gap={200}
                    id="brandManager"
                  >
                    <Heading level={4} weight="bold">
                      Brand Manager
                    </Heading>
                    <Flex gap={50} direction={"column"}>
                      <Paragraph>Primary Color</Paragraph>
                      <Flex
                        gap={200}
                        style={{
                          marginTop: "var(--size-100)",
                          marginBottom: "var(--size-100)",
                        }}
                      >
                        <DropdownMenu>
                          <DropdownMenu.Trigger asChild>
                            <Button fluid variant="outline">
                              {color || "Select Color"}
                            </Button>
                          </DropdownMenu.Trigger>

                          <DropdownMenu.Content sideOffset={4} align="start">
                            <HexColorPicker color={color} onChange={setColor} />
                          </DropdownMenu.Content>
                        </DropdownMenu>
                        <Button onClick={handleSaveBrandColor}>Save</Button>
                      </Flex>
                      <Flex
                        direction={"column"}
                        gap={50}
                        style={{ marginTop: "var(--size-200)" }}
                      >
                        <Paragraph>Your corporate logo</Paragraph>

                        <Flex
                          gap={200}
                          customClass={"uploadLogo"}
                          style={{
                            marginTop: "var(--size-100)",
                            marginBottom: "var(--size-100)",
                          }}
                        >
                          <input
                            type="file"
                            onChange={handleLogoChange}
                            style={{ display: "none" }}
                            id="image-logo-upload"
                          />
                          <Button
                            size="medium"
                            variant="outline"
                            fluid
                            onClick={() =>
                              document
                                .getElementById("image-logo-upload")
                                .click()
                            }
                          >
                            {logoName || "Upload Logo"}
                          </Button>
                          <Button
                            size="medium"
                            variant="solid"
                            onClick={handleLogoSave}
                          >
                            Save
                          </Button>
                        </Flex>
                        {errorMessage && (
                          <p className="Error">{errorMessage}</p>
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                )}

                {section.id === "users" && isUserAdmin && (
                  <Flex
                    customClass={"PageContent Users"}
                    direction={"column"}
                    gap={200}
                    id="users"
                  >
                    <Heading level={4} weight="bold">
                      Pending Users
                    </Heading>
                    <Flex gap={50} direction={"column"}>
                      <Paragraph>
                        Users waiting to be added to your organisation
                      </Paragraph>
                      <Flex
                        gap={100}
                        direction={"column"}
                        style={{
                          marginTop: "var(--size-200)",
                          marginBottom: "var(--size-200)",
                        }}
                      >
                        {organisationInfo.pendingApprovals.map(
                          (user, index) => (
                            <UserOnHoldItem
                              key={`pending-${user._id}-${index}`}
                              user={user}
                              orgId={organisationInfo._id}
                              onApprove={() =>
                                handleApproveUser(user._id, true)
                              }
                              onReject={() =>
                                handleApproveUser(user._id, false)
                              }
                              onRemove={() =>
                                handleApproveUser(user._id, false)
                              }
                            />
                          )
                        )}

                        <Separator />
                      </Flex>
                      <Heading level={4} weight="bold">
                        All Users
                      </Heading>
                      <Flex
                        gap={50}
                        direction={"column"}
                        style={{ marginTop: "var(--size-200)" }}
                      >
                        <Paragraph>Users in your organisation</Paragraph>
                        <Flex
                          gap={100}
                          direction={"column"}
                          style={{
                            marginTop: "var(--size-200)",
                          }}
                        >
                          {organisationInfo.users.map((user, index) => (
                            <UserOnHoldItem
                              key={`user-${user._id}-${index}`}
                              user={user}
                              orgId={organisationInfo._id}
                              onApprove={() =>
                                handleApproveUser(user._id, true)
                              }
                              onReject={() =>
                                handleApproveUser(user._id, false)
                              }
                              onRemove={() =>
                                handleApproveUser(user._id, false)
                              }
                            />
                          ))}
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                )}

                {section.id === "dangerZone" && (
                  <Flex
                    customClass={"PageContent BrandManager"}
                    direction={"column"}
                    gap={200}
                    id="dangerZone"
                  >
                    <Heading level={4} weight="bold">
                      Danger Zone
                    </Heading>
                    <Flex gap={400}>
                      <Flex gap={50} direction={"column"}>
                        <Paragraph>Delete account</Paragraph>
                        <Flex
                          gap={200}
                          style={{
                            marginTop: "var(--size-100)",
                            marginBottom: "var(--size-100)",
                          }}
                        >
                          <Button
                            size="medium"
                            variant="danger"
                            onClick={handleCloseAccount}
                          >
                            Close account
                          </Button>
                        </Flex>
                      </Flex>
                      {isUserAdmin && (
                        <Flex gap={50} direction={"column"}>
                          <Paragraph>Delete organisation data</Paragraph>
                          <Flex
                            gap={200}
                            style={{
                              marginTop: "var(--size-100)",
                              marginBottom: "var(--size-100)",
                            }}
                          >
                            <Button
                              variant="danger"
                              onClick={() => setShowDeleteOrgDialog(true)} // Open the alert dialog on click
                            >
                              Delete Data
                            </Button>
                          </Flex>
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                )}
              </div>
            ))}
          </Flex>
        </Flex>
      </Flex>
      <AlertDialogCustom
        title="Close Account"
        description="Are you sure you want to close your account? This action is not reversible."
        cta="Yes, close account"
        onClick={handleDeleteAccount}
        isOpen={showCloseAccountDialog}
        onOpenChange={setShowCloseAccountDialog}
        disabled={
          isUserAdmin && organisationInfo.users.length > 1 && !selectedNewAdmin
        }
      >
        {isUserAdmin &&
          organisationInfo.users.length > 1 &&
          renderUserSelect(
            organisationInfo.users,
            selectedNewAdmin,
            handleSelectNewAdmin
          )}
      </AlertDialogCustom>
    </>
  );
};

export default SettingsPage;
