import "./App.scss";
import React, { useState, useEffect, useCallback } from "react";
import Login from "./login";
import Dashboard from "./dashboard";
import { Helmet } from "react-helmet";
import HomePage from "./pages/HomePage";
import PagePreview from "./pages/PagePreview";
import Community from "./pages/Community";
// import * as ScrollArea from "@radix-ui/react-scroll-area";
import Pricing from "./pages/Pricing";
import About from "./pages/About";
import DashboardPage from "./pages/DashboardPage";
import TemplatesPage from "./pages/TemplatesPage";
import ProjectManagement from "./pages/ProjectManagement";
import ContributionsPage from "./pages/ContributionsPage";
import DocumentationBuilderPage from "./pages/DocumentationBuilderPage";
import APIsPage from "./pages/APIsPage";
import PageHomePreview from "./pages/PageHomePreview";
import { jwtDecode } from "jwt-decode";
// import axios from "axios";
// import NotesContext from "./contexts/NotesContext";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import DesignSystem from "./pages/DesignSystem";
import { Flex } from "blocksin-system";

const App = () => {
  // const [noteId, setNoteId] = useState(null);
  // const handleNoteIdChange = (newId) => {
  //   setNoteId(newId);
  // };
  //
  //
  //
  //
  // const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [loggedInUser, setLoggedInUser] = useState(() => {
    const storedUserData = localStorage.getItem("loggedInUser");
    return storedUserData ? JSON.parse(storedUserData) : null;
  });

  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const storedUserData = localStorage.getItem("loggedInUser");
    return !!storedUserData;
  });
  //
  const [username, setUsername] = useState("");
  //

  // const [currentProject, setCurrentProject] = useState(null);
  const [currentProject, setCurrentProject] = useState("");

  //
  //
  //
  useEffect(() => {
    // Function to check the URL for the 'clear' query parameter
    const checkClearLocalStorage = () => {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.has("clear")) {
        localStorage.clear();
        // alert("Local storage cleared!");
        // Optionally, redirect the user to a specific page after clearing
        // window.location.href = '/';
      }
    };

    checkClearLocalStorage();
  }, []);

  //
  //
  //
  //
  useEffect(() => {
    const storedUserData = localStorage.getItem("loggedInUser");
    if (storedUserData) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleUserLoggedIn = (user) => {
    console.log("User logged in:", user);
    setIsLoggedIn(true);
    setUsername(user.username); // Assuming 'user' has a 'username' property
    setLoggedInUser(user);
  };

  const handleLogin = (loginResponse) => {
    const { user, token } = loginResponse;
    localStorage.setItem("loggedInUser", JSON.stringify(user));
    localStorage.setItem("token", token);
    setIsLoggedIn(true);
    setLoggedInUser(user);
  };

  const handleLogout = useCallback(() => {
    localStorage.removeItem("loggedInUser");
    localStorage.removeItem("token");
    setCurrentProject(null);
    setIsLoggedIn(false);
    setLoggedInUser(null);
  }, []);
  //
  //
  //
  //
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && !isTokenExpired(token)) {
      // Setup a timeout for automatic logout at token expiration time
      const decoded = jwtDecode(token);
      const logoutTimer = setTimeout(() => {
        handleLogout();
      }, decoded.exp * 1000 - Date.now());

      return () => clearTimeout(logoutTimer);
    } else {
      handleLogout();
    }
  }, [handleLogout]);

  const isTokenExpired = (token) => {
    try {
      const decoded = jwtDecode(token);
      return decoded.exp < Date.now() / 1000;
    } catch (error) {
      return true; // If an error occurs, assume the token is invalid
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("logout")) {
      handleLogout();
      window.location.href = "/app"; // Replace '/login' with your desired route
    }
  }, [handleLogout]);

  const handleSubmit = (submittedUsername) => {
    setUsername(submittedUsername);
    setIsLoggedIn(true);
  };

  const [showNewDialog, setShowNewDialog] = useState(false);

  // Handle URL query parameter
  useEffect(() => {
    const handleURLChange = () => {
      const searchParams = new URLSearchParams(window.location.search);
      setShowNewDialog(searchParams.has("new"));
    };

    // Call on initial load and on every URL change
    handleURLChange();
    window.addEventListener("popstate", handleURLChange);
    return () => {
      window.removeEventListener("popstate", handleURLChange);
    };
  }, []);

  // Handle keyboard shortcut
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if Cmd (on Mac) or Ctrl (on Windows/Linux) is pressed along with 'N'
      if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === "n") {
        event.preventDefault();
        setShowNewDialog(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleDialogClose = () => {
    setShowNewDialog(false);
  };

  useEffect(() => {
    const disableContextMenu = (event) => {
      event.preventDefault();
    };

    window.addEventListener("contextmenu", disableContextMenu);
    return () => {
      window.removeEventListener("contextmenu", disableContextMenu);
    };
  }, []);

  //
  //
  //
  return (
    <Router>
      <Helmet>
        <meta charSet="utf-8" />
        <title>BlocksIn App - Design System Management Tool</title>
        <link rel="canonical" href="https://designsystemsapp.netlify.app/" />
        <meta name="viewport" content="width=device-width, user-scalable=no" />
        <meta
          name="description"
          content="BlocksIn App will help your organization assign dev and design ownership."
        />
      </Helmet>
      <Routes>
        <Route path="/" element={<HomePage />} />

        <Route
          path="/login"
          element={
            isLoggedIn ? (
              <Navigate to="/app" />
            ) : (
              <Login
                onLogin={handleLogin}
                onSubmit={handleSubmit}
                onUserLoggedIn={handleUserLoggedIn}
              />
            )
          }
        />

        <Route
          path="/app"
          element={
            isLoggedIn ? (
              <Flex
                direction={"column"}
                align={"start"}
                justify={"start"}
                gap={0}
                id="AppBody"
              >
                {/* <NotesContext.Provider value={{ noteId, setNoteId }}> */}
                <Dashboard
                  username={username}
                  onLogout={handleLogout}
                  loggedInUser={loggedInUser}
                  currentProject={currentProject}
                  setCurrentProject={setCurrentProject}
                  showDialog={showNewDialog}
                  onDialogClose={handleDialogClose}
                  // organisationInfo={organisationInfo}
                  setLoggedInUser={setLoggedInUser}
                  // onNoteIdChange={handleNoteIdChange}
                />
                {/* </NotesContext.Provider> */}
              </Flex>
            ) : (
              <Flex
                direction={"column"}
                align={"start"}
                justify={"start"}
                gap={0}
                id="AppBody"
              >
                <Navigate to="/login" />
              </Flex>
            )
          }
        />
        {/* :projectTitle */}
        <Route
          path=":organization/:id"
          element={
            // <NotesContext.Provider value={{ noteId, setNoteId }}>
            <PagePreview loggedInUser={loggedInUser} />
            // </NotesContext.Provider>
          }
        />

        <Route path=":organization" element={<PageHomePreview />} />

        <Route
          path="privacy-policy"
          element={<PrivacyPolicy loggedInUser={loggedInUser} />}
        />
        <Route path="pricing" element={<Pricing />} />
        <Route path="about" element={<About />} />
        <Route path="dashboard" element={<DashboardPage />} />
        <Route path="project-management" element={<ProjectManagement />} />
        <Route path="contributions" element={<ContributionsPage />} />
        <Route
          path="documentation-builder"
          element={<DocumentationBuilderPage />}
        />
        <Route path="templates" element={<TemplatesPage />} />
        <Route path="apis" element={<APIsPage />} />

        <Route path="community" element={<Community />} />

        <Route path="design-system" element={<DesignSystem />} />
      </Routes>
    </Router>
  );
};

export default App;
