import React, { useState } from "react";
import "./../radix-styles/Dialog.scss";
import * as Dialog from "@radix-ui/react-dialog";
// import Button from "../components/Button";
// import Heading from "../components/Heading";
// import Paragraph from "../components/Paragraph";
// import Flex from "../components/Flex";
// import IconButton from "../components/IconButton";
import { IconButton, Button, Heading, Paragraph, Flex } from "blocksin-system";
import {
  Cross2Icon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from "@radix-ui/react-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Video1 from "../assets/slides/intro/slide1.mp4";
import Video2 from "../assets/slides/intro/slide2.mp4";
import Video3 from "../assets/slides/intro/slide3.mp4";

const DialogWelcome = ({ open, onOpenChange, onUpdate, username }) => {
  const handleInteractOutside = (event) => {
    event.preventDefault();
  };

  const [swiperInstance, setSwiperInstance] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0); // Add state to track the current index

  const nextSlide = () => {
    if (swiperInstance) {
      swiperInstance.slideNext();
    }
  };

  const prevSlide = () => {
    if (swiperInstance) {
      swiperInstance.slidePrev();
    }
  };

  const updateCurrentIndex = () => {
    if (swiperInstance) {
      setCurrentIndex(swiperInstance.realIndex);
    }
  };

  const handleFinish = () => {
    if (typeof onUpdate === "function") {
      onUpdate();
    }
  };

  return (
    <>
      <Dialog.Root
        className="DialogRoot Tutorial"
        open={open}
        onOpenChange={onOpenChange}
        modal
      >
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay Tutorial" />
          <Dialog.Content
            className="DialogContent Inverted Tutorial"
            onInteractOutside={handleInteractOutside}
          >
            <Flex customClass="closeButton darkmode">
              <Dialog.Close asChild>
                <IconButton
                  aria-label="Close"
                  size="small"
                  variant="ghost"
                  onClick={handleFinish}
                >
                  <Cross2Icon />
                </IconButton>
              </Dialog.Close>
            </Flex>

            <Flex style={{ width: "100%", height: "100%" }}>
              <Swiper
                onSwiper={setSwiperInstance}
                slidesPerView={1}
                spaceBetween={0}
                loop={false}
                onSlideChange={updateCurrentIndex}
              >
                <SwiperSlide>
                  <Flex direction="row">
                    <Flex direction="column" gap={200} customClass="sideLeft">
                      <Heading level={3} weight="bold">
                        Hi, {username} ❤️
                      </Heading>
                      <Flex
                        customClass="DialogDescription"
                        direction={"column"}
                      >
                        <Paragraph size="large">
                          Kickstart your journey by creating your first project!
                          Simply click the "Add Library" button to access your
                          project. From there, you can seamlessly connect your
                          design or development resources.
                          <br />
                          <br />
                          Go to the next slide to learn more about Figma
                          widgets!
                        </Paragraph>
                      </Flex>
                    </Flex>

                    <Flex direction="column" gap={200} customClass="sideRight">
                      <video
                        autoPlay
                        loop
                        muted
                        style={{ width: "100%", height: "auto" }}
                      >
                        <source src={Video1} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </Flex>
                  </Flex>
                </SwiperSlide>

                <SwiperSlide>
                  <Flex direction="row">
                    <Flex direction="column" gap={200} customClass="sideLeft">
                      <Heading level={3} weight="bold">
                        Widgets
                      </Heading>
                      <Flex
                        customClass="DialogDescription"
                        direction={"column"}
                      >
                        <Paragraph size="large">
                          Now, you can enhance your project with Github and
                          Figma widgets by adding your personal access token.
                          Just select a widget from the custom widgets box, add
                          your tokens, and enjoy the seamless data flow from
                          popular tools like Figma and Github. Figma widgets
                          also offer activity graphs to visualize collaboration
                          dynamics, and you can easily filter comments and
                          replies.
                          <br />
                          <br />
                          Go to the next slide to learn more about Drag-and-Drop
                          editor!
                        </Paragraph>
                      </Flex>
                    </Flex>

                    <Flex direction="column" gap={200} customClass="sideRight">
                      <video
                        autoPlay
                        loop
                        muted
                        style={{ width: "100%", height: "auto" }}
                      >
                        <source src={Video2} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </Flex>
                  </Flex>
                </SwiperSlide>

                <SwiperSlide>
                  <Flex direction="row">
                    <Flex direction="column" gap={200} customClass="sideLeft">
                      <Heading level={3} weight="bold">
                        Notes and Community
                      </Heading>
                      <Flex
                        customClass="DialogDescription"
                        direction={"column"}
                      >
                        <Paragraph size="large">
                          Use the Notes drag-and-drop editor to quickly create
                          product design documentation. Alternatively, tap into
                          our vibrant community and kickstart your project with
                          design templates created by professional product
                          designers. Publish your pages and effortlessly share
                          them with your team members!
                          <br />
                          <br />
                          Have fun!
                        </Paragraph>
                      </Flex>
                    </Flex>

                    <Flex direction="column" gap={200} customClass="sideRight">
                      <video
                        autoPlay
                        loop
                        muted
                        style={{ width: "100%", height: "auto" }}
                      >
                        <source src={Video3} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </Flex>
                  </Flex>
                </SwiperSlide>
              </Swiper>
            </Flex>

            <Flex
              direction="row"
              gap={100}
              align="end"
              customClass="darkmode navigation"
            >
              {currentIndex > 0 && (
                <IconButton onClick={prevSlide} aria-label="Previous">
                  <ChevronLeftIcon />
                </IconButton>
              )}
              {currentIndex < 2 && (
                <IconButton onClick={nextSlide} aria-label="Next">
                  <ChevronRightIcon />
                </IconButton>
              )}
              {currentIndex === 2 && (
                <Dialog.Close asChild>
                  <Button size="medium" variant="solid" onClick={handleFinish}>
                    Finish
                  </Button>
                </Dialog.Close>
              )}
            </Flex>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

export default DialogWelcome;
