import React, { useState, useEffect, useCallback } from "react";
import { parse } from "papaparse";
import { CopyIcon } from "@radix-ui/react-icons";
import "./ColorTableDisplay.scss";
import { Flex, Paragraph, IconButton, Toast } from "blocksin-system";

const ColorCell = ({ color }) => {
  const [showToast, setShowToast] = useState(false);

  const copyToClipboard = () => {
    // Copy the color value to the clipboard
    navigator.clipboard.writeText(color);

    setShowToast(true);

    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  };

  return (
    <td style={{ backgroundColor: color }}>
      <Flex direction="row" align="center" justify="between">
        <Paragraph size="large">{color}</Paragraph>
        <Flex customClass="copyButton">
          <IconButton size="small" variant="ghost" onClick={copyToClipboard}>
            <CopyIcon />
          </IconButton>
        </Flex>
      </Flex>

      {showToast && (
        <Toast
          showToast={showToast}
          setShowToast={setShowToast}
          text="Color value copied to clipboard!"
          time={3000}
          simple
        />
      )}
    </td>
  );
};

const ColorTableDisplay = ({
  csvData,
  figmaData,
  userCredentials,
  figmaFileId,
}) => {
  const [tableHtml, setTableHtml] = useState("");

  //   const [figmaFileName, setFigmaFileName] = useState("");

  const gatherComponentIds = useCallback((node, componentIds) => {
    if (node.type === "COMPONENT" || node.type === "COMPONENT_SET") {
      componentIds.add(node.id);
    }
    if (node.children) {
      node.children.forEach((child) => {
        gatherComponentIds(child, componentIds);
      });
    }
  }, []);

  const countDetachedInstances = useCallback((node, componentIds) => {
    let count = 0;
    if (node.type === "INSTANCE" && !componentIds.has(node.componentId)) {
      count += 1;
    }
    if (node.children) {
      node.children.forEach((child) => {
        count += countDetachedInstances(child, componentIds);
      });
    }
    return count;
  }, []);

  useEffect(() => {
    if (csvData) {
      fetch(csvData)
        .then((response) => response.text())
        .then((text) => {
          parse(text, {
            complete: (results) => {
              const rows = results.data;
              generateTableFromCSV(rows);
            },
            header: false,
          });
        });
    }
  }, [csvData]);

  //   useEffect(() => {
  //     const fetchFigmaData = async () => {
  //       const userCredentials = JSON.parse(
  //         localStorage.getItem("userCredentials")
  //       );
  //       const currentUser = userCredentials.find((user) => user.isLoggedIn);
  //       const figmaToken = currentUser?.mainFigmaAccessToken;

  //       if (!figmaToken) {
  //         console.error("No Figma token found");
  //         return;
  //       }

  //       try {
  //         const fileResponse = await fetch(
  //           `https://api.figma.com/v1/files/${figmaFileId}`,
  //           {
  //             headers: { "X-Figma-Token": figmaToken },
  //           }
  //         );
  //         const fileData = await fileResponse.json();

  //         if (fileResponse.ok) {
  //           setFigmaFileName(fileData.name);
  //         } else {
  //           throw new Error(`HTTP error! status: ${fileResponse.status}`);
  //         }
  //       } catch (error) {
  //         console.error("Error fetching Figma data:", error);
  //       }
  //     };

  //     fetchFigmaData();
  //   }, [figmaFileId]);

  const generateTableFromCSV = (rows) => {
    let html = (
      <table className="Table colorTable">
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <React.Fragment key={cellIndex}>
                  {rowIndex === 0 ? (
                    <th>
                      <Paragraph size="large">{cell}</Paragraph>
                    </th>
                  ) : cell.startsWith("#") ? (
                    <ColorCell color={cell} />
                  ) : (
                    <td>
                      <Paragraph size="large">{cell}</Paragraph>
                    </td>
                  )}
                </React.Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );

    setTableHtml(html);
  };

  return (
    <Flex direction="column" customClass="ColorTableDisplay">
      <Flex
        customClass="Preview"
        direction="column"
        justify="center"
        align="center"
      >
        {tableHtml}
        {/* <Paragraph>Figma File Name: {figmaFileName}</Paragraph> */}
      </Flex>
    </Flex>
  );
};

export default ColorTableDisplay;
