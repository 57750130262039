import React, { useState, useEffect } from "react";
import "./ComponentCard.scss";
import "./ComponentCardRow.scss";

import PlaceholderImage from "./assets/PlaceholderImage.svg";
import PlaceholderImage2 from "./assets/PlaceholderImage2.svg";
import PlaceholderImage3 from "./assets/PlaceholderImage3.svg";
import PlaceholderImage4 from "./assets/PlaceholderImage4.svg";

import GitHubCommits from "./components/githubcommits";
import { EyeOpenIcon, Pencil2Icon } from "@radix-ui/react-icons";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import AlertDialogCustom from "./AlertDialog";
import {
  Avatar,
  Paragraph,
  Flex,
  Tag,
  IconButton,
  DropdownMenu,
  AvatarGroup,
} from "blocksin-system";

const CardInsideProject = ({
  title,
  id,
  projectId,
  figmaIcons,
  description,
  figmaURL,
  figmaToken,
  colorsURL,
  figmaFileId,
  image,
  owners,
  handleDeleteCard,
  onShowCardBoard,
  setActiveCardInSidebar,
  mainGithubAccessToken,
  githubRepo,
  githubBranch,
  mainGithubUsername,
  onShowNotes,
  // onNoteDisplay,
  username,
  currentUser,
  placeholder,
  openUpdateDialog,
  openFigmaDialog,
  openGithubDialog,
  openMoveToDialog,
  openColorsDialog,
  newDescription,
  toggleViewState,
  allUsers,
}) => {
  const [updatedOwners, setUpdatedOwners] = useState([]);

  const handleEditClick = () => {
    openUpdateDialog(id);
  };

  useEffect(() => {
    setActiveCardInSidebar(id);
  }, [id, setActiveCardInSidebar]);

  const handleEditFigmaClick = () => {
    openFigmaDialog(id);
  };

  const handleEditGithubClick = () => {
    openGithubDialog(id);
  };

  const handleEditMoveToClick = () => {
    openMoveToDialog(id);
  };

  const handleEditColorsClick = () => {
    openColorsDialog(id);
  };

  useEffect(() => {
    if (Array.isArray(owners) && Array.isArray(allUsers)) {
      const newUpdatedOwners = owners
        .map((ownerId) => {
          const ownerDetails = allUsers.find((user) => user._id === ownerId);
          return ownerDetails
            ? {
                id: ownerId,
                avatar: ownerDetails.avatar,
                username: ownerDetails.username,
                role: ownerDetails.role,
              }
            : null;
        })
        .filter((owner) => owner !== null); // Filter out any null values

      setUpdatedOwners(newUpdatedOwners);
    }
  }, [owners, allUsers]);

  const [showAlertDeleteCardDialog, setShowAlertDeleteCardDialog] =
    useState(false);

  const getRandomPlaceholder = () => {
    const placeholders = [
      PlaceholderImage,
      PlaceholderImage2,
      PlaceholderImage3,
      PlaceholderImage4,
    ];
    const randomIndex = Math.floor(Math.random() * placeholders.length);
    return placeholders[randomIndex];
  };

  // Set avatar to a random demo if not provided
  const placeholderSrc = placeholder || getRandomPlaceholder();

  const handleEyeOpenClick = () => {
    onShowCardBoard({
      title,
      id,
      description,
      newDescription,
      projectId,
      figmaIcons,
      figmaURL,
      figmaToken,
      figmaFileId,
      colorsURL,
      image,
      owners,
      githubRepo,
      githubBranch,
      mainGithubUsername,
      mainGithubAccessToken,
    });
    setActiveCardInSidebar(id); // Update active card in sidebar
  };

  useEffect(() => {
    setActiveCardInSidebar(id);
  }, [id, setActiveCardInSidebar]);

  const handleShowNotesClick = () => {
    // onNoteDisplay(id)
    onShowNotes(id);
    localStorage.removeItem("gjsProject");
  };

  //
  //
  //
  const getTagLabel = () => {
    if (figmaURL && colorsURL) {
      return "Figma & Colors";
    } else if (figmaURL && figmaToken) {
      return "Icons";
    } else if (figmaURL) {
      return "Figma";
    } else if (colorsURL) {
      return "Colors";
    } else {
      return "Card";
    }
  };

  const TagLabel = getTagLabel();

  return (
    <>
      {toggleViewState === "Grid" ? (
        <div className="ComponentCard">
          <AlertDialogCustom
            title="Delete Card"
            description={"Are you sure you want to delete card?"}
            cta="Yes, delete"
            onClick={() => handleDeleteCard(id, projectId)} // Updated prop
            isOpen={showAlertDeleteCardDialog}
            onOpenChange={setShowAlertDeleteCardDialog}
          />

          <Flex customClass={"actionButtons"} direction={"row"} gap={100}>
            <IconButton size="medium" onClick={handleShowNotesClick}>
              <Pencil2Icon />
            </IconButton>
            <IconButton size="medium" onClick={handleEyeOpenClick}>
              <EyeOpenIcon />
            </IconButton>
          </Flex>
          <Flex customClass={"actionButtonsRight"} direction={"row"} gap={100}>
            <DropdownMenu modal={false}>
              <DropdownMenu.Trigger asChild>
                <IconButton size="medium">
                  <DotsHorizontalIcon />
                </IconButton>
              </DropdownMenu.Trigger>

              <DropdownMenu.Content sideOffset={4} align="end">
                <DropdownMenu.Item onClick={handleEditClick}>
                  Update details
                </DropdownMenu.Item>
                {!colorsURL && (
                  <DropdownMenu.Item onClick={handleEditFigmaClick}>
                    Add Figma file
                  </DropdownMenu.Item>
                )}
                {!figmaURL && (
                  <DropdownMenu.Item onClick={handleEditColorsClick}>
                    Add Color Library
                  </DropdownMenu.Item>
                )}
                <DropdownMenu.Item onClick={handleEditGithubClick}>
                  Add Github Branch
                </DropdownMenu.Item>
                <DropdownMenu.Item onClick={handleEditMoveToClick}>
                  Move to
                </DropdownMenu.Item>
                <DropdownMenu.Item
                  onClick={() => setShowAlertDeleteCardDialog(true)}
                >
                  Delete
                </DropdownMenu.Item>
              </DropdownMenu.Content>
            </DropdownMenu>
          </Flex>
          <Flex direction={"column"} customClass={"Thumbnail"}>
            <Tag text={TagLabel} variant="Demo" />
            {image && <img src={image} alt={title} />}
            {!image && <img src={placeholderSrc} alt={title} />}
          </Flex>
          <Flex
            direction={"column"}
            align="start"
            justify="start"
            style={{ padding: "var(--size-200)", width: "100%" }}
          >
            <Paragraph size="large" weight="bold">
              {title}
            </Paragraph>
            {description && (
              <Paragraph
                size="large"
                weight="regular"
                customClass="description"
              >
                {description}
              </Paragraph>
            )}
            {/* <IframeComponent url={figmaURL} title="Figma Design" /> */}
            <GitHubCommits
              currentUser={currentUser}
              mainGithubUsername={mainGithubUsername}
              mainGithubAccessToken={mainGithubAccessToken}
              githubRepo={githubRepo}
              githubBranch={githubBranch}
            />
          </Flex>
          <Flex customClass={"CardFooter"}>
            <AvatarGroup>
              {updatedOwners.map((owner, index) => (
                <Avatar
                  key={index}
                  avatar={owner.avatar}
                  altText={owner.username}
                  role={Array.isArray(owner.role) ? owner.role.join(", ") : ""}
                />
              ))}
            </AvatarGroup>
          </Flex>
        </div>
      ) : (
        <div className="ComponentCardRow">
          <AlertDialogCustom
            title="Delete Card"
            description={"Are you sure you want to delete card?"}
            cta="Yes, delete"
            onClick={() => handleDeleteCard(id)} // Updated prop
            isOpen={showAlertDeleteCardDialog}
            onOpenChange={setShowAlertDeleteCardDialog}
          />
          <Flex
            gap={200}
            direction={"column"}
            style={{ padding: "var(--size-200)", width: "100%" }}
          >
            <Flex direction={"column"} align="start" justify="start">
              <Paragraph size="large" weight="bold">
                {title}
              </Paragraph>
              {description && (
                <Paragraph
                  size="large"
                  weight="regular"
                  customClass="description"
                >
                  {description}
                </Paragraph>
              )}
            </Flex>

            <Flex customClass={"actionButtons"} direction={"row"} gap={100}>
              <IconButton size="medium" onClick={handleEyeOpenClick}>
                <EyeOpenIcon />
              </IconButton>
              <IconButton size="medium" onClick={handleShowNotesClick}>
                <Pencil2Icon />
              </IconButton>
              <DropdownMenu modal={false}>
                <DropdownMenu.Trigger asChild>
                  <IconButton size="medium">
                    <DotsHorizontalIcon />
                  </IconButton>
                </DropdownMenu.Trigger>

                <DropdownMenu.Content sideOffset={4} align="end">
                  <DropdownMenu.Item onClick={handleEditClick}>
                    Update details
                  </DropdownMenu.Item>
                  <DropdownMenu.Item onClick={handleEditFigmaClick}>
                    Add Figma file
                  </DropdownMenu.Item>
                  <DropdownMenu.Item onClick={handleEditColorsClick}>
                    Add Color Library
                  </DropdownMenu.Item>
                  <DropdownMenu.Item onClick={handleEditGithubClick}>
                    Add Github Branch
                  </DropdownMenu.Item>
                  <DropdownMenu.Item onClick={handleEditMoveToClick}>
                    Move to
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    onClick={() => setShowAlertDeleteCardDialog(true)}
                  >
                    Delete
                  </DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu>
            </Flex>
          </Flex>
          <Flex direction={"column"} customClass={"Thumbnail"}>
            <Tag text={TagLabel} variant="Demo" />

            {image && <img src={image} alt={title} />}
            {!image && <img src={placeholderSrc} alt={title} />}
          </Flex>
          <Flex customClass={"CardFooter"}>
            <AvatarGroup>
              {updatedOwners.map((owner, index) => (
                <Avatar
                  key={index}
                  avatar={owner.avatar}
                  altText={owner.username}
                  role={Array.isArray(owner.role) ? owner.role.join(", ") : ""}
                />
              ))}
            </AvatarGroup>
          </Flex>
        </div>
      )}
    </>
  );
};

export default CardInsideProject;
