import React, { useEffect, useState } from "react";
// import { Cross2Icon } from "@radix-ui/react-icons";
import GitHubCommitsThree from "./components/githubcommits-three";
// import PlaceholderImage from "./assets/PlaceholderImage.svg";
import "./ComponentCardBoard.scss";
import ColorTableDisplay from "./ColorTableDisplay";
import {
  Avatar,
  Heading,
  Paragraph,
  Flex,
  Iframe,
  Button,
  Loader,
  IconButton,
  Toast,
  AvatarGroup,
} from "blocksin-system";
import { FigmaLogoIcon, CopyIcon } from "@radix-ui/react-icons";

const generateComponentCode = (icon) => {
  // Simple parser to transform SVG string into JSX
  const svgToJSX = (svgString) => {
    return svgString
      .replace(/<svg([^>]+)>/, `<svg$1>`)
      .replace(/class=/g, "className=")
      .replace(/clip-rule=/g, "clipRule=")
      .replace(/fill-rule=/g, "fillRule=");
    // Add more replacements as needed
  };

  const jsxSvg = svgToJSX(icon.content);

  return `
    export const ${icon.iconName} = () => (
      ${jsxSvg}
    );
  `;
};

// Function to download the file
const downloadFile = (content, filename, contentType) => {
  const a = document.createElement("a");
  const file = new Blob([content], { type: contentType });
  a.href = URL.createObjectURL(file);
  a.download = filename;
  a.click();
};

const ComponentCardBoard = ({
  onBackToProject,
  figmaURL,
  figmaToken,
  colorsURL,
  description,
  githubBranch,
  title,
  projectId,
  id,
  owners,
  image,
  githubRepo,
  mainGithubUsername,
  currentUser,
  mainGithubAccessToken,
  username,
  figmaFileId,
  allUsers,
  figmaIcons,
}) => {
  const [updatedOwners, setUpdatedOwners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [icons, setIcons] = useState([]);
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  //
  //
  //
  const handleDownloadClick = () => {
    let fileContent = "import React from 'react';\n\n";
    // icons.forEach((icon) => {
    //   fileContent += generateComponentCode(icon) + "\n";
    // });
    icons.forEach((icon) => {
      if (icon.iconName) {
        // Check if iconName exists and is not empty
        fileContent += generateComponentCode(icon) + "\n";
      }
    });

    downloadFile(fileContent, "Icons.js", "text/javascript");
  };
  //
  //
  //
  //
  //
  //
  //
  //
  // Example function to call the new route and update figmaIcons
  const updateFigmaIcons = async (icons) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token is missing or sub-project title is empty.");
      return;
    }

    try {
      const response = await fetch(
        `${serverUrl}/api/projects/projects/${projectId}/sub-projects/${id}/figma-icons`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ icons }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        console.log("Figma icons updated successfully", data);
        // Update your component state with the updated icons
        setIcons(data.figmaIcons);
        setIsLoading(false);
      } else {
        console.error("Failed to update Figma icons:", data.message);
      }
    } catch (error) {
      console.error("Error updating Figma icons:", error);
    }
  };

  //
  //
  //
  //
  //
  //
  const detectAndReplaceColors = (svgContent, colorMap) => {
    let updatedContent = svgContent;
    Object.keys(colorMap).forEach((color, index) => {
      const variableName = `color-${index + 1}`; // e.g., color-1
      updatedContent = updatedContent.replace(
        new RegExp(color, "g"),
        variableName
      );
      colorMap[color] = variableName; // Update color map with variable names
    });
    return updatedContent;
  };
  //
  const colorMap = {};
  //
  //
  //
  const fetchIconsFromFigma = async () => {
    const fileKeyMatch = figmaURL.match(/design\/([a-zA-Z0-9]+)\//);
    const nodeIdMatch = figmaURL.match(/node-id=([0-9]+)-([0-9]+)/);

    if (!fileKeyMatch || !nodeIdMatch) {
      console.error("Invalid Figma URL");
      return;
    }

    const fileKey = fileKeyMatch[1];
    const figmaFrameId = `${nodeIdMatch[1]}:${nodeIdMatch[2]}`;
    setIsLoading(true);

    try {
      // Fetch the frame node to get children nodes' IDs
      const frameResponse = await fetch(
        `https://api.figma.com/v1/files/${fileKey}/nodes?ids=${figmaFrameId}`,
        {
          headers: { "X-Figma-Token": figmaToken },
        }
      );

      const frameData = await frameResponse.json();
      const children = frameData.nodes[figmaFrameId].document.children;
      const nodeIds = children.map((child) => child.id).join(",");

      // Fetch SVGs for all node IDs in one request
      const imagesResponse = await fetch(
        `https://api.figma.com/v1/images/${fileKey}?ids=${nodeIds}&format=svg`,
        {
          headers: { "X-Figma-Token": figmaToken },
        }
      );

      const imagesData = await imagesResponse.json();
      const imageUrls = imagesData.images;

      // Map URLs to SVG content and format names
      const fetchedSVGs = await Promise.all(
        Object.keys(imageUrls).map(async (id) => {
          const svgResponse = await fetch(imageUrls[id]);
          let svgContent = await svgResponse.text();
          const child = children.find((child) => child.id === id);

          // Inject icon-name attribute directly if not using generateComponentCode for direct display
          svgContent = svgContent.replace(
            /<svg([^>]+)>/,
            `<svg$1 icon-name="${child.name.replace(/\s+/g, "") + "Icon"}">`
          );

          // Detect and replace colors in SVG content as before
          svgContent = detectAndReplaceColors(svgContent, colorMap);

          const formattedName = child.name.replace(/\s+/g, "") + "Icon";
          return { content: svgContent, name: formattedName };
        })
      );

      updateFigmaIcons(fetchedSVGs.map((svg) => svg.content));
      setIcons(fetchedSVGs);
    } catch (error) {
      console.error("Error fetching icons from Figma:", error);
    } finally {
      // setIsLoading(false);
    }
  };
  //
  //
  //
  //
  useEffect(() => {
    const newUpdatedOwners = owners
      .map((ownerId) => {
        const ownerDetails = allUsers.find((user) => user._id === ownerId);
        return ownerDetails
          ? {
              id: ownerId,
              avatar: ownerDetails.avatar,
              username: ownerDetails.username,
              role: ownerDetails.role,
            }
          : null;
      })
      .filter((owner) => owner !== null); // Filter out any null values

    setUpdatedOwners(newUpdatedOwners);

    if (figmaIcons && figmaIcons.length > 0) {
      setIcons(figmaIcons);
      // If figmaIcons data is available, use it to set the icons state
      // setIcons(figmaIcons.map((icon) => ({ content: icon, name: "" }))); // Assuming figmaIcons are just the SVG strings. Adjust if the structure is different
    } else {
      // If no figmaIcons data is available, fetch icons from Figma
      // fetchIconsFromFigma();
    }
  }, [owners, allUsers, figmaIcons]);
  //
  //
  //
  //
  const [hasIcons, setHasIcons] = useState("");
  useEffect(() => {
    if (figmaToken && figmaURL) {
      setHasIcons(true);
    }
  }, [figmaToken, figmaURL, hasIcons]);

  //
  //
  //
  // Assume you have a function to fetch icons data from your backend
  const fetchIconsData = async () => {
    const token = localStorage.getItem("token");
    if (!token || !projectId || !id) {
      console.error("Missing token or project/sub-project ID.");
      return;
    }

    try {
      const response = await fetch(
        `${serverUrl}/api/projects/projects/${projectId}/sub-projects/${id}/figma-icons`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) throw new Error("Failed to fetch icons data");

      const data = await response.json();
      // Assume the data structure matches what your component expects
      setIcons(data.figmaIcons);
    } catch (error) {
      console.error("Error fetching icons data:", error);
    }
  };

  useEffect(() => {
    // Call fetchIconsData when the component mounts
    fetchIconsData();
    // eslint-disable-next-line
  }, [projectId, id]); // Re-fetch whenever projectId or id changes
  //
  const [showToastCopied, setShowToastCopied] = useState(false);

  return (
    <>
      <Toast
        showToast={showToastCopied}
        setShowToast={setShowToastCopied}
        text="Copied to clipboard 🚀"
        time={2000}
        simple
      />
      <Flex direction={"column"} customClass={"ComponentCardBoard"} gap={0}>
        <Flex gap={100} align={"center"} justify={"between"}>
          <Heading level={3} weight="bold">
            {title}
          </Heading>
          {/* <Button size="medium" onClick={onBackToProject}>
            Go back to project
          </Button> */}
          <AvatarGroup>
            {updatedOwners.map((owner, index) => (
              <Avatar
                key={index}
                avatar={owner.avatar}
                altText={owner.username}
                role={Array.isArray(owner.role) ? owner.role.join(", ") : ""}
              />
            ))}

            {
              !updatedOwners.map((owner, index) => (
                <Avatar
                  key={index}
                  avatar={owner.avatar}
                  altText={owner.username}
                  role={Array.isArray(owner.role) ? owner.role.join(", ") : ""}
                />
              ))
            }
          </AvatarGroup>
        </Flex>
        <Flex customClass="description">
          <Paragraph size="large" weight="regular" customClass="description">
            {description}
          </Paragraph>
        </Flex>
        {/* <Flex direction={"column"} customClass={"Thumbnail"}>
          {image && <img src={image} alt={title} />}
          {!image && <img src={PlaceholderImage} alt={title} />}
        </Flex> */}
        {!figmaToken && (
          <Flex gap={200} align={"center"} customClass={"Figma"}>
            <Iframe url={figmaURL} title="Figma Design" />
          </Flex>
        )}
        {githubBranch && (
          <>
            <Paragraph size="large" weight="bold" customClass="description">
              Latest code change in the component
            </Paragraph>
            <GitHubCommitsThree
              githubBranch={githubBranch}
              username={username}
              currentUser={currentUser}
            />
          </>
        )}
        {colorsURL && colorsURL.startsWith("https") && (
          <Flex
            direction="column"
            gap={100}
            style={{ marginTop: "var(--size-400)" }}
          >
            <Heading level={4} weight="bold">
              Colors imported from Google Sheets
            </Heading>

            <ColorTableDisplay
              csvData={colorsURL}
              figmaData={figmaURL}
              figmaFileId={figmaFileId}
            />
          </Flex>
        )}
        {hasIcons && (
          <>
            <Flex
              direction="row"
              justify="end"
              fluid
              gap={100}
              style={{ marginTop: "var(--size-400)" }}
            >
              <Button onClick={fetchIconsFromFigma}>
                <FigmaLogoIcon />
                Fetch Assets
              </Button>
              <Button onClick={handleDownloadClick}>Download React</Button>
            </Flex>
            <Flex
              direction="row"
              justify="start"
              align="start"
              gap={100}
              customClass="IconDisplay"
            >
              {isLoading && <Loader />}
              <Paragraph>Preview</Paragraph>
              {icons.map((icon, index) => (
                <div key={index} className="IconBox">
                  <div dangerouslySetInnerHTML={{ __html: icon.content }} />
                  <span>{icon.iconName}</span> {/* Adjusted to use iconName */}
                  <IconButton
                    variant="ghost"
                    size="medium"
                    onClick={() => {
                      navigator.clipboard.writeText(icon.content);
                      setShowToastCopied(true);
                      // Optionally, you can also reset the toast visibility after a delay
                      setTimeout(() => setShowToastCopied(false), 2000); // Hide toast after 2 seconds
                    }}
                  >
                    <CopyIcon />
                  </IconButton>
                </div>
              ))}
            </Flex>
          </>
        )}
      </Flex>
    </>
  );
};

export default ComponentCardBoard;
