import React, { useEffect, useState, useRef, useCallback } from "react";
import MenubarDemo from "./menubar";
import ProjectsDashboard from "./ProjectsDashboard";
import Project from "./Project";
import "./_Dashboard.scss";
import DialogWelcome from "./dialogs/DialogWelcome";
import SettingsPage from "./SettingsPage";
import Updates from "./Updates";
import {
  PlusIcon,
  ChevronRightIcon,
  // Component1Icon,
} from "@radix-ui/react-icons";
import LeftSideBar from "./LeftSideBar";
import DialogGithubWidget from "./dialogs/DialogGithubWidget";
import DialogFigmaWidget from "./dialogs/DialogFigmaWidget";
import ComponentCardBoard from "./ComponentCardBoard";
import Notes from "./components/Notes";
import ScoreBoard from "./ScoreBoard";
import FigmaFileDetails from "./components/FigmaFileDetails";
import Notifications from "./components/notifications.json";
// import DialogHowToFigmaWidget from "./dialogs/DialogHowToFigmaWidget";
import axios from "axios";
import { FigmaToReactIcon } from "./components/BlocksInIcons";
import {
  Button,
  Loader,
  Separator,
  TopBanner,
  Heading,
  Flex,
  Paragraph,
  DropdownMenu,
  Tabs,
} from "blocksin-system";
import Lottie from "lottie-react";
import animationData from "./assets/confetti.json";
import { findAllByTestId } from "@testing-library/react";
// import AvatarPlaceholder from "./assets/AvatarDemo2.svg";

const Dashboard = ({
  // username,
  onLogout,
  currentProject,
  setCurrentProject,
  // organisation,
  showDialog,
  onDialogClose,
  loggedInUser,
  setLoggedInUser,
  // onNoteIdChange,
}) => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  // const userId = loggedInUser?._id;
  const [userIsApproved, setUserIsApproved] = useState(null);
  //
  //
  //
  //
  const [levelUpAnimation, setLevelUpAnimation] = useState(false);
  const prevBadgeLevel = useRef();
  const [badgeLevel, setBadgeLevel] = useState("1");
  const [userPoints, setUserPoints] = useState("1");

  //
  //
  //
  //
  const [showWelcomeDialog, setShowWelcomeDialog] = useState(false);
  const [showSettings, setShowSettings] = useState(false); // Add this line
  const [showScoreBoard, setShowScoreBoard] = useState(false); // Add this line
  const [currentUserAvatar, setCurrentUserAvatar] = useState("");
  const [currentUserId, setCurrentUserId] = useState("");

  const [showNotes, setShowNotes] = useState(false);

  const [toggleViewState, setToggleViewState] = useState("Grid");
  //
  //
  //
  //
  const [projectData, setProjectData] = useState({ title: "" });
  const [nestedCards, setNestedCards] = useState(projectData.cards || []);

  useEffect(() => {
    // Update nestedCards when projectData changes
    setNestedCards(projectData.cards || []);
  }, [projectData]);
  //
  //
  //
  //
  //
  //
  //  fetching data about user
  //
  const fetchUserData = useCallback(async () => {
    const token = localStorage.getItem("token");
    try {
      const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
      const userId = loggedInUser?._id;

      if (userId) {
        const response = await axios.get(
          `${serverUrl}/api/users/users/${userId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setCurrentUser(response.data);

        //
        //
        //
        // Set user approval status for current organization
        const isApprovedInCurrentOrg =
          response.data.approvedInOrganisation.some(
            (org) =>
              org.organisationId === loggedInUser.organisation && org.isApproved
          );
        setUserIsApproved(isApprovedInCurrentOrg);
        // Set the badge level and store the previous badge level in localStorage
        const prevBadge = localStorage.getItem("prevBadgeLevel") || "0";
        prevBadgeLevel.current = parseInt(prevBadge);
        setBadgeLevel(response.data.badge);
        setUserPoints(response.data.points);
        localStorage.setItem("prevBadgeLevel", response.data.badge);
        //
        //
        //
        setMainFigmaFileId(response.data.widgetFigmaFileId);
        setMainFigmaAccessToken(response.data.widgetFigmaAccessToken);
        // Set welcome dialog visibility based on fetched user data
        // const hasOrganizationOrRole =
        //   response.data.organisation || response.data.role.length > 0;
        // setShowWelcomeDialog(!hasOrganizationOrRole);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
    const isAdmin = loggedInUser?.role?.includes("Admin");
    setIsUserAdmin(isAdmin);
  }, [serverUrl, loggedInUser?.role]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  //
  //
  //
  //
  //
  // Add a new function to refresh user data
  const refreshUserData = () => {
    fetchUserData();
  };
  //
  //
  //
  //
  // console.log(currentUser.username);

  useEffect(() => {
    const currentBadgeLevel = parseInt(badgeLevel);

    // Check if the badge level has increased
    if (prevBadgeLevel.current < currentBadgeLevel) {
      setLevelUpAnimation(true);
      // Reset animation after it has played
      setTimeout(() => setLevelUpAnimation(false), 3000); // Adjust time based on animation length
    }

    // Update the ref to the current badge level for the next render
    prevBadgeLevel.current = currentBadgeLevel;
  }, [badgeLevel]);
  //
  //
  //
  //
  //
  const [isLoading, setIsLoading] = useState(false);
  const [organisationInfo, setOrganisationInfo] = useState({
    name: "",
    userCount: 0,
    logo: "",
    users: [],
    pendingApprovals: [],
  });

  const [selectedOrgProjects, setSelectedOrgProjects] = useState([]);
  const [userOrganisationProjects, setUserOrganisationProjects] = useState([]);
  const updateUserOrganisationProjects = useCallback(() => {
    if (organisationInfo.projects && loggedInUser) {
      setUserOrganisationProjects(organisationInfo.projects);
    }
  }, [organisationInfo.projects, loggedInUser]);

  useEffect(() => {
    updateUserOrganisationProjects();
  }, [updateUserOrganisationProjects]);
  //
  //
  //
  //
  //
  const [organisationNotifications, setOrganisationNotifications] = useState(
    []
  );
  //
  //
  //
  //
  //
  //
  // Function to fetch organisations and notifications
  const fetchOrganisation = useCallback(async () => {
    setIsLoading(true);
    if (!loggedInUser || !loggedInUser.organisation) {
      console.error("No logged-in user or organisation ID found.");
      setIsLoading(false);
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token is missing for authenticated request.");
      setIsLoading(false);
      return;
    }

    // Before the try block in your fetchOrganisation function

    // Define isUserAdmin here, inside the function, to ensure it uses the latest state
    const isUserAdmin = loggedInUser?.role?.includes("Admin");
    const isApprovedInCurrentOrg = currentUser?.approvedInOrganisation?.some(
      (org) =>
        org.organisationId === loggedInUser.organisation && org.isApproved
    ); //
    // console.log("Logged in user: ", loggedInUser);
    // console.log("Is user admin: ", isUserAdmin);
    //
    console.log(
      "isUserAdmin:",
      isUserAdmin,
      "isApprovedInCurrentOrg:",
      isApprovedInCurrentOrg
    );

    // Check if the user is approved or an admin before fetching organization data
    if (!isApprovedInCurrentOrg) {
      console.error("User is neither approved nor an admin.");
      setIsLoading(false);
      setOrganisationInfo({});
      setSelectedOrgProjects([]);
      return;
    }

    try {
      const response = await axios.get(
        `${serverUrl}/organisations/${loggedInUser.organisation}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const orgData = response.data;

      // console.log(orgData);
      const allMembers = [...orgData.users, ...orgData.removedUsers];

      const notificationsWithAvatar = orgData.notifications.map(
        (notification) => {
          const authorId = notification.author;
          const author = allMembers.find((user) => user._id === authorId) || {};
          return {
            ...notification,
            author: {
              _id: author._id || "Unknown User",
              avatar: author.avatar,
              username: author.username || "Unknown",
            },
          };
        }
      );

      setOrganisationInfo({
        _id: orgData._id,
        name: orgData.name,
        userCount: orgData.users.length,
        logo: orgData.logo,
        figmaAccessToken: orgData.figmaAccessToken,
        figmaFileId: orgData.figmaFileId,
        users: orgData.users,
        removedUsers: orgData.removedUsers,
        pendingApprovals: orgData.pendingApprovals,
      });

      setUserOrganisationProjects(orgData.projects);
      setOrganisationNotifications(notificationsWithAvatar);

      setSelectedOrgProjects(orgData.projects);
    } catch (error) {
      console.error("Error fetching organisation data:", error);
    }

    setIsLoading(false);
  }, [loggedInUser, serverUrl, currentUser?.approvedInOrganisation]);

  //
  //
  //
  // useEffect to call fetchOrganisation when loggedInUser is updated
  useEffect(() => {
    if (loggedInUser) {
      fetchOrganisation();
    }
  }, [fetchOrganisation, loggedInUser]);

  //
  //
  //
  //
  //
  //
  const refreshOrgData = () => {
    fetchOrganisation();
  };
  //
  //
  //
  //
  // const fetchCombinedData = useCallback(async () => {
  //   setIsLoading(true);
  //   const token = localStorage.getItem("token");
  //   const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

  //   if (!loggedInUser || !loggedInUser.organisation || !loggedInUser._id) {
  //     console.error("Logged-in user data is incomplete.");
  //     setIsLoading(false);
  //     return;
  //   }

  //   try {
  //     const response = await axios.get(
  //       `${serverUrl}/api/combinedData/${loggedInUser.organisation}/${loggedInUser._id}`,
  //       { headers: { Authorization: `Bearer ${token}` } }
  //     );

  //     const { user, organisation } = response.data;

  //     setCurrentUser(user);
  //     const isApprovedInCurrentOrg = user.approvedInOrganisation.some(
  //       (org) =>
  //         org.organisationId === loggedInUser.organisation && org.isApproved
  //     );
  //     setUserIsApproved(isApprovedInCurrentOrg);
  //     setBadgeLevel(user.badge);
  //     setUserPoints(user.points);
  //     const isAdmin = user.role.includes("Admin");
  //     setIsUserAdmin(isAdmin);

  //     const notificationsWithAvatar = organisation.notifications.map(
  //       (notification) => {
  //         const author =
  //           organisation.users.find(
  //             (user) => user._id === notification.author._id
  //           ) || {};
  //         return {
  //           ...notification,
  //           author: {
  //             _id: author._id || "Unknown User",
  //             avatar: author.avatar,
  //             username: author.username || "Unknown",
  //           },
  //         };
  //       }
  //     );

  //     setOrganisationInfo({
  //       _id: organisation._id,
  //       name: organisation.name,
  //       userCount: organisation.users.length,
  //       logo: organisation.logo,
  //       users: organisation.users,
  //       removedUsers: organisation.removedUsers,
  //       pendingApprovals: organisation.pendingApprovals,
  //     });
  //     setUserOrganisationProjects(organisation.projects);
  //     setOrganisationNotifications(notificationsWithAvatar);
  //   } catch (error) {
  //     console.error("Error fetching combined data:", error);
  //   }
  //   setIsLoading(false);
  // }, [serverUrl]);
  //

  //
  //
  //
  //
  //
  useEffect(() => {
    const storedToggleViewState = localStorage.getItem(
      `${currentUser?.username}_toggleViewState`
    );
    if (storedToggleViewState) {
      setToggleViewState(storedToggleViewState);
    }
  }, [currentUser?.username]);

  useEffect(() => {
    localStorage.setItem(
      `${currentUser?.username}_toggleViewState`,
      toggleViewState
    );
  }, [toggleViewState, currentUser?.username]);

  const showDashboard = () => {
    setCurrentProject(null);

    setShowSettings(false);
    setShowScoreBoard(false);
    setShowNotes(false);
    setShowCardBoard(false);
  };

  const showSettingsPage = () => {
    setShowSettings(true);
    setShowScoreBoard(false);
    setShowNotes(false);
  };

  const showScoreBoardPage = () => {
    setShowSettings(false); // Hide settings page
    setShowNotes(false); // Hide notes
    setShowScoreBoard(true); // Show score board
  };

  useEffect(() => {
    // Check and set the avatar if it exists
    if (loggedInUser && loggedInUser.avatar) {
      setCurrentUserAvatar(loggedInUser.avatar);
    }
    if (loggedInUser && loggedInUser.id) {
      setCurrentUserId(loggedInUser.id);
    }

    localStorage.setItem("currentProject", JSON.stringify(currentProject));
  }, [
    currentUser?.username,
    currentProject,
    currentUser?.organisation,
    loggedInUser,
  ]);

  const handleViewProject = (projectData) => {
    if (!projectData || !projectData.title) {
      console.error("Invalid project data:", projectData);
      return;
    }
    setActiveCard(null);

    setCurrentProject(projectData);
  };

  const [mainGithubUsername, setMainGithubUsername] = useState();
  const [githubRepo, setGithubRepo] = useState();
  const [mainGithubAccessToken, setMainGithubAccessToken] = useState();
  //
  const [mainFigmaAccessToken, setMainFigmaAccessToken] = useState();
  const [mainFigmaFileId, setMainFigmaFileId] = useState();

  //
  //
  // backup
  // const handleUpdateProfile = (updatedUserData) => {
  //   // Close the welcome dialog if the user now has an organisation
  //   if (updatedUserData.organisation) {
  //     refreshUserData();
  //     setShowWelcomeDialog(false);
  //   }
  // };
  //
  //

  useEffect(() => {
    const username = currentUser?.username;
    if (username) {
      // Ensure username is not undefined or null
      const dialogShownKey = `${username}_DialogWelcomeShown`;
      const hasShownDialog = localStorage.getItem(dialogShownKey);

      if (!hasShownDialog || hasShownDialog === "false") {
        setShowWelcomeDialog(true);
      } else {
        setShowWelcomeDialog(false);
      }
    }
  }, [currentUser?.username]);

  const handleCloseDialogWelcome = () => {
    const username = currentUser?.username; // Replace with actual username logic
    localStorage.setItem(`${username}_DialogWelcomeShown`, "true");
    setShowWelcomeDialog(false);
  };

  //
  //
  //
  const handleSaveGithubUsername = (updatedData) => {
    // Update local component state
    setMainGithubAccessToken(updatedData.mainGithubAccessToken);

    setMainGithubUsername(updatedData.widgetGithubUsername);
    setGithubRepo(updatedData.widgetGithubRepo);

    setIsGithubDialogOpen(false);
  };

  //

  // const handleSaveFigmaFileId = (data) => {
  //   // Update local component state
  //   setMainFigmaFileId(data.mainFigmaFileId);
  //   setMainFigmaAccessToken(data.mainFigmaAccessToken);

  //   setIsFigmaDialogOpen(false);
  // };

  const handleSaveFigmaIntegration = async (updatedData) => {
    const { mainFigmaFileId, mainFigmaAccessToken, mainFigmaUserId } =
      updatedData;

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token is missing.");
      return;
    }

    try {
      await axios.patch(
        `${serverUrl}/api/users/users/${loggedInUser._id}/figma`,
        {
          widgetFigmaFileId: mainFigmaFileId,
          widgetFigmaAccessToken: mainFigmaAccessToken,
          widgetFigmaUserId: mainFigmaUserId,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setMainFigmaFileId(mainFigmaFileId);
      setMainFigmaAccessToken(mainFigmaAccessToken);
      // await refreshUserData();

      // Update local state or perform any other actions after successful update
      console.log("Figma integration updated successfully");
    } catch (error) {
      console.error("Error updating Figma integration:", error);
    }

    // Refresh user data to reflect changes
    refreshUserData();
  };

  //

  const [isSidebarVisible, setIsSidebarVisible] = useState(
    localStorage.getItem("sidebarVisible") === "true"
  );

  useEffect(() => {
    localStorage.setItem("sidebarVisible", isSidebarVisible);
  }, [isSidebarVisible]);

  useEffect(() => {
    // Update local storage when currentProject changes
    localStorage.setItem("currentProject", JSON.stringify(currentProject));
  }, [currentProject]);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const [isGithubDialogOpen, setIsGithubDialogOpen] = useState(false);
  const [isFigmaDialogOpen, setIsFigmaDialogOpen] = useState(false);

  //
  //
  // const [tokenExistsInDB, setTokenExistsInDB] = useState(false);
  // useEffect(() => {
  //   const fetchUserData = async () => {
  //     try {
  //       const response = await fetch(`${serverUrl}/api/users/users/${userId}`, {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       });
  //       const userData = await response.json();
  //       if (userData.widgetGithubAccessToken) {
  //         setTokenExistsInDB(true);
  //       } else {
  //         setTokenExistsInDB(false);
  //       }
  //       // Set other user details as well
  //       setMainGithubUsername(userData.widgetGithubUsername || "");
  //       setGithubRepo(userData.widgetGithubRepo || "");
  //     } catch (error) {
  //       console.error("Error fetching user data:", error);
  //     }
  //   };
  // }, [serverUrl, userId]);
  //
  //
  const handleAddGithubIntegration = () => {
    fetchUserData();

    setIsGithubDialogOpen(true);
  };
  //
  //
  //
  //
  //
  const handleAddFigmaIntegration = () => {
    setIsFigmaDialogOpen(true); // This opens the Figma Dialog
  };

  const [showCardBoard, setShowCardBoard] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const toggleCardBoard = () => {
    setShowCardBoard(!showCardBoard);
  };

  const handleBackToTable = () => {
    setCurrentProject(null);
    setShowNotes(false);
    setActiveCard(null);
  };

  const onBackToProject = () => {
    setShowCardBoard(false);
    setShowNotes(false);
    setActiveCard(null);
  };

  //
  //
  //
  //
  //
  const handleCardSelect = (selectedCard, currentProject) => {
    // Ensure the image property is named consistently
    const updatedSelectedCard = {
      ...selectedCard,
      projectFileImage: selectedCard.projectFileImage || selectedCard.image,
    };

    setSelectedCard(updatedSelectedCard);

    // Update currentProject state when a card is selected
    if (currentProject) {
      setCurrentProject(currentProject);
    }

    setShowCardBoard(true);
  };

  //
  //
  //
  //
  //

  const leftSideBarRef = useRef(null);
  const [activeCard, setActiveCard] = useState(null);

  const setActiveCardInSidebar = (cardId) => {
    // if (leftSideBarRef.current) {
    //   leftSideBarRef.current.setActiveCard(cardId);
    // }
  };

  // Function to save GrapesJS content
  // const saveGrapesContent = (grapesContent) => {};

  const updateCurrentProjectCards = (updatedCard) => {
    if (!currentProject || !updatedCard || !Array.isArray(currentProject.cards))
      return;

    const isCardExists = currentProject.cards.some(
      (card) => card.title === updatedCard.title
    );

    const updatedCards = isCardExists
      ? currentProject.cards.map((card) =>
          card.title === updatedCard.title ? updatedCard : card
        )
      : [...currentProject.cards, updatedCard]; // Add the new card to the array if it doesn't exist

    setCurrentProject((prevProject) => ({
      ...prevProject,
      cards: updatedCards,
    }));
  };

  const handleCardUpdate = (updatedCard) => {
    updateCurrentProjectCards(updatedCard);
  };
  //
  //
  //
  //
  //
  const handleFigmaUrlUpdate = async (cardId, newFigmaURL) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token is missing.");
      return;
    }

    const dataToSend = {
      figmaURL: newFigmaURL, // Assuming 'newFigmaURL' is the updated Figma URL
    };

    try {
      const response = await axios.patch(
        `${serverUrl}/api/projects/projects/${projectData._id}/sub-projects/${cardId}/figmaURL`,
        dataToSend,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        await fetchOrganisation();

        setNestedCards((prevCards) =>
          prevCards.map((card) =>
            card._id === cardId ? { ...card, figmaURL: newFigmaURL } : card
          )
        );

        // Update local state or perform any other actions after successful update
        console.log("Figma URL updated successfully", response.data);
        // You can update the 'figmaURL' state if needed
      } else {
        console.error("Failed to update Figma URL:", response.data);
      }
    } catch (error) {
      console.error("Error updating Figma URL:", error);
    }
  };

  //
  //
  //
  //
  //
  //

  //
  //
  //
  //
  //
  //
  const handleSaveCardDescription = async (cardId, updatedCardData) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token is missing.");
      return;
    }

    const dataToSend = {
      title: updatedCardData.title,
      projectFileImage: updatedCardData.projectFileImage, // Assuming 'image' contains the URL or path of the image
      description: updatedCardData.description, // Ensure this is a string
      owners: updatedCardData.owners, // Ensure this is an array of user IDs
    };

    try {
      const response = await axios.patch(
        `${serverUrl}/api/projects/projects/${projectData._id}/sub-projects/${cardId}`,
        dataToSend,

        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        // Update local state or perform any other actions after successful update
        // console.log("Sub-project updated successfully", response.data);
        // Update nestedCards state to reflect changes
        await fetchOrganisation();

        setNestedCards((prevCards) =>
          prevCards.map((card) =>
            card._id === cardId ? { ...card, ...updatedCardData } : card
          )
        );

        handleCardUpdate(cardId, updatedCardData);
        //
      } else {
        console.error("Failed to update sub-project:", response.data);
      }
    } catch (error) {
      console.error("Error updating sub-project:", error);
    }
  };
  //
  //
  //
  //
  //
  const [isDialogColorsOpen, setIsDialogColorsOpen] = useState(false);

  const handleSaveCardColorsURL = async (
    cardId,
    newColorsURL,
    newFigmaFileId
  ) => {
    if (!newColorsURL && !newFigmaFileId) {
      console.error("No URL or Figma File ID provided");
      return;
    }

    // You might want to include logic to validate or process newColorsURL here

    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `${serverUrl}/api/projects/projects/${projectData._id}/sub-projects/${cardId}/colorsURL`,
        { colorsURL: newColorsURL },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        await fetchOrganisation();

        setNestedCards((prevCards) =>
          prevCards.map((card) =>
            card._id === cardId ? { ...card, colorsURL: newColorsURL } : card
          )
        );

        // Update local state or perform any other actions after successful update
        console.log("Colors URL updated successfully", response.data);
        // You can update the 'colorsURL' state if needed
      } else {
        console.error("Failed to update Colors URL:", response.data);
      }
    } catch (error) {
      console.error("Error updating Colors URL:", error);
    }

    setIsDialogColorsOpen(false);
  };

  //
  //
  //
  //
  //
  const handleDeleteCard = async (subProjectId, projectId) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token is missing.");
      return;
    }

    try {
      await axios.delete(
        `${serverUrl}/api/projects/projects/${projectId}/sub-projects/${subProjectId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      fetchOrganisation(); // Refresh data
      setNestedCards((prevCards) =>
        prevCards.filter((card) => card._id !== subProjectId)
      );
    } catch (error) {
      console.error("Error deleting sub-project:", error);
    }
  };
  //
  //
  //
  //
  //

  const [moveToProjectId, setMoveToProjectId] = useState({});

  // Function to handle moving sub-project to another project
  // In Dashboard component
  const handleSaveCardMoveTo = async (subProjectId, currentProjectId) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token is missing.");
      return;
    }

    console.log("Selected Project ID:", moveToProjectId);

    // Ensure you're extracting the newProjectId correctly
    const newProjectId = moveToProjectId;
    if (!newProjectId || typeof newProjectId !== "string") {
      console.error("New project ID is not selected or invalid.");
      console.error({ moveToProjectId });
      return;
    }

    // Logging for debugging
    console.log("Moving sub-project", {
      subProjectId,
      currentProjectId,
      newProjectId,
    });

    try {
      await axios.post(
        `${serverUrl}/api/projects/move-sub-project`,
        { subProjectId, newProjectId, currentProjectId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchOrganisation(); // Refresh data

      // Remove the moved card from nestedCards
      const updatedNestedCards = nestedCards.filter(
        (card) => card._id !== subProjectId
      );
      setNestedCards(updatedNestedCards);
    } catch (error) {
      console.error("Error moving sub-project:", error);
    }
  };

  //
  //
  //
  //
  //
  const [isDialogGithubOpen, setIsDialogGithubOpen] = useState(false);

  const handleGithubBranchUpdate = async (subProjectId, newGithubBranch) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token is missing.");
      return;
    }

    try {
      const response = await axios.patch(
        `${serverUrl}/api/projects/projects/${currentProject._id}/sub-projects/${subProjectId}/githubBranch`,
        { githubBranch: newGithubBranch },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        console.log("GitHub branch updated successfully", response.data);

        // Refetch organisation data
        await fetchOrganisation();

        // Update nestedCards state
        setNestedCards((prevCards) =>
          prevCards.map((card) =>
            card._id === subProjectId
              ? { ...card, githubBranch: newGithubBranch }
              : card
          )
        );
        setIsDialogGithubOpen(false);
      } else {
        console.error("Failed to update GitHub branch:", response.data);
      }
    } catch (error) {
      console.error("Error updating GitHub branch:", error);
    }
  };

  const handleNoteDisplay = (card) => {
    if (!card) {
      console.error("Card data is missing");
      return;
    }
    console.log("Organisation Info:", selectedOrgProjects);

    // Find the project associated with the selected card
    const associatedProject = selectedOrgProjects.find((project) =>
      project.projectFiles.some((projectFile) => projectFile._id === card._id)
    );

    if (!associatedProject) {
      console.error("Project not found for the selected card");
      return;
    }

    setCurrentProject(associatedProject);
    setSelectedCard(card);

    // Display the Notes component
    setShowNotes(true);
    setShowCardBoard(false); // Hide the card board if it's visible
  };

  const handleShowNotes = (cardId) => {
    if (!cardId) {
      console.error("Card ID is missing");
      return;
    }

    // Find the project associated with the selected card using selectedOrgProjects
    const associatedProject = selectedOrgProjects.find((project) =>
      project.projectFiles.some((projectFile) => projectFile._id === cardId)
    );

    if (associatedProject) {
      // Find the card within the associated project
      const card = associatedProject.projectFiles.find(
        (projectFile) => projectFile._id === cardId
      );

      if (!card) {
        console.error("Card not found in the selected project");
        return;
      }

      setSelectedCard(card);
      setShowNotes(true);
    } else {
      console.error("Project not found for the selected card");
    }
  };
  //
  //
  //
  // eslint-disable-next-line no-unused-vars
  const [projects, setProjects] = useState([]);

  // Callback function to update projects/cards
  const handleProjectsUpdate = (updatedProjects) => {
    setProjects(updatedProjects);
  };
  //
  //
  //
  const [userCommentsCount, setUserCommentsCount] = useState(0);
  const [userOrgCommentsCount, setUserOrgCommentsCount] = useState(0);

  // const [userEditsCount, setUserEditsCount] = useState(0);

  useEffect(() => {
    const username = currentUser?.username;
    const figmaFileId = currentUser?.widgetFigmaFileId;
    const orgFigmaFileId = organisationInfo?.figmaFileId;
    const userFigmaId = currentUser?.widgetFigmaUserId;

    if (username && figmaFileId) {
      const storageKey = `figmaFile_${figmaFileId}_${username}`;
      const storedData = JSON.parse(localStorage.getItem(storageKey));

      if (storedData && userFigmaId) {
        const userComments = storedData.comments.filter(
          (comment) => comment.user.id === userFigmaId
        );
        setUserCommentsCount(userComments.length);

        // const userEdits = storedData.versions.filter(
        //   (version) => version.user.id === userFigmaId
        // );
        // setUserEditsCount(userEdits.length);
      }
    }
    if (username && orgFigmaFileId) {
      const storageKey = `figmaFile_${orgFigmaFileId}_${username}`;
      const storedData = JSON.parse(localStorage.getItem(storageKey));

      if (storedData && userFigmaId) {
        const userComments = storedData.comments.filter(
          (comment) => comment.user.id === userFigmaId
        );
        setUserOrgCommentsCount(userComments.length);

        // const userEdits = storedData.versions.filter(
        //   (version) => version.user.id === userFigmaId
        // );
        // setUserEditsCount(userEdits.length);
      }
    }
  }, [currentUser, organisationInfo]);
  //
  //
  //
  //
  //
  const [currentNotification, setCurrentNotification] = useState(null);
  const username = currentUser?.username;

  useEffect(() => {
    const undismissedNotification = Notifications.find((notification) => {
      const notificationKey = `NotificationShown_${notification.id}_${username}`;
      return !localStorage.getItem(notificationKey);
    });

    setCurrentNotification(undismissedNotification);
  }, [username]);

  const dismissNotification = () => {
    if (currentNotification) {
      const notificationKey = `NotificationShown_${currentNotification.id}_${username}`;
      localStorage.setItem(notificationKey, "dismissed");
      setCurrentNotification(null);
    }
  };
  //
  //
  //
  //
  //
  const handleAddCardIcons = () => {
    setIsDialogIconsVisible(true);
  };

  const [isDialogIconsVisible, setIsDialogIconsVisible] = useState(false);

  return (
    <>
      {levelUpAnimation && (
        <Flex customClass="levelUpScreen" align="center" justify="center">
          <Lottie
            className="lottie"
            animationData={animationData}
            loop={findAllByTestId}
            autoplay={true}
            rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
          />
          <span>Level Up {badgeLevel}</span>
        </Flex>
      )}
      {showScoreBoard ? (
        <>
          <Flex
            align="center"
            direction="column"
            customClass={"MenubarContainer"}
          >
            <MenubarDemo
              onLogout={onLogout}
              onShowDashboard={showDashboard}
              onShowSettings={showSettingsPage}
              onToggleSidebar={toggleSidebar}
              showSettings={showSettings}
              showScoreBoard={showScoreBoard}
              onShowScoreBoard={showScoreBoardPage}
              username={currentUser?.username}
              currentUserAvatar={currentUserAvatar}
              currentUserId={currentUserId}
              currentUser={currentUser}
              organisationNotifications={organisationNotifications}
              setOrganisationNotifications={setOrganisationNotifications}
            />
          </Flex>
          <ScoreBoard
            username={currentUser?.username}
            currentNotification={currentNotification}
            dismissNotification={dismissNotification}
            currentUser={currentUser}
          />
        </>
      ) : showSettings ? (
        <>
          <Flex
            align="center"
            direction="column"
            customClass={"MenubarContainer"}
          >
            <MenubarDemo
              onLogout={onLogout}
              onShowDashboard={showDashboard}
              onShowSettings={showSettingsPage}
              onToggleSidebar={toggleSidebar}
              showSettings={showSettings}
              showScoreBoard={showScoreBoard}
              onShowScoreBoard={showScoreBoardPage}
              username={currentUser?.username}
              currentUserAvatar={currentUser?.avatar}
              currentUserId={currentUser?._id}
              currentUser={currentUser}
              organisationNotifications={organisationNotifications}
              setOrganisationNotifications={setOrganisationNotifications}
            />
          </Flex>
          <SettingsPage
            username={currentUser?.username}
            organisation={currentUser?.organisation?.name}
            organisationInfo={organisationInfo}
            setLoggedInUser={setLoggedInUser}
            loggedInUser={loggedInUser}
            currentUser={currentUser}
            isUserAdmin={isUserAdmin}
            refreshUserData={refreshUserData}
            refreshOrgData={refreshOrgData}
            setOrganisationInfo={setOrganisationInfo}
            onLogout={onLogout}
            currentNotification={currentNotification}
            dismissNotification={dismissNotification}
          />
        </>
      ) : (
        <Flex
          id="DashboardContainer"
          customClass={isSidebarVisible ? "LeftSideBar-visible" : ""}
          align={"stretch"}
        >
          <Flex
            align="center"
            direction="column"
            customClass={
              isSidebarVisible
                ? "LeftSideBar-visible MenubarContainer"
                : "MenubarContainer"
            }
          >
            <MenubarDemo
              onLogout={onLogout}
              onShowDashboard={showDashboard}
              onShowSettings={showSettingsPage}
              onToggleSidebar={toggleSidebar}
              showScoreBoard={showScoreBoard}
              onShowScoreBoard={showScoreBoardPage}
              username={currentUser?.username}
              currentUserAvatar={currentUser?.avatar}
              currentUserId={currentUser?._id}
              currentUser={currentUser}
              organisationNotifications={organisationNotifications}
              setOrganisationNotifications={setOrganisationNotifications}
            />
          </Flex>

          <LeftSideBar
            ref={leftSideBarRef}
            onCardSelect={handleCardSelect}
            onNoteDisplay={handleNoteDisplay}
            onShowNotes={handleShowNotes}
            projects={selectedOrgProjects}
            organisation={currentUser?.organisation?.name}
            organisationInfo={organisationInfo}
            activeCard={activeCard}
            setActiveCard={setActiveCard}
          />

          <Flex direction={"column"} id="Dashboard" align={"center"}>
            {showWelcomeDialog && (
              <DialogWelcome
                onUpdate={handleCloseDialogWelcome}
                open={showWelcomeDialog}
                onOpenChange={setShowWelcomeDialog}
                username={currentUser?.username}
                refreshUserData={refreshUserData}
              />
            )}

            {isGithubDialogOpen && (
              <DialogGithubWidget
                open={isGithubDialogOpen}
                onOpenChange={setIsGithubDialogOpen}
                onUpdate={handleSaveGithubUsername}
                //
                currentMainGithubUsername={mainGithubUsername}
                currentGithubRepo={githubRepo}
                currentMainGithubAccessToken={mainGithubAccessToken}
                //
                username={currentUser?.username}
                // mainGithubAccessToken={mainGithubAccessToken}
                currentUser={currentUser}
                refreshUserData={refreshUserData}
                //
                githubTokenExists={!!currentUser.widgetGithubAccessToken} // Assuming this is the correct field
                // mainGithubUsername={currentUser.widgetGithubUsername}
                // githubRepo={currentUser.widgetGithubRepo}
                // setMainGithubUsername={setMainGithubUsername}
                // setGithubRepo={setGithubRepo}
              />
            )}

            {isFigmaDialogOpen && (
              <DialogFigmaWidget
                open={isFigmaDialogOpen}
                onOpenChange={setIsFigmaDialogOpen}
                // onUpdate={handleSaveFigmaFileId}
                onUpdate={handleSaveFigmaIntegration}
                currentMainFigmaFileId={mainFigmaFileId}
                currentMainFigmaAccessToken={mainFigmaAccessToken}
                username={currentUser?.username}
                mainFigmaAccessToken={mainFigmaAccessToken}
                refreshUserData={refreshUserData}
              />
            )}

            {/* {isHowToFigmaWidgetDialogOpen && (
              <DialogHowToFigmaWidget
                open={isHowToFigmaWidgetDialogOpen}
                onOpenChange={openHowToFigmaWidgetDialog}
              />
            )} */}
            {currentNotification && (
              <TopBanner
                username={currentUser?.username}
                notification={currentNotification}
                onDismiss={dismissNotification}
              />
            )}
            {!currentProject && !showCardBoard && (
              <Flex customClass={"Widgets"}>
                <Updates
                  mainGithubUsername={mainGithubUsername}
                  mainGithubAccessToken={mainGithubAccessToken}
                  githubRepo={githubRepo}
                  currentUser={currentUser}
                />
                <Flex customClass={"Widget"} direction={"column"}>
                  <Paragraph size="large" weight="bold">
                    Custom widgets
                  </Paragraph>
                  <DropdownMenu modal={false}>
                    <DropdownMenu.Trigger asChild>
                      <Button size="medium" variant="outline">
                        <PlusIcon />
                        Add integration
                      </Button>
                    </DropdownMenu.Trigger>

                    <DropdownMenu.Content sideOffset={4} align="end">
                      <DropdownMenu.Item onClick={handleAddGithubIntegration}>
                        GitHub
                      </DropdownMenu.Item>
                      <DropdownMenu.Item onClick={handleAddFigmaIntegration}>
                        Figma
                      </DropdownMenu.Item>
                    </DropdownMenu.Content>
                  </DropdownMenu>
                </Flex>
              </Flex>
            )}

            <Flex direction={"column"} gap={200} customClass="PageBody">
              {!currentProject && !showCardBoard && (
                <Flex direction={"column"} gap={200} customClass="PageContent">
                  <Flex direction={"column"}>
                    <Heading level={4} weight="bold">
                      Dashboard
                    </Heading>
                    <Separator />
                    <Heading level={3} weight="bold">
                      Welcome, {currentUser?.username}! 🥳
                    </Heading>
                  </Flex>
                  {/* <Paragraph size="large">This is your dashboard.</Paragraph> */}
                  <Flex
                    direction="column"
                    gap={100}
                    style={{ marginTop: "var(--size-200)" }}
                  >
                    <Heading level={3} weight="bold">
                      Your Facts
                    </Heading>
                    <Flex gap={200}>
                      <Flex
                        direction="column"
                        gap={100}
                        customClass="factsCard"
                      >
                        <Paragraph size="large" weight="bold">
                          Your grades
                        </Paragraph>
                        <Separator />
                        <Paragraph size="large">Points: {userPoints}</Paragraph>
                        <Paragraph size="large">Level: {badgeLevel}</Paragraph>
                      </Flex>
                      <Flex
                        direction="column"
                        gap={100}
                        customClass="factsCard"
                      >
                        <Paragraph size="large" weight="bold">
                          Projects
                        </Paragraph>
                        <Separator />
                        <Paragraph size="large">
                          {currentUser?.projects.length} project(s).
                        </Paragraph>
                        <Paragraph size="large">
                          {currentUser?.projectFiles.length} component card(s).
                        </Paragraph>
                      </Flex>
                      <Flex
                        direction="column"
                        gap={100}
                        customClass="factsCard"
                      >
                        <Paragraph size="large" weight="bold">
                          Comments in Figma
                        </Paragraph>
                        <Separator />
                        <Paragraph size="large" weight="bold">
                          In Organisation
                        </Paragraph>
                        <Paragraph size="large">
                          You made {userOrgCommentsCount} comment(s).
                        </Paragraph>
                        <Separator />
                        <Paragraph size="large" weight="bold">
                          In Personal
                        </Paragraph>
                        <Paragraph size="large">
                          You made {userCommentsCount} comment(s).
                        </Paragraph>
                      </Flex>

                      {/* <Paragraph size="large">Edits: {userEditsCount}</Paragraph> */}
                    </Flex>
                  </Flex>
                  {!isUserAdmin && !userIsApproved && (
                    <Paragraph size="large">
                      Please wait for the admin of the organisation to approve
                      your request.
                    </Paragraph>
                  )}
                </Flex>
              )}
              {!currentProject && !showCardBoard && (
                <Tabs
                  defaultValue="tab1"
                  style={{
                    overflow: "hidden",
                    borderRadius: "var(--size-100)",
                    boxShadow: "var(--shadow-level-1)",
                  }}
                >
                  <Tabs.List aria-label="Manage your account">
                    <Tabs.Trigger value="tab1">
                      Organisation Widgets
                    </Tabs.Trigger>
                    <Tabs.Trigger value="tab2">Personal Widgets</Tabs.Trigger>
                  </Tabs.List>
                  <Tabs.Content value="tab1">
                    {organisationInfo.figmaFileId &&
                      !currentProject &&
                      !showCardBoard && (
                        <Flex
                          direction={"column"}
                          gap={200}
                          customClass="PageContent"
                        >
                          <FigmaFileDetails
                            figmaFileId={organisationInfo.figmaFileId}
                            username={currentUser?.username}
                            currentUser={currentUser}
                            figmaAccessToken={organisationInfo.figmaAccessToken}
                          />
                        </Flex>
                      )}
                  </Tabs.Content>
                  <Tabs.Content value="tab2">
                    {mainFigmaFileId && !currentProject && !showCardBoard && (
                      <Flex
                        direction={"column"}
                        gap={200}
                        customClass="PageContent"
                      >
                        <FigmaFileDetails
                          figmaFileId={currentUser?.widgetFigmaFileId}
                          figmaAccessToken={currentUser?.widgetFigmaAccessToken}
                          username={currentUser?.username}
                          currentUser={currentUser}
                        />
                      </Flex>
                    )}
                  </Tabs.Content>
                </Tabs>
              )}
              {showCardBoard && (
                <Flex direction={"column"} gap={200} customClass="PageContent">
                  <Paragraph size="large" weight="bold">
                    {currentProject?.title} <ChevronRightIcon />
                    {selectedCard?.title}
                  </Paragraph>

                  <Button onClick={onBackToProject}>Go back to project</Button>
                </Flex>
              )}

              {showNotes && !showCardBoard && (
                <Flex direction={"column"} gap={200} customClass="PageContent">
                  <Paragraph size="large" weight="bold">
                    {currentProject?.title} <ChevronRightIcon />
                    {selectedCard?.title}
                  </Paragraph>
                  <Button onClick={onBackToProject}>Go back to project</Button>
                </Flex>
              )}
              {isLoading && <Loader />}

              {userIsApproved &&
                currentProject &&
                !showCardBoard &&
                !showNotes && (
                  <Flex
                    direction={"column"}
                    customClass={`PageContent ${
                      showNotes ? "paddingReduced" : ""
                    }`}
                  >
                    <Heading level={4} weight="bold">
                      Quick actions
                    </Heading>
                    <Flex gap={200} customClass="QuickActions">
                      <Button
                        size="large"
                        variant="outline"
                        onClick={handleAddCardIcons}
                        showBadge
                        badgeLabel="New"
                        helper="Sync Figma with React"
                        iconLeft={FigmaToReactIcon}
                      >
                        Add Icons
                      </Button>
                    </Flex>
                  </Flex>
                )}

              {userIsApproved && (
                <Flex
                  direction={"column"}
                  customClass={`PageContent ${
                    showNotes ? "paddingReduced" : ""
                  }`}
                >
                  {currentProject && !showCardBoard && !showNotes ? (
                    <Project
                      onCardUpdate={handleSaveCardDescription}
                      projectData={currentProject}
                      username={currentUser?.username}
                      onBack={handleBackToTable}
                      onShowCardBoard={handleCardSelect}
                      setActiveCardInSidebar={setActiveCardInSidebar}
                      onNoteDisplay={handleNoteDisplay}
                      onShowNotes={handleShowNotes}
                      githubBranch={selectedCard?.githubBranch}
                      toggleViewState={toggleViewState}
                      setToggleViewState={setToggleViewState}
                      figmaURL={selectedCard?.figmaURL}
                      figmaToken={selectedCard?.figmaToken}
                      handleCardUpdate={handleCardUpdate}
                      onUpdateFigmaURL={handleFigmaUrlUpdate}
                      onDeleteCard={handleDeleteCard}
                      onUpdateGithubBranch={handleGithubBranchUpdate}
                      onUpdateColors={handleSaveCardColorsURL}
                      onMoveTo={handleSaveCardMoveTo}
                      currentUser={currentUser}
                      colorsURL={selectedCard?.colorsURL}
                      figmaFileId={selectedCard?.figmaFileId}
                      //
                      fetchOrganisation={fetchOrganisation}
                      refreshOrgData={refreshOrgData}
                      organisationInfo={organisationInfo}
                      userOrganisationProjects={userOrganisationProjects}
                      allUsers={organisationInfo.users}
                      nestedCards={nestedCards}
                      setNestedCards={setNestedCards}
                      isDialogColorsOpen={isDialogColorsOpen}
                      setIsDialogColorsOpen={setIsDialogColorsOpen}
                      moveToProjectId={moveToProjectId}
                      setMoveToProjectId={setMoveToProjectId}
                      handleSaveCardMoveTo={handleSaveCardMoveTo}
                      setIsDialogGithubOpen={setIsDialogGithubOpen}
                      isDialogGithubOpen={isDialogGithubOpen}
                      id={selectedCard?._id || ""}
                      // figmaIcons={selectedCard?.figmaIcons}
                      isDialogIconsVisible={isDialogIconsVisible}
                      setIsDialogIconsVisible={setIsDialogIconsVisible}
                    />
                  ) : showCardBoard ? (
                    <ComponentCardBoard
                      onBackToProject={toggleCardBoard}
                      projectId={currentProject._id}
                      title={selectedCard?.title}
                      id={selectedCard?.id || ""}
                      description={selectedCard?.description}
                      figmaURL={selectedCard?.figmaURL}
                      figmaToken={selectedCard?.figmaToken}
                      githubUsername={selectedCard?.githubUsername}
                      image={selectedCard?.projectFileImage}
                      owners={selectedCard?.owners}
                      githubBranch={selectedCard?.githubBranch}
                      currentUser={currentUser}
                      figmaIcons={selectedCard?.figmaIcons}
                      // githubRepo={selectedCard.githubRepo}
                      // ownersData={selectedCard.ownersData}
                      colorsURL={selectedCard?.colorsURL}
                      allUsers={organisationInfo.users}
                    />
                  ) : showNotes ? (
                    <Notes
                      // onSave={saveGrapesContent}
                      title={selectedCard?.title || ""}
                      id={selectedCard?._id || ""}
                      currentUser={currentUser}
                      selectedOrgProjects={selectedOrgProjects}
                      projectTitle={currentProject?.title}
                      projectId={currentProject?._id}

                      // onIdChange={onNoteIdChange}
                    />
                  ) : (
                    <ProjectsDashboard
                      username={currentUser?.username}
                      onViewProject={handleViewProject}
                      showDialog={showDialog}
                      onDialogClose={onDialogClose}
                      onProjectsUpdate={handleProjectsUpdate}
                      organisationInfo={organisationInfo}
                      fetchOrganisation={fetchOrganisation}
                      // fetchCombinedData={fetchCombinedData}
                      setProjectData={setProjectData}
                      projectData={projectData}
                      selectedOrgProjects={selectedOrgProjects}
                      setSelectedOrgProjects={setSelectedOrgProjects}
                      // setProjects={setProjects}
                    />
                  )}
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default Dashboard;
