import React from "react";
import PlaceholderImage from "./assets/PlaceholderImage.svg";
import "./TemplateCard.scss";
import { Button, Flex, Paragraph } from "blocksin-system";

const TemplateCard = ({ template, handlePreview, featured }) => {
  const className = `templateCard ${featured ? "templateCardFeatured" : ""}`;
  const authorUsername =
    template && template.author ? template.author.username : "Summer Break 🌴";

  return (
    <Flex customClass={className} direction="column">
      <Flex customClass={"templateImg"}>
        <img src={template.image || PlaceholderImage} alt={template.title} />
      </Flex>
      <Flex direction={"column"}>
        <Paragraph size="large" weight="bold">
          {template.title}
        </Paragraph>
        <Paragraph size="medium">{authorUsername}</Paragraph>
      </Flex>

      <Button
        size="medium"
        variant="outline"
        onClick={() => handlePreview(template)}
      >
        Preview
      </Button>
    </Flex>
  );
};

export default TemplateCard;
