import React, { useState } from "react";
import "./login.scss";
import AppLogoWhite from "./assets/AppLogo2-White.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import {
  Separator,
  Button,
  Flex,
  Input,
  Paragraph,
  Checkbox,
  Tabs,
  Select,
} from "blocksin-system";

const Login = ({ onLogin, onUserLoggedIn }) => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  // const [username, setUsername] = useState("");
  // const [password, setPassword] = useState("");
  // const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [privacyError, setPrivacyError] = useState("");
  //
  const [organisation, setOrganisation] = useState("");
  const [role, setRole] = useState("");
  //
  const [errorMessage, setErrorMessage] = useState("");
  // const [currentStep, setCurrentStep] = useState(1);

  const handleChange = (e) => {
    console.log(e.target.name, e.target.value);
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const [userData, setUserData] = useState({
    username: "",
    email: "",
    password: "",
    isApproved: false,
    points: "1",
  });

  // const handleNextStep = () => {
  //   // Add validation logic for step 1 fields if needed
  //   setCurrentStep(2);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(""); // Reset the error message
    setError(""); // Reset the previous error state

    if (userData.username.length < 4 || userData.password.length < 4) {
      setError("Username and password must be at least 4 characters long.");
      return;
    }

    if (!privacyAccepted) {
      setPrivacyError(
        "You must accept the privacy policy to create an account."
      );
      return;
    }

    // Add validation for organisation and role
    if (!organisation.trim()) {
      setError("Organisation cannot be empty.");
      return;
    }
    if (!role.trim()) {
      setError("Role cannot be empty.");
      return;
    }

    const updatedUserData = {
      ...userData,
      organisationName: organisation,
      role,
    };
    setIsLoading(true);
    try {
      // Sign-up request
      await axios.post(`${serverUrl}/api/users/users`, updatedUserData);

      // Attempt to log in after successful sign-up
      const loginResponse = await axios.post(`${serverUrl}/api/users/login`, {
        email: userData.email,
        password: userData.password,
      });

      localStorage.setItem("token", loginResponse.data.token);
      localStorage.setItem(
        "loggedInUser",
        JSON.stringify(loginResponse.data.user)
      );

      // Invoke the callback to update the parent component's state
      if (onUserLoggedIn && loginResponse.data.user) {
        onUserLoggedIn(loginResponse.data.user);
      }
      navigate("/app");
    } catch (error) {
      const errorMsg =
        error.response?.data?.message ||
        "An unexpected error occurred. Please try again.";
      setError(errorMsg);
    } finally {
      setIsLoading(false); // Set loading to false after API call is complete
    }
  };

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const handleChangeLogin = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
    setError(""); // Reset error on input change
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${serverUrl}/api/users/login`,
        loginData
      );
      onLogin(response.data);
      navigate("/app");
    } catch (error) {
      setError("Login failed. Please check your username and password.");
      console.error("Error logging in:", error);
    } finally {
      setIsLoading(false); // Set loading to false after API call is complete
    }
  };
  return (
    <>
      <Flex
        direction={"column"}
        align={"center"}
        justify={"center"}
        gap={100}
        id="LoginContainer"
      >
        <Tabs defaultValue="tab1">
          <Tabs.List aria-label="Manage your account">
            <Tabs.Trigger value="tab1">Sign Up</Tabs.Trigger>
            <Tabs.Trigger value="tab2">Sign In</Tabs.Trigger>
          </Tabs.List>
          <Tabs.Content value="tab1">
            <Paragraph size="large" weight="bold">
              Create new account
            </Paragraph>
            {/* {currentStep === 1 && ( */}
            <Flex id="signupStep1" direction={"column"}>
              <fieldset>
                <Input
                  label="Username"
                  name="username"
                  placeholder="Enter your username"
                  errormsg={false}
                  type="text"
                  value={userData.username}
                  onChange={handleChange}
                />
                <Input
                  name="email"
                  label="Email"
                  placeholder="Enter your email"
                  errormsg={false}
                  type="email"
                  value={userData.email}
                  onChange={handleChange}
                />
                <Input
                  name="password"
                  label="Password"
                  placeholder="Enter your password"
                  errormsg={false}
                  type="password"
                  value={userData.password}
                  onChange={handleChange}
                />
                {/* </fieldset>
                <Button
                  size="large"
                  variant="solid"
                  fluid
                  onClick={handleNextStep}
                >
                  Next step
                </Button>
              </Flex>
            )}
            {currentStep === 2 && (
              <Flex id="signupStep2" direction={"column"}>
                <fieldset> */}
                <Flex direction="column" gap={100}>
                  <Separator />
                  <Paragraph>
                    Create new or join existing organisation
                  </Paragraph>
                </Flex>
                <Input
                  label="Organisation"
                  placeholder="Enter name of your organisation"
                  type="text"
                  value={organisation}
                  onChange={(e) => setOrganisation(e.target.value)}
                />

                <Select value={role} onValueChange={setRole}>
                  <Select.Trigger aria-label="Role">
                    <Select.Value placeholder="Select a role" />
                  </Select.Trigger>
                  <Select.Content side="bottom" sideOffset={0} align="start">
                    <Select.Viewport>
                      <Select.Item value="Design">Product Designer</Select.Item>
                      <Select.Item value="Developer">Developer</Select.Item>
                      <Select.Item value="PM">PM</Select.Item>
                      <Select.Item value="Brand">Brand Designer</Select.Item>
                    </Select.Viewport>
                    <Select.ScrollDownButton className="SelectScrollButton">
                      <ChevronDownIcon />
                    </Select.ScrollDownButton>
                  </Select.Content>
                </Select>
              </fieldset>
              <Checkbox
                customID={"PPTerms"}
                checked={privacyAccepted}
                onChange={(newCheckedValue) => {
                  setPrivacyAccepted(newCheckedValue); // newCheckedValue is a boolean
                  setPrivacyError(""); // Reset privacy error when checkbox state changes
                }}
              >
                <Paragraph size="medium">
                  Accept <a href="/privacy-policy">terms and conditions</a>
                </Paragraph>
              </Checkbox>
              {/* <Button
                  size="large"
                  variant="solid"
                  fluid
                  onClick={handlePrevStep}
                >
                  Go back
                </Button> */}
              <Button
                size="large"
                variant="solid"
                disabled={isLoading}
                isLoading={isLoading}
                fluid
                onClick={handleSubmit}
              >
                Create Account
              </Button>
            </Flex>
            {/* )} */}
            {errorMessage && <p className="Error">{errorMessage}</p>}

            {error && <p className="Error">{error}</p>}
            {privacyError && <p className="Error">{privacyError}</p>}
          </Tabs.Content>
          <Tabs.Content value="tab2">
            <Paragraph size="large" weight="bold">
              Sign in to your account
            </Paragraph>
            <fieldset>
              <Input
                label="Email"
                placeholder="Enter your email"
                errormsg={false}
                type="email"
                name="email"
                value={loginData.email}
                onChange={handleChangeLogin}
              />

              <Input
                label="Password"
                placeholder="Enter your password"
                errormsg={false}
                name="password"
                type="password"
                value={loginData.password}
                onChange={handleChangeLogin}
              />
            </fieldset>
            <Button
              size="large"
              variant="solid"
              fluid
              onClick={handleLogin}
              disabled={isLoading}
              isLoading={isLoading}
            >
              Login
            </Button>
            {error && <p className="Error">{error}</p>}
          </Tabs.Content>
        </Tabs>

        <Flex customClass="LoginInfo" align="center" justify="end">
          <Flex
            direction={"column"}
            style={{
              maxWidth: "400px",
              marginRight: "calc((360px/2) + var(--size-400))",
            }}
          >
            <img
              className="LoginLogo"
              src={AppLogoWhite}
              alt="Empower Your Design & Development Teams with BlocksInGrids Logo"
            />
            <br />
            <Paragraph size="large">
              Efficiently assign design and development ownership to your
              product components, patterns, and features, consolidating all
              design and development documentation in one place, and enhance
              your modules with integrated structures and resources connected
              from your GitHub repository, Figma component libraries, or
              Storybook documentation.
            </Paragraph>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default Login;
