import React, { useState, useEffect } from "react";
import "./../radix-styles/Dialog.scss";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import {
  Button,
  Flex,
  Paragraph,
  Heading,
  IconButton,
  Input,
} from "blocksin-system";

const DialogComponentCardUpdateFigma = ({
  onSave,
  open,
  onOpenChange,
  cardId,
  onUpdateFigmaURL,
  currentFigmaURL,
}) => {
  const [figmaURL, setFigmaURL] = useState(currentFigmaURL);

  const handleSaveClick = () => {
    onSave(cardId, figmaURL); // Pass both cardId and figmaURL to onSave
    onUpdateFigmaURL(cardId, figmaURL); // This updates the Dashboard's state
  };

  useEffect(() => {
    setFigmaURL(currentFigmaURL || "");
  }, [currentFigmaURL]);

  return (
    <>
      <Dialog.Root
        className="DialogRoot"
        open={open}
        onOpenChange={onOpenChange}
      >
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent">
            <Heading level={3} weight="bold">
              Add Figma file
            </Heading>
            <Flex customClass="DialogDescription" direction={"column"}>
              <Paragraph size="large">
                Here you can add Figma file by entering url of your Figma file.
              </Paragraph>
            </Flex>
            <Flex direction="column" gap={200}>
              <Input
                label="Figma"
                placeholder="Enter figma URL"
                type="url"
                value={figmaURL}
                onChange={(e) => setFigmaURL(e.target.value)}
              />
            </Flex>

            <Flex
              direction="row"
              align="end"
              justify={"end"}
              gap={200}
              style={{
                marginTop: "var(--size-400)",
                paddingTop: "var(--size-150)",
                borderTop: "1px solid var(--border-neutral-subtle)",
              }}
            >
              <Button size="medium" variant="solid" onClick={handleSaveClick}>
                Update Component
              </Button>
            </Flex>
            <Flex customClass="closeButton">
              <Dialog.Close asChild>
                <IconButton aria-label="Close" size="small" variant="ghost">
                  <Cross2Icon />
                </IconButton>
              </Dialog.Close>
            </Flex>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

export default DialogComponentCardUpdateFigma;
