import React from "react";
import Nav from "./Nav";

import "./WebPage.scss";
import { Flex, Heading, Paragraph } from "blocksin-system";

const APIsPage = () => {
  return (
    <Flex direction={"column"} customClass={"WebPage"} align={"center"}>
      <Nav />

      <Flex direction={"column"} customClass={"WebPageBody"}>
        <Heading level={2} weight="bold">
          APIs
        </Heading>
        <Paragraph size="medium">Coming soon...</Paragraph>
      </Flex>
    </Flex>
  );
};

export default APIsPage;
