import React, { useState, useEffect } from "react";
import "./../radix-styles/Dialog.scss";
import * as Dialog from "@radix-ui/react-dialog";
import { Button, Flex, Paragraph, Heading, IconButton } from "blocksin-system";

import classnames from "classnames";
import * as RadixSelect from "@radix-ui/react-select";
import {
  ChevronDownIcon,
  Cross2Icon,
  // ChevronUpIcon,
  CheckIcon,
} from "@radix-ui/react-icons";

const DialogComponentCardUpdateMoveTo = ({
  onSave,
  open,
  onOpenChange,
  userOrganisationProjects,
  project,
  subProject,
  setMoveToProjectId,
}) => {
  const [selectedProjectId, setSelectedProjectId] = useState("");

  useEffect(() => {
    if (open) setSelectedProjectId(""); // Reset selected project when the dialog opens
  }, [open]);

  // In DialogComponentCardUpdateMoveTo component
  const handleSave = () => {
    // Assuming setMoveToProjectId updates the moveToProjectId state in the parent
    setMoveToProjectId(selectedProjectId);

    // Debugging log
    // console.log("Selected Project ID:", selectedProjectId);

    onSave(subProject._id, project._id, selectedProjectId);
    onOpenChange(false);
  };

  const renderProjectSelect = () => {
    const projects = userOrganisationProjects || [];

    return (
      <Flex direction={"column"} gap={100}>
        <Paragraph size="large">Move Card to Project</Paragraph>
        <RadixSelect.Root
          value={selectedProjectId}
          onValueChange={setSelectedProjectId}
        >
          <RadixSelect.Trigger className="SelectTrigger">
            <RadixSelect.Value placeholder="Select a project" />
            <RadixSelect.Icon>
              <ChevronDownIcon />
            </RadixSelect.Icon>
          </RadixSelect.Trigger>
          <RadixSelect.Portal>
            <RadixSelect.Content className="SelectContent">
              <RadixSelect.ScrollUpButton className="SelectScrollButton">
                <ChevronDownIcon />
              </RadixSelect.ScrollUpButton>
              <RadixSelect.Viewport>
                {projects.map((proj) => (
                  <RadixSelectItem key={proj._id} value={proj._id}>
                    {proj.title}{" "}
                  </RadixSelectItem>
                ))}
              </RadixSelect.Viewport>
              <RadixSelect.ScrollDownButton className="SelectScrollButton">
                <ChevronDownIcon />
              </RadixSelect.ScrollDownButton>
            </RadixSelect.Content>
          </RadixSelect.Portal>
        </RadixSelect.Root>
      </Flex>
    );
  };

  return (
    <>
      <Dialog.Root
        className="DialogRoot"
        open={open}
        onOpenChange={onOpenChange}
      >
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent">
            <Heading level={3} weight="bold">
              Move Card to Project
            </Heading>
            <Flex customClass="DialogDescription" direction={"column"}>
              <Paragraph size="large">
                You can move your card to different project.
              </Paragraph>
            </Flex>
            <Flex direction="column" gap={200}>
              {renderProjectSelect()}
            </Flex>

            <Flex
              direction="row"
              align="end"
              justify={"end"}
              gap={200}
              style={{
                marginTop: "var(--size-400)",
                paddingTop: "var(--size-150)",
                borderTop: "1px solid var(--border-neutral-subtle)",
              }}
            >
              <Button size="medium" variant="solid" onClick={handleSave}>
                Move Card
              </Button>
            </Flex>
            <Flex customClass="closeButton">
              <Dialog.Close asChild>
                <IconButton aria-label="Close" size="small" variant="ghost">
                  <Cross2Icon />
                </IconButton>
              </Dialog.Close>
            </Flex>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

const RadixSelectItem = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <RadixSelect.Item
        className={classnames("SelectItem", className)}
        {...props}
        ref={forwardedRef}
      >
        <RadixSelect.ItemText>{children}</RadixSelect.ItemText>
        <RadixSelect.ItemIndicator className="SelectItemIndicator">
          <CheckIcon />
        </RadixSelect.ItemIndicator>
      </RadixSelect.Item>
    );
  }
);

export default DialogComponentCardUpdateMoveTo;
